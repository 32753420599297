import { BillingCountResultData, ServiceUnitRenderResultData,TrainingLinkData,UpcomingAppointmentsData,UpcomingComplianceCountData} from "src/dataModels/dashboardResponseModel";
import { SAVE_BILLING_COUNTS, SAVE_BILLING_DATE, SAVE_CLIENT_BY_GENDER_COUNT, SAVE_CLIENT_COUNT, SAVE_CLIENT_DIAGNOSIS_COUNT, SAVE_CLOCK_STATUS, SAVE_SERVICE_UNITS_RENDER, SAVE_TASK_FILTER, SAVE_TIME_CLOCK_ARCHIVE, SAVE_TIME_CLOCK_BY_ID, SAVE_UPDATE_STAFF_TIME_CLOCK_RESPONSE ,SAVE_TRAINING_LINKS,SAVE_UPCOMING_APPOINTMENTS,SAVE_UPCOMING_COMPLIANCE_COUNT_DATA, TIME_CLOCK_FILTER} from "../actions/types";

const initialState = {
    serviceUnitRender: {} as ServiceUnitRenderResultData,
    billingCount: {} as BillingCountResultData,
    timeclockStatus: {},
    clientCountByGender: {},
    staffClockTimeHistory: [],
    trainingLink:[] as TrainingLinkData[],
    upcomingAppointments:[] as UpcomingAppointmentsData[],
    upcomingComplianceCount : [] as UpcomingComplianceCountData[],
    timeClockFilters: {}
}

export const DashboardReducer = (state = initialState, action: any) => {
    const oldState = { ...state };
    const { type, payload } = action;

    switch (type) {
        case SAVE_CLIENT_COUNT:
            return {
                ...oldState,
                clientCount: payload,
            };
        case SAVE_CLIENT_BY_GENDER_COUNT:
          
            return {
                ...oldState,
                clientCountByGender: payload,
            };
        case SAVE_CLIENT_DIAGNOSIS_COUNT:
            return {
                ...oldState,
                clientDiagnosisCount: payload,
            };
        case SAVE_SERVICE_UNITS_RENDER:
            return {
                ...oldState,
                serviceUnitRender: payload
            }

        case SAVE_BILLING_COUNTS:
            return {
                ...oldState,
                billingCount: payload
            }

        case SAVE_BILLING_DATE:
            return {
                ...oldState,
                billingDate: payload
            }

        case SAVE_TASK_FILTER:
            return {
                ...oldState,
                taskFilterItems: payload
            }

        case SAVE_CLOCK_STATUS:
            return {
                ...oldState,
                timeclockStatus: payload
            }
        case SAVE_TIME_CLOCK_BY_ID:
            return {
                ...oldState,
                timeClockById: payload
            }
        case SAVE_UPDATE_STAFF_TIME_CLOCK_RESPONSE:
            return {
                ...oldState,
                updateClockTimeResponse: payload
            }
        case SAVE_TIME_CLOCK_ARCHIVE: 
            return {
                ...oldState,
                staffClockTimeHistory: payload
            }
        case SAVE_TRAINING_LINKS: 
            return {
                ...oldState,
                trainingLink: payload
            }  
        case SAVE_UPCOMING_APPOINTMENTS: 
            return {
                ...oldState,
                upcomingAppointments: payload
            } 
        case SAVE_UPCOMING_COMPLIANCE_COUNT_DATA: 
            return {
                ...oldState,
                upcomingComplianceCount: payload
            }    
        case TIME_CLOCK_FILTER:
            console.log('hello from reducer', payload) 
            return {
                ...oldState,
                timeClockFilters: payload
            } 
    }
    return oldState;

}