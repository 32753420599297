import { takeLatest, put } from 'redux-saga/effects';
import {
  loaderAction,
  roleModeAction,
  staffSettingsAction, savePermissionsAction, saveStaffPermissions, loginUser, saveAuthTransactions
} from '../actions';
import * as TYPES from '../actions/types';
import DEVELOPMENT_CONFIG from "../../helper/config";
import moment from 'moment';
import { PermissionHelper } from '../../helper/permission-helper';
import { Encrption } from '../../app-modules/encrption';
import { UserAuthResponse } from '../../dataModels/authDataModels';
import { getUser } from '../../services/authService';
import { UserPermissions } from '../../dataModels/userPermissionModels';
import { SettingsResponse } from '../../dataModels/userSettingsModel';


export function* LoginSaga() {
  yield takeLatest(TYPES.LOGIN_ACTION, loginWorker);
}


function* loginWorker(param: any) {
  // const navigate = useNavigate();
  try {
    yield put(loaderAction(true));
    const response: UserAuthResponse = yield getUser.auth(param['payload']);
    yield put(loaderAction(false));
    let roleId = response.resultData?.roleId;
    let staffId = response.resultData?.staffId

    if (response?.resultData?.isTempPassword === 1) {
      localStorage.setItem(DEVELOPMENT_CONFIG.IS_TEMP_PASSWORD, response?.resultData?.isTempPassword.toString());
    }
    localStorage.setItem(DEVELOPMENT_CONFIG.TOKEN, response.resultData.token);
    localStorage.setItem(
      DEVELOPMENT_CONFIG.REFRESH_TOKEN,
      response.resultData.refreshToken
    );
    localStorage.setItem(DEVELOPMENT_CONFIG.LOGIN_TIME, moment().format());
    localStorage.setItem(
      DEVELOPMENT_CONFIG.TOKEN_EXPIRETIME,
      moment().add(response.resultData.tokenExpireIn, "seconds").format()
    );
    localStorage.setItem(
      DEVELOPMENT_CONFIG.REFRESHTOKEN_EXPIRETIME,
      moment()
        .add(response.resultData.refreshTokenExpireIn, "seconds")
        .format()
    );
    yield put(loginUser(response.resultData));
    yield put(roleModeAction(roleId));
    yield put(staffSettingsAction(staffId));

  } catch (err) {
  }
}

export function* getRoleSaga() {
  yield takeLatest(TYPES.ROLE_MODE, getRoleModulesWorker);
}

function* getRoleModulesWorker(param: any) {

  let response: UserPermissions = yield getUser.getUserPermissions()
  let list = response?.resultData;
  if (list.length > 0) {
    let data = yield PermissionHelper(response?.resultData);
    yield put(savePermissionsAction(data));
  }
}


export function* getStaffSettingsSaga() {
  yield takeLatest(TYPES.STAFF_SETTINGS, getStaffSettingListWorker);
}


function* getStaffSettingListWorker(param: any) {
  let id = Encrption(param['payload']);

  let response: SettingsResponse = yield getUser.getStaffSettings(id);
  yield put(saveStaffPermissions(response?.resultData));
}

export function* getAuthViewTransSaga() {
  yield takeLatest(TYPES.GET_AUTH_TRANSACTIONS, getAuthViewTransWorker);
}

function* getAuthViewTransWorker(param: any) {
  yield put(loaderAction(true));
  let response: any = yield getUser.getAuthTransaction(param['payload']);
  yield put(loaderAction(false));
  yield put(saveAuthTransactions(response?.resultData));
}


