import React, { useEffect, useState } from "react";
import Header from "../header/header";
import MenuHeader from "../menu-header/menu-header";
import Footer from "../footer-bottom/footer";
import ChangePassword from "../header/changePassword";


const Layout = ({ children }) => {

  let getTempPwdStatus = parseInt(localStorage.getItem("isTemp"));
  const url = window.location.href;

  if (getTempPwdStatus === 1) {
    return <ChangePassword onClose={() => null} isTemp={getTempPwdStatus} />
  }

  if (url.endsWith('latest-document')) {
    return (
      <div className="main_wrapper">

        {children}

      </div>
    );
  }
  if (url.endsWith('document/print')) {
    return (
      <div className="main_wrapper">

        {children}

      </div>
    );
  }

  return (
    <div className="main_wrapper">
      <div className="z-navbar">
        <Header />
        <MenuHeader />
      </div>
      <div className={"inner-content-bottom"}>
        <div className="container-fluid">
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
