import React, { useEffect } from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { forwardRef, useRef, useImperativeHandle } from "react"
import useScrollBlock from 'src/cutomHooks/scroll';

const DeleteDialogModal = (props: DeleteDialogProps) => {
  const {
    title,
    message,
    activeType,
    onClose,
    handleDelete,
    handleReactive,
    ...rest
  } = props;
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    blockScroll()
  }, [blockScroll])

  return (
    <Dialog
      className='xs-modal blue_theme'
      onClose={() => {
        if (onClose != null) {
          onClose();
        }
        allowScroll()
      }} title={activeType === true ? `Reactivate ${title}` : `Delete ${title}`}>
      <p
        style={{
          margin: "25px",
        }}
      >
        {activeType === true ? `Are you sure you want to reactivate ${message}?` : ` Are you sure you want to delete ${message}?`}
      </p>
      <DialogActionsBar>
        {activeType ? (
          <button
            className="submitButon px-4"
            onClick={handleReactive}
          >
            Yes
          </button>
        ) : (
          <button
            className="submitButon px-4"
            onClick={handleDelete}
          >
            Yes
          </button>
        )}
        <button
          className=" cancelBtn px-4 "
          onClick={() => {
            if (onClose != null) {
              onClose();
            }
            allowScroll()
          }}
        >
          No
        </button>

      </DialogActionsBar>
    </Dialog>
  )
}

export default DeleteDialogModal

export interface DeleteDialogProps {
  title: string
  message: String
  activeType?: boolean
  onClose?: () => void | number;
  handleDelete?: () => void;
  handleReactive?: () => void;
}