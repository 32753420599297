import React, { ComponentState, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { pieChartModel } from "src/dataModels/docListModel";
import { userIsSuperAdmin } from "src/helper/permission-helper";
import { ListView, ListViewHeader } from "@progress/kendo-react-listview";
import CustomSkeleton from "src/control-components/skeleton/skeleton";

export const DocumentByPayer = () => {
  const state: ComponentState = useSelector((states) => {
    return states;
  });
  const isBillingManager = state.getRolePermission.isBillingManager;
  const [billingData, setBillingData] = useState<pieChartModel[]>([]);
  const [billingDataDocs, setBillingDataDocs] = useState<pieChartModel[]>([]);
  const { documentBillingByPayerResp } = state["DocumentReducer"];
  const staffLoginInfo = useSelector(
    (state: ComponentState) => state.getStaffReducer
  );
  const userIsSA = userIsSuperAdmin(staffLoginInfo?.roleId);
  const showBillingAmounts = state.getRolePermission.showBillingAmounts;
  const canShowBillData = isBillingManager || userIsSA || showBillingAmounts;
  const [isShowItems, setShowItems] = useState(false);

  useEffect(() => {
    setBillingData([]);
    setBillingDataDocs([]);
    if (documentBillingByPayerResp && documentBillingByPayerResp?.resultData) {
      setShowItems(true);
      documentBillingByPayerResp?.resultData?.map((element, index) => {
        const totalBillValue = Number(element?.totalBilled).toFixed(2);
        const formattedTotalBill = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Number(Number(totalBillValue).toFixed(2)));

        const label = `Docs:${element?.totDocs}, ${
          canShowBillData ? "Billed Amt:" + { formattedTotalBill } : ""
        }`;
        var obj: pieChartModel = {
          id: index,
          label: `${element?.payerId} - ${element?.payerName}`,
          value: element?.totalBilled,
          info: label,
          totalBilled: canShowBillData ? formattedTotalBill : "",
          count: element?.totDocs,
        };

        var objDocs: pieChartModel = {
          id: index,
          label: `${element?.payerId} - ${element?.payerName}`,
          value: element?.totDocs,
          info: label,
          totalBilled: canShowBillData ? formattedTotalBill : "",
          count: element?.totDocs,
        };

        setBillingData((oldArray) => [...oldArray, obj]);
        setBillingDataDocs((oldArrayDc) => [...oldArrayDc, objDocs]);
      });
    }
  }, [documentBillingByPayerResp]);

  const totalValue = billingData.reduce((sum, item) => sum + item.value, 0);
  const totalDocValue = billingDataDocs.reduce(
    (sum, item) => sum + item.value,
    0
  );

  const MyFirstHeader = () => {
    return (
      <ListViewHeader
        style={{
          color: "#000000",
          fontSize: 30,
        }}
        className="px-3 py-2 "
      >
        <div className="row border-bottom align-middle">
          <div className="col-4">
            <h2 className="f-14">Payer</h2>
          </div>
          <div className="col-4">
            <h2 className="f-14"># of Docs</h2>
          </div>
          <div className="col-4">
            <h2 className="f-14">Billing Charges</h2>
          </div>
        </div>
      </ListViewHeader>
    );
  };
  const MyFirstItemRender = (props) => {
    let item = props.dataItem;
    return (
      <div
        className="row py-2 border-bottom align-middle"
        style={{ margin: 0 }}
      >
        <div className="col-4">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.label}</div>
          )}
        </div>
        <div className="col-4">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.count}</div>
          )}
        </div>
        <div className="col-4">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.totalBilled}</div>
          )}
        </div>
      </div>
    );
  };

  const MySecondHeader = () => {
    return (
      <ListViewHeader
        style={{
          color: "#000000",
          fontSize: 30,
        }}
        className="px-3 py-2 "
      >
        <div className="row border-bottom align-middle">
          <div className="col-4">
            <h2 className="f-14">Payer</h2>
          </div>
          <div className="col-4">
            <h2 className="f-14"># of Docs</h2>
          </div>
          <div className="col-4">
            <h2 className="f-14">Billing Charges</h2>
          </div>
        </div>
      </ListViewHeader>
    );
  };
  const MySecondItemRender = (props) => {
    let item = props.dataItem;
    return (
      <div
        className="row py-2 border-bottom align-middle"
        style={{ margin: 0 }}
      >
        <div className="col-4">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.label}</div>
          )}
        </div>
        <div className="col-4">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.count}</div>
          )}
        </div>
        <div className="col-4">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.totalBilled}</div>
          )}
        </div>
      </div>
    );
  };

  const size = {
    width: 400,
    height: 200,
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          {documentBillingByPayerResp?.resultData != 0 ? (
            <div className="grid-table filter-grid dash-filter-grid my-3 caseload-custom gridheight casload boxHeight traningList">
              <h5>
                Billing by Payer{" "}
                {canShowBillData
                  ? `: (${new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(Number(Number(totalValue).toFixed(2)))})`
                  : ""}
              </h5>
              <ListView
                className="traningList"
                data={billingData}
                item={MyFirstItemRender}
                style={{
                  width: "100%",
                  display: "block",
                }}
                header={MyFirstHeader}
              />
            </div>
          ) : (
            <p>No Record found</p>
          )}
        </div>

        <div className="col-md-6">
          {documentBillingByPayerResp?.resultData != 0 ? (
            <div className="grid-table filter-grid dash-filter-grid my-3 caseload-custom gridheight casload boxHeight traningList">
              <h5> Documents by Payer: ({totalDocValue})</h5>
              <ListView
                className="traningList"
                data={billingDataDocs}
                item={MySecondItemRender}
                style={{
                  width: "100%",
                  display: "block",
                }}
                header={MySecondHeader}
              />
            </div>
          ) : (
            <p>No Record found</p>
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentByPayer;
