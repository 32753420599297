
import { ComponentState, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getStaffListData } from "../globalDataCheckerService";
import MultiSelectDropDown from "src/control-components/multi-select-drop-down/multi-select-drop-down";
import React from "react";

export const MultiSelectStaffDropDownComponent = (props: StaffDdlProps) => {
    const state: ComponentState = useSelector(states => {
        return states;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { selectedStaff, disabled, isCheckActiveStaff, switchStaffValue, onChangeStaff, ...rest } = props
    const { staffsList } = state['DocumentReducer'];
    const [staffList, setStaffList] = useState([]);
    const [isDataLoadStart, setDataLoadStart] = useState(false);
    const [loading, setLoading] = useState(false);

    const callStaffListApiData = async (isHardRefresh: boolean) => {
        getStaffListData(isHardRefresh, staffsList, isCheckActiveStaff, switchStaffValue)
    }
    useEffect(() => {

        if (!isDataLoadStart) {
            setLoading(true)
            callStaffListApiData(false)
            setDataLoadStart(true)
        }
        if (staffsList != null) {
            setLoading(false)
            setStaffList(staffsList);
        }
    }, [isDataLoadStart, staffsList])


    function renderToItem(li, itemProps) {
        const itemChildren = (
            <span>
                <input
                    type="checkbox"
                    name={itemProps.dataItem}
                    checked={itemProps.selected}
                    onChange={(e) => itemProps.onClick(itemProps.index, e)}
                />
                &nbsp;{li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }

    return <div className="p-0 col-md-12 pr-3 pl-3">
                <div className="position-relative d-inline-block w-100">
                    <MultiSelectDropDown
                        data={staffList}
                        textField="name"
                        label="Staff"
                        name="staff"
                        disabled={disabled}
                        value={selectedStaff}
                        onChange={onChangeStaff}
                        autoClose={false}
                        dataItemKey={"id"}
                        itemRender={renderToItem}
                        canReFetch={true}
                        onRefetchData={() => {
                            setLoading(true)
                            setStaffList([]);
                            callStaffListApiData(true);
                        }}
                        loading = {loading}
                    />
                   
                </div>
            </div>
     
}

export interface StaffDdlProps {
    selectedStaff: any,
    disabled: any,
    isCheckActiveStaff: boolean,
    switchStaffValue: any,
    onChangeStaff?: (newValue: any) => void
}