import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";

import ProgressLoading from "src/control-components/loader/loader_with_text";
import DateTimeHelper from "src/helper/date-time-helper";
import { MaskFormatted } from "src/helper/mask-helper";
import { saveExportCount } from "src/redux/actions";
import { onShowFileTempFields } from "../file-template-utility";
import './document-styles.css';
import DocumentTemplatePdfItem from "./pdf-doc-temp-item";
import { styles } from "./style";

const myTemplateNew = (args, metaData) => {
  const PrimaryNumber = MaskFormatted(
    metaData.phone ? metaData.phone : "",
    "(999) 999-9999"
  );
  return ReactDOMServer.renderToString(
    <div>
      <style> {styles}</style>
      <div style={{ position: 'absolute', top: 0.5, width: "95%", backgroundColor: "#F6F8FA", padding: "2px 15px", marginBottom: "80px", height: 50 }}>
        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginTop: '2px' }}>
          <div className="document-sec" >
            <div className='mb-0 head-6 customCell'>Office Use Only Doc ID :<span className="customCell-light">{metaData.documentId}</span></div>
          </div>
          <div className="document-sec">
            <div className='customCell-light'>{`${metaData.clientLastName}, `}<span style={{ color: "#4a4a4b", }}>{metaData.clientFirstName}</span></div>
          </div>
          <div className="document-sec">
            <div className='customCell-light'>by {`${metaData.staffLastName},  ${metaData.staffFirstName}, `}<span className="customCell-light">{metaData.siteName}</span></div>
          </div>
          <div className="document-sec">
            <div className='customCell' >Page<span className="customCell-light">
              &nbsp; {args.pageNum} of {args.totalPages}
            </span></div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginRight: "0px", paddingRight: "0px", marginTop: '15px' }}>
          <div className="document-sec" >
            <div className='customCell' >Policy#: <span className="customCell-light">{metaData.policyNumber}</span> </div>
          </div>
          <div className="document-sec">
            <div className='customCell'>DOB: <span className="customCell-light">{moment(metaData.dob).format('M/D/YYYY')}</span></div>
          </div>
          <div className="document-sec">
            <div className='customCell'>DOS: <span className="customCell-light">
              {DateTimeHelper.formatDatePickerString(metaData.serviceDate)}
            </span></div>
          </div>
          {
            metaData.recordId &&
             <div className="document-sec">
             <div className='customCell'>Record#: <span className="customCell-light">
               {metaData.recordId}
             </span></div>
           </div>
          }
        </div>
      </div>
      <div style={{ position: 'absolute', bottom: 0.2, width: "95%", backgroundColor: "#F6F8FA", padding: "3px 15px", height: 50, }}>
        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginTop: '3px' }}>
          <div className="d-flex justify-content-start align-items-center gap-0" >
            <div className='customCell' >{metaData.templateName} <span className="px-0 mx-0"></span></div>
          </div>
          <div className="">
            <div className='mb-0 customCell-light'>Page#:<span className="customCell-light">
              &nbsp; {args.pageNum} of {args.totalPages}
            </span></div>
          </div>
        </div>
        <div>
          <div style={{ marginTop: '15px' }}>
            <div className='customCell'>
              {`${metaData.address} ${metaData.city} | ${metaData.stateName} | ${metaData.zip} | ${PrimaryNumber}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};




function DocumentTemplatePdf({ documentPrintData, onlyView, metaDataDocs }) {

  const _elementRef = useRef(null);
  const [isFieldSet, setFieldSet] = useState(false);
  const [docCalDone, setDocCalDone] = useState(false);
  const clinicLogo = useSelector((states) => states.logoByte);
  const [refresh, setRefResh] = useState(false);
  const classRow = "row col-lg-11 ml-1 mt-3"

  const [isPrepared, setIsPrePared] = useState(false);
  const [prepCount, setPrepCount] = useState(0);
  const [exportCount, setExportCount] = useState(0);
  const state = useSelector(states => {
    return states;
  });
  const { docPreparedCount, docExportCount } = state['DocumentReducer'];
  const [isFileTempFieldMapped, setIsFileTempFieldMapped] = useState(false);
  const [isDocumentRendered, setIsDocumentRendered] = useState(false);
  const [isDocumenFileComponentLoaded, setIsDocumenFileComponentLoaded] = useState(false);
  const [documenFileComponentLoadCount, setDocumenFileComponentLoadCount] = useState(0);

  useEffect(() => {
    const nonFileTemplateDocs = documentPrintData.filter(doc => {
      // console.log(doc)
      return !doc.isHtmlFileTypeTemplate
    }).length
    setDocumenFileComponentLoadCount(x=> x + nonFileTemplateDocs);
  }, [documentPrintData]);

  useEffect(() => {
    // console.log("documenFileComponentLoadCount", documenFileComponentLoadCount, "/", documentPrintData.length);
    if(documenFileComponentLoadCount === documentPrintData.length) {
      setIsDocumenFileComponentLoaded(true)
      // console.log("Document loaded completely!");
    }
  }, [documenFileComponentLoadCount]);

  // const exportElement = async (elements, metaDataDocs, totalPageCount, pageBreaks) => {
  const exportElement = async (elements, metaDataDocs) => {
    if (elements.length > 0) {
      var file1 = null;
      var index = 0;
      for (let optionItem of elements) {
        const cDocId = optionItem.getAttribute('id')
        const optionItem8 = {
          paperSize: "A4",
          margin: {
            left: 0,
            top: 50,
            right: 0,
            bottom: 50,
          },
          fileName: "Document Temp",
          author: "Notenetic Team",
          template: (args) => {
            return myTemplateNew({ totalPages: args.totalPages, pageNum: args.pageNum }
              , metaDataDocs.find((item) => item.documentId == cDocId),)
          },
          keepTogether: ".keep-together",
          multiPage: true,
          // scale: 0.9,
        }

        if (index === 0) {
          file1 = await drawDOM(optionItem, { ...optionItem8 });
        }
        if (index > 0) {
          const file2 = await drawDOM(optionItem, { ...optionItem8 });
          file1.append(...file2.children);
        }
        index = index + 1;
        dispatch(saveExportCount(index))
        if (index === elements.length) {
          const exportedPdf = await exportPDF(file1, {
            forcePageBreak: '.break',
            multiPage: true,
            title: "Notenetic Document",
            author: "Notenetic Team",
            creator: "Notenetic Team",
            subject: "Notenetic Document",
            date: moment.now(),
            producer: "Notenetic Team",
          });
          saveAs(exportedPdf, 'Document.pdf');
          window.close()
        } else {
          await pause()
        }

      }
    }
  }

  React.useEffect(() => {
    // onFieldsSet();
    setFieldSet(true)
  }, []);


  React.useEffect(() => {
    // console.log("_elementRef.current", _elementRef?.current)
    if (_elementRef.current && !docCalDone) {
      handleSelect().then(() => { });
      setDocCalDone(true)
    }
  }, [isDocumentRendered, isFieldSet]);

  const pause = () => {
    return new Promise(r => setTimeout(r, 0))
  }
  const dispatch = useDispatch()


  const handleSelect = async () => {
    if (!onlyView) {
      // let totalPageCount = 0
      // let pageBreaks = [0]

      // const optionItem8 = {
      //   paperSize: "A4",
      //   margin: {
      //     left: 0,
      //     top: 50,
      //     right: 0,
      //     bottom: 50,
      //   },
      //   fileName: "Document Temp",
      //   author: "Notenetic Team",
      //   keepTogether: ".keep-together",
      //   multiPage: true,
      // }
      setIsPrePared(true)
      await exportElement(_element1, metaDataDocs);
      // var index = 0;
      // for (let optionItem of _element1) {

      //   const file1 = await drawDOM(optionItem, { ...optionItem8 })
      //   totalPageCount = totalPageCount + file1.children.length;
      //   pageBreaks.push(totalPageCount)
      //   setRefResh(!refresh)
      //   index = index + 1;
      //   dispatch(savePrePareCount(index))
      //   if (pageBreaks.length - 1 == _element1.length) {
      //     setIsPrePared(true)
      //     await exportElement(_element1, metaDataDocs, totalPageCount, pageBreaks);

      //   } else {
      //     await pause()
      //   }

      // }

    }

  }



  let _element1 = [];


  useEffect(() => {
    if (isDocumenFileComponentLoaded) {
      mapDocumentFileFields();
    }
  }, [documentPrintData, isDocumenFileComponentLoaded])

  const mapDocumentFileFields = () => {
    // console.log("mapDocumentFileFields..")
    if (!isFileTempFieldMapped && documentPrintData && documentPrintData.length > 0) {
      documentPrintData.map((currentDocumentData, index) => {
        onShowFileTempFields(currentDocumentData.fieldsMapping, currentDocumentData.documentId, true, currentDocumentData.htmlFileName, index);
        if (index === (documentPrintData.length - 1)) {
          setIsFileTempFieldMapped(true)
        }
      })
    } else {
      setIsFileTempFieldMapped(true)
    }
  }

  function onComponentLoaded() {
    setDocumenFileComponentLoadCount(x => x + 1);
  }

  const docComponent = () => {
    return (<div>
      {
        documentPrintData.map((currentDocumentData) => (
          <div
            key={currentDocumentData.documentId}
            id={currentDocumentData.documentId}
            ref={(div) => {
              if (!docCalDone && div != null) {
                _element1.push(div);
              }
            }}>
            <DocumentTemplatePdfItem
              onComponentLoaded={onComponentLoaded}
              staffInfo={currentDocumentData.staffInfo}
              documentDetail={currentDocumentData.documentDetail}
              diagnosisList={currentDocumentData.diagnosisList}
              docSignature={currentDocumentData.docSignature}
              docClientSignature={currentDocumentData.docClientSignature}
              isHtmlFileTypeTemplate={currentDocumentData.isHtmlFileTypeTemplate}
              htmlFileName={currentDocumentData.htmlFileName}
              template={currentDocumentData.template}
              fieldsMapping={currentDocumentData.fieldsMapping}
              documentId={currentDocumentData.documentId}
              docTreatmentPlans={currentDocumentData.docTreatmentPlans}
              historyList={currentDocumentData.historyList}
              documentCommonControls={currentDocumentData.documentCommonControls}
              metaData={metaDataDocs.find((i) => i.documentId === currentDocumentData.documentId)}
              docHasControlls={currentDocumentData.docHasControlls}
            ></DocumentTemplatePdfItem>
          </div>
        ))
      }
    </div>)
  }

  return (
    (!isFileTempFieldMapped) ?

      <div>
        {docComponent()}
        <ProgressLoading message={'Please wait while we are collecting information for all selected documents.'}></ProgressLoading>
      </div> :
      <div ref={(div) => {
        _elementRef.current = div;
        setIsDocumentRendered(true);
      }}>
        {docComponent()}
        {!onlyView && <ProgressLoading message={isPrepared ?
          `Exporting documents (${docExportCount}/${documentPrintData.length})`
          : `Prepared documents (${docPreparedCount}/${documentPrintData.length})`}>
        </ProgressLoading>}
      </div>
  );

}

export default DocumentTemplatePdf;
