
import React, {  useEffect, useState } from 'react';
import { DropDownList} from '@progress/kendo-react-dropdowns';
import { RefreshButton } from 'src/app-modules/global-components/refresh-button';



export interface DropDownProps {
    filterable?:boolean
    data?:any
    onFilterChange?:(event) =>void
    loading?:boolean
    textField?:string
    label?:string
    name?:string
    value?:any
    onChange?:(event) =>void
    canReFetch?:boolean
    onRefetch?:() =>void
    dataItemKey?:string
    showExtraPadding?:boolean
}


function DropDownListComponent(props: DropDownProps) {
    const {
        filterable,
        data,
        onFilterChange,
        loading,
        textField,
        label,
        name,
        value,
        onChange,
        canReFetch,
        onRefetch,
        dataItemKey,
        showExtraPadding
    } = props

    const [filter, setFilter] = useState(data?.slice());


    return (
        <div>
              <DropDownList
                    filterable={filterable}
                    data={data}
                    onFilterChange={onFilterChange}
                    loading={loading}
                    textField={textField}
                    label={label}
                    name={name}
                    value={value}
                    onChange={onChange}
                    dataItemKey={dataItemKey}
                />
          

                {(canReFetch && !loading) &&
                     <div className={showExtraPadding?"position-absolute mr-n2 staffDoc-refresh-button-single-selection":"position-absolute mr-n2 staffDoc-refresh-button"}>
                     <RefreshButton
                         onRefresh={onRefetch }
                     />
                 </div>
                }
         
            {/* {
                error &&
                <Error >
                    {error}
                </Error>
            } */}
        </div>


    );

}
export default DropDownListComponent;



