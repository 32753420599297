import refreshImageBlue from "../../assets/images/refresh2.svg";
import { Tooltip } from "@progress/kendo-react-tooltip";


export const RefreshButton = (props: RefreshButtonInterface) =>{
    const { onRefresh, ...rest } = props

    return <Tooltip anchorElement="target" position="bottom" >
                <img src={refreshImageBlue} alt="refresh"  title="Refresh" className="cursor-pointer refreshSyncBlue" 
                onClick={() => onRefresh && onRefresh()} />
            </Tooltip>
            
}


export interface RefreshButtonInterface {
    onRefresh?: () => void
}