import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { loaderAction, saveBillingTransItemByClaimId, saveClaimServiceLine, saveEdiBybatchId } from '../actions';

import {  EDIResponse, ResponseBillingTransByClaimId } from 'src/dataModels/clientResponse';
import { billingService } from 'src/services/billingService';
import { BillingServiceLineResponse } from 'src/dataModels/billingResponses';



export function* billingClaimServiceLineSaga() {
  yield takeLatest(TYPES.GET_CLAIM_SERVICE_LINE, claimServiceLineWorker);
}

function* claimServiceLineWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: BillingServiceLineResponse = yield billingService.billingClaimServiceLine(param['payload']);
    yield put(saveClaimServiceLine(response.resultData));
    yield put(loaderAction(false));
  } catch (err) {
  }
}



export function* getbillingTransItemClaimIdSaga() {
  yield takeLatest(TYPES.GET_BILLING_TRANS_ITEM_BY_CLAIMID, getbillingTransItemClaimIdWorker);
}

function* getbillingTransItemClaimIdWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: ResponseBillingTransByClaimId = yield billingService.getBillingTransItemByClaimId(param['payload']);
    yield put(saveBillingTransItemByClaimId(response));
    yield put(loaderAction(false));
  } catch (err) {
  }
}

export function* getEdiByBatchIdSaga() {
  yield takeLatest(TYPES.GET_EDI_BY_BATCH_ID, getEdiByBatchIdWorker);
}

function* getEdiByBatchIdWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: EDIResponse = yield billingService.getEdiBybatchId(param['payload']);
    yield put(saveEdiBybatchId(response.resultData));
    yield put(loaderAction(false));
  } catch (err) {
  }
}
