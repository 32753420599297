import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { clientLedgerBalanceChanged, getClientComplianceList, loaderAction, saveBillingClaims, saveClientComplianceList, saveClientLedgerByClientId, saveClientLedgerById, saveClientLedgerPaymentTypes, saveClientListChangedBoolean, saveClientStatus, saveClinicFlag, saveCurrentLedgerBalance, saveDocLoadingState, saveDuplicateClients, saveMultipleClientList, saveReviewGoalByGoalId, saveTpStaffTypeDll, saveUnreadCount, storeAssignFlagToClient } from '../actions';
import { clientServices } from 'src/services/clientServices';
import { ClientBillingClaimResponse, ClientComplianceListResponse, CompleteComplianceResponse, ResponseMultiClientList, clientStatusResponse, clinicFlagResponse, unreadCountResponse } from 'src/dataModels/clientResponse';
import { NotificationManager } from "react-notifications";
import NOTIFICATION_MESSAGE from 'src/helper/notification-messages';
import { GetTpStaffTypesResponse, ReviewGoalResponse } from 'src/dataModels/clinicResponseModels';




export function* clientBillingClaimSaga() {
  yield takeLatest(TYPES.GET_CLIENT_BILLING_CLAIMS, claimWorker);
}

function* claimWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: ClientBillingClaimResponse = yield clientServices.billingClaims(param['payload']);
    yield put(saveBillingClaims(response.resultData));
    yield put(loaderAction(false));
  } catch (err) {
  }
}

export function* getClientComplianceListSaga() {
  yield takeLatest(TYPES.GET_CLIENT_COMPLIANCE_LIST, getClientComplianceListWorker);
}

function* getClientComplianceListWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: ClientComplianceListResponse = yield clientServices.clientCompliance(param['payload']);
    yield put(loaderAction(false));
    yield put(saveClientComplianceList(response))
  } catch (err) {
  }
}

export function* completeComplianceSaga() {
  yield takeLatest(TYPES.COMPLETE_COMPLIANCE, completeComplianceWorker);
}

function* completeComplianceWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: CompleteComplianceResponse = yield clientServices.completeCompliance(param['payload']);
    NotificationManager.success(NOTIFICATION_MESSAGE.COMPLETE_COMPLIANCE);
    yield put(getClientComplianceList(param['payload'].clientId))
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* deleteManuallyCompletedComplianceSaga() {
  yield takeLatest(TYPES.DELETE_MANUALLY_COMPLETED_COMPLIANCE, deleteManuallyCompletedComplianceWorker);
}

function* deleteManuallyCompletedComplianceWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield clientServices.deleteManuallyCompletedCompliance(param['payload']['complianceId']);
    yield put(loaderAction(false));
    yield put(getClientComplianceList(param['payload'].clientId))
    NotificationManager.success(NOTIFICATION_MESSAGE.DELETE_COMPLETE_COMPLIANCE);

  }
  catch (err) {
  }
}

export function* getClinicFlagSaga() {
  yield takeLatest(TYPES.GET_CLINIC_FLAG, getClinicFlagWorker);
}

function* getClinicFlagWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: clinicFlagResponse = yield clientServices.getClinicFlag();
    yield put(loaderAction(false));
    yield put(saveClinicFlag(response))


  }
  catch (err) {
  }
}

export function* saveAssignFlagToClientSaga() {
  yield takeLatest(TYPES.ASSIGN_FLAG_TO_CLIENT, saveAssignFlagToClientWorker);
}

function* saveAssignFlagToClientWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield clientServices.saveClinicFlag(param['payload']);
    yield put(loaderAction(false));
    NotificationManager.success(NOTIFICATION_MESSAGE.ASSIGN_FLAG_TO_CLINET);
    yield put(storeAssignFlagToClient(response))

  }
  catch (err) {
  }
}

export function* getClientStatusSaga() {
  yield takeLatest(TYPES.GET_CLIENT_STATUS, getClientStatusWorker);
}

function* getClientStatusWorker() {
  try {
    yield put(loaderAction(true));
    const response: clientStatusResponse = yield clientServices.getClientStatus();
    yield put(loaderAction(false));
    yield put(saveClientStatus(response))
  }
  catch (err) {
  }
}

export function* saveSelectedClientStatusSaga() {
  yield takeLatest(TYPES.SAVE_SELECTED_CLIENT_STATUS, saveSelectedClientStatusWorker);
}

function* saveSelectedClientStatusWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield clientServices.saveSelectedClientStatus(param['payload']);
    yield put(loaderAction(false));
    NotificationManager.success(NOTIFICATION_MESSAGE.CHANGE_CLIENT_STATUS_OF_SELECTED_CLIENTS);
  }
  catch (err) {
  }
}
export function* saveSelectedClientEligibilitySaga() {
  yield takeLatest(TYPES.SAVE_SELECTED_CLIENT_ELIGIBILITY, saveSelectedClientEligibilityWorker);
}

function* saveSelectedClientEligibilityWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield clientServices.saveSelectedClientEligibility(param['payload']);
    yield put(loaderAction(false));
    NotificationManager.success(NOTIFICATION_MESSAGE.INSERT_ELIGIBILITY);
  }
  catch (err) {
  }
}

export function* getUnreadCountSaga() {
  yield takeLatest(TYPES.GET_UNREAD_COUNT, getUnreadCountWorker);
}

function* getUnreadCountWorker() {
  try {
    yield put(loaderAction(true));
    const response: unreadCountResponse = yield clientServices.getUnreadCount();
    yield put(loaderAction(false));
    yield put(saveUnreadCount(response))
  }
  catch (err) {
  }
}

export function* getMultiClientListSaga() {
  yield takeLatest(TYPES.GET_MULTIPLE_CLIENTS, getmultiClientList);
}

function* getmultiClientList(param: any) {
  try {
    // yield put(loaderAction(true));
    const response: ResponseMultiClientList = yield clientServices.getMultiClientList(param['payload']);
    // yield put(loaderAction(false));
    yield put(saveMultipleClientList(response.resultData))
  }
  catch (err) {
  }
}

export function* getTpStaffTypesDllSaga() {
  yield takeLatest(TYPES.GET_TP_STAFF_TYPE_DLL, getTpStaffTypesDllList);
}

function* getTpStaffTypesDllList(param: any) {
  try {
    yield put(loaderAction(true));
    const response: GetTpStaffTypesResponse = yield clientServices.getTpStaffType();
    yield put(loaderAction(false));
    yield put(saveTpStaffTypeDll(response.resultData))
  }
  catch (err) {
  }
}

export function* insertReviewGoalSaga() {
  yield takeLatest(TYPES.INSERT_REVIEW_GOAL, insertReviewGoalWorker);
}

function* insertReviewGoalWorker(param: any) {
  try {
    yield put(loaderAction(true));
    yield clientServices.insertReviewGoal(param['payload']);
    yield put(loaderAction(false));
    NotificationManager.success(NOTIFICATION_MESSAGE.INSERT_REVIEW_GOAL);
  }
  catch (err) {
  }
}

export function* getReviewGoalByGoalIdSaga() {
  yield takeLatest(TYPES.GET_REVIEW_GOAL_BY_GOAL_ID, getReviewGoalByGoalId);
}

function* getReviewGoalByGoalId(param: any) {
  try {
    yield put(loaderAction(true));
    const response: ReviewGoalResponse = yield clientServices.getReviewGoalByGoalId(param['payload']);
    yield put(loaderAction(false));
    yield put(saveReviewGoalByGoalId(response.resultData))
  }
  catch (err) {
  }
}

export function* deleteReviewGoalSaga() {
  yield takeLatest(TYPES.DELETE_REVIEW_GOAL, deleteReviewGoal);
}

function* deleteReviewGoal(param: any) {
  try {
    yield put(loaderAction(true));
    yield clientServices.deleteReviewGoal(param['payload']);
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}


export function* checkDuplicateClientSaga() {
  yield takeLatest(TYPES.CHECK_DUPLICATE_CLIENTS, checkDuplicateClientWorker);
}

function* checkDuplicateClientWorker(param: any) {
  try {
    // yield put(loaderAction(true));
    const response =yield clientServices.checkDuplicateClient(param['payload']);
    if(response && response.resultData)
    yield put(saveDuplicateClients(response.resultData));
  }
  catch (err) {
  }
}

export function* getClientLedgerByClientIdSaga() {
  yield takeLatest(TYPES.GET_CLIENT_LEDGER_BY_CLIENT_ID, getClientLedgerByClientIdWorker);
}

function* getClientLedgerByClientIdWorker(param: any) {
  try {
    yield put(saveDocLoadingState(true));
    const response = yield clientServices.getClientLedgerByClientId(param['payload']);
    if(response && response.resultData) {
      yield put(clientLedgerBalanceChanged(true))
    }
    yield put(saveClientLedgerByClientId(response.resultData));
    yield put(saveDocLoadingState(false));
  }
  catch (err) {
  }
}

export function* insertClientLedgerRecordSaga() {
  yield takeLatest(TYPES.INSERT_CLIENT_LEDGER_RECORD, insertClientLedgerRecordWorker);
}

function* insertClientLedgerRecordWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.insertClientLedgerRecord(param['payload']);
    if(response && response.resultData) {
      yield put(saveClientListChangedBoolean(true));
      yield put(loaderAction(false));
    }
  }
  catch (err) {
  }
}

export function* deleteClientLedgerRecordSaga() {
  yield takeLatest(TYPES.DELETE_CLIENT_LEDGER_RECORD, deleteClientLedgerRecordWorker);
}

function* deleteClientLedgerRecordWorker(param: any) {
  try {
    yield put(saveDocLoadingState(true));
    const response = yield clientServices.deleteClientLedgerRecordWithId(param['id']);
    if(response && response?.resultData) {
      yield put(saveClientListChangedBoolean(true));
    }
    yield put(saveDocLoadingState(false));
  }
  catch (err) {
  }
}


export function* getPaymentTypesClientLedgerSaga() {
  yield takeLatest(TYPES.GET_CLIENT_LEDGER_PAYMENT_TYPES, getPaymentTypesClientLedgerWorker);
}

function* getPaymentTypesClientLedgerWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientLedgerPaymentTypes(param['isActive']);
    yield put(saveClientLedgerPaymentTypes(response.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getClientLedgerByIdSaga() {
  yield takeLatest(TYPES.GET_CLIENT_LEDGER_BY_ID, getClientLedgerByWorker);
}

function* getClientLedgerByWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientLedgerById(param['id']);
    yield put(saveClientLedgerById(response.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getCurrentLedgerBalanceSaga() {
  yield takeLatest(TYPES.GET_CURRENT_LEDGER_BALANCE, getCurrentLedgerBalanceWorker);
}

function* getCurrentLedgerBalanceWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientLedgerCurrentBalance(param['payload']);
    yield put(saveCurrentLedgerBalance(response?.resultData?.currentBalance));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}


export function* updateClientLedgerSaga() {
  yield takeLatest(TYPES.UPDATE_CLIENT_LEDGER, updateClientLedgerWorker);
}

function* updateClientLedgerWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.updateClientLedger(param['payload']);
    if(response && response.resultData) {
      yield put(saveClientListChangedBoolean(true))
    }
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}