import React, { ComponentState, useEffect, useRef, useState } from "react";
import Loader from "../../../../control-components/loader/loader";

import { ClientService } from "../../../../services/clientService";
import MyComponent from "./questionnaire-module.js/patient-health-questionnaire";
import { renderErrors } from "src/helper/error-message-helper";
import { drawDOM } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-drawing/pdf";
import { useSelector } from "react-redux";
import { QuestionnaireFields } from "src/dataModels/QuestionnaireFields";
import DateTimeHelper from "src/helper/date-time-helper";
import * as ReactDOMServer from "react-dom/server";
import { MaskFormatted } from "src/helper/mask-helper";
import { styles } from "./style";



const PrintQuestionnaire = () => {
  const [loading, setLoading] = useState(false);
  const [isPrintStarted, setPrintStarted] = useState(false);
  const [questionsDetails, setQuestionDetails] = useState<any>();
  const [questionListById, setQuestionListById] = useState([]);
  const [queData, setQueData] = useState<QuestionnaireFields>();
  const clientDetail = useSelector((state: ComponentState) => state.clientDetails);
  const _elementRef = useRef(null);
  const currentInsurance = useSelector(
    (state: ComponentState) => state.currentInsuranceDetails
  );
  const primaryDx = useSelector(
    (state: ComponentState) => state.PrimaryDX
  );
  const clinicDetails = useSelector((state: ComponentState) => state.getClinicDetails);
  let minRankNumber = Infinity;
  let minDiagnoseName = '';
  primaryDx?.forEach(({ rankNumber, icd10 }) => {
    if (rankNumber < minRankNumber) {
      minRankNumber = rankNumber;
      minDiagnoseName = icd10;
    }
  });

  let newObj = clinicDetails?.zip
  let intZip = newObj !== undefined && newObj.slice(0, 5)
  let lastZip = newObj !== undefined && newObj.slice(5)
  let Phone = MaskFormatted(
    clinicDetails ? clinicDetails?.phone : "",
    "(999) 999-9999"
  );


  const myTemplateNew = (args) => {
    const queDataSet: QuestionnaireFields = JSON.parse(localStorage.getItem('print-que-state')!)
    if (!queDataSet) { return ReactDOMServer.renderToString(<></>) }
    return ReactDOMServer.renderToString(
      <div>
        <style>{styles}</style>
        <div style={{
          position: 'absolute', top: 0.5, width: "95%", backgroundColor: "#F6F8FA", padding: "2px 15px",
          marginBottom: "80px", height: 50,
        }}>
          <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", }}>
            <div className="document-sec" >
              <div className='mt-2 mb-0'>
                <div className="customCell">
                  {clientDetail.lName + ', ' + clientDetail.fName}
                </div>

              </div>
            </div>
            <div className="document-sec">
              <p className='mt-2 mb-0' style={{
                color: "#4a4a4b", fontSize: "11px", marginLeft: "0px", marginRight: "0px", paddingRight: "0px",
                fontFamily: "Manrope  !important"
              }}>
                <span style={{ color: "#4a4a4b", fontFamily: "Manrope  !important" }}></span></p>
            </div>
            <div className="document-sec">
              <div className='mb-0 customCell-light' >by {`${queDataSet?.createdByStaff} `}
                <span style={{ color: "#4a4a4b", fontFamily: "Manrope  !important" }}>{''}</span></div>
            </div>

          </div>
          <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginRight: "0px", paddingRight: "0px" }}>

            <div className="document-sec">
              <div className='mt-2 mb-0 customCell' >Created Date:
                <span className="customCell-light">
                  {DateTimeHelper.formatDatePickerString(queDataSet?.utcDateCreated)} </span></div>
            </div>
            <div className="document-sec">
              <div className='mt-2 mb-0 customCell'>Page :<span style={{ color: "#4a4a4b", fontFamily: "Manrope  !important", marginLeft: "2px", }}>
                &nbsp; {args.pageNum} of {args.totalPages}
              </span></div>
            </div>
          </div>
        </div>
        <div style={{
          position: 'absolute', bottom: 0.2, width: "95%", backgroundColor: "#F6F8FA",
          padding: "3px 15px", height: 30
        }}>
          <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", }}>
            <div className="d-flex justify-content-start align-items-center gap-0" >
              <div className='mb-0 mx-0 px-0 pr-0 mr-0 customCell mt-2' >
                {queDataSet?.questionnaire} <span className="px-0 mx-0"></span></div>
            </div>
            <div>
              <div className='mb-0  mt-2 customCell'
              >Page#:<span>
                  &nbsp; {args.pageNum} of {args.totalPages}
                </span></div>
            </div>
          </div>
          <div>
            <div className="document-sec d-flex justify-content-start align-items-center" >
              <div className='mb-0 mx-0 px-0 pr-0 mr-0  mt-2'>

                <span className="px-0 mx-0"></span></div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  useEffect(() => {
    const queDataSet = localStorage.getItem('print-que-state')
    if (queDataSet) {
      setQueData(JSON.parse(queDataSet))
      getQuestionsById(JSON.parse(queDataSet).id);
      const dropDownValue = {
        fullName: JSON.parse(queDataSet)?.questionnaire,
        id: JSON.parse(queDataSet)?.questionnaireId,
      };
      setQuestionDetails(dropDownValue);
    }
  }, []);




  const getQuestionsById = async (queIds) => {
    await ClientService.getQuestionsById(queIds)
      .then((result) => {
        let questionListById1 = result?.resultData;
        setQuestionListById(JSON.parse(questionListById1?.data));
      })
      .catch((error) => {

        renderErrors(error.message);
      });
  };

  React.useEffect(() => {

    if (_elementRef.current && !isPrintStarted) {
      setPrintStarted(true)
      handleSelect();
    }
  }, [_elementRef, isPrintStarted, questionListById]);

  const handleSelect = async () => {
    const optionItem8 = {
      paperSize: "A4",
      margin: {
        left: 0,
        top: 60,
        right: 0,
        bottom: 60,
      },
      fileName: "Document Questionnaire",
      author: "Notenetic Team",
      keepTogether: ".keep-together",
      multiPage: true,
      template: (args) => {
        return myTemplateNew(args)
      },
    }
    setTimeout(() => {

      if (_elementRef.current && _elementRef.current != null) {
        drawDOM(_elementRef.current, { ...optionItem8 })
          .then(async (group) => {
          await  saveAs(group, 'Questionnaire.pdf');
            localStorage.removeItem('print-que-state')
            window.close()
          })
        setLoading(false);
      } else {
        setLoading(false);
      }
    }, 1000);
  }
  const clientHeaderInfo = () => {
    return (
      <div className="grid-template-pdf new-grid-template-pdf grid-template-pdf-head ">
        <div className="col-6" >
          <span style={{ color: "#000", fontSize: "11px", marginLeft: "2px" }}>
            <span style={{ color: "#000", fontSize: "11px", }}>{clientDetail ? clientDetail?.lName : ""}, {clientDetail ? clientDetail?.fName : ""}</span>
          </span>
          <ul className="list-unstyled list-icons-pdf new-list-icons-pdf" style={{ marginBottom: "0px" }}>
            <li style={{ color: "#000", padding: "0" }}>
              <h6 className='mb-0' style={{
                color: "#000", fontSize: "11px", fontWeight: "600",
                fontFamily: "Manrope  !important"
              }}>DOB:
                <span style={{ color: "#4a4a4b", fontFamily: "Manrope  !important", marginLeft: "2px", }}>
                  {DateTimeHelper.formatDatePickerString(clientDetail?.dob)} </span></h6>
            </li>
            <li style={{ color: "#000", padding: "0" }}>
              Ins:
              <span style={{ color: "#4a4a4b", fontFamily: "Manrope  !important", marginLeft: "2px", }}>
                {`${currentInsurance?.insuranceName.split('-')[1]}`} - {`${currentInsurance?.policyNumber}`} </span>


            </li>
            <li style={{ color: "#000", padding: "0" }}>
              <h6 className='mb-0' style={{
                color: "#000", fontSize: "11px", fontWeight: "600",
                fontFamily: "Manrope  !important"
              }}>Primary Dx:
                <span style={{ color: "#4a4a4b", fontFamily: "Manrope  !important", marginLeft: "2px", }}>
                  {minDiagnoseName} </span></h6>
            </li>
          </ul>
        </div>

        <div className="col-6" style={{ paddingTop: "5px" }}>
          <ul className="list-unstyled list-icons-pdf new-list-icons-pdf" style={{ marginBottom: "0px" }}>
            <li style={{ color: "#000", padding: "0" }}>
              <h6 className='mb-0' style={{
                color: "#000", fontSize: "11px", fontWeight: "600",
                fontFamily: "Manrope  !important"
              }}>Program Start Date:
                <span style={{ color: "#4a4a4b", fontFamily: "Manrope  !important", marginLeft: "2px", }}>
                  {DateTimeHelper.formatDatePickerString(clientDetail?.dateStart)} </span></h6>


            </li>
            <li style={{ color: "#000", padding: "0" }}>

              <span style={{
                color: "#000", fontSize: "11px", fontWeight: "600",
                fontFamily: "Manrope  !important"
              }}>{clientDetail?.clinicName}<br />
                <span style={{ color: "#4a4a4b", fontFamily: "Manrope  !important", }}>
                  {(clinicDetails?.address).trim()}<br />
                  {`${clinicDetails?.city}, ${clinicDetails?.state} ${lastZip.length == 0 ? (intZip + lastZip) : (intZip + "-" + lastZip)}`} <br />
                  {Phone}
                </span></span>

            </li>
            <li style={{ color: "#000", padding: "0" }}>

              <h6 className='mb-0' style={{
                color: "#000", fontSize: "11px", fontWeight: "600",
                fontFamily: "Manrope  !important"
              }}>
              </h6>

            </li>

          </ul>
        </div></div>

    )

  }


  return (
    <div >
      <style> {styles}</style>
      {(loading === false && questionListById.length > 0 && (questionsDetails?.enumId || JSON.stringify(queData?.enumId))) ?

        <div
          ref={(div: any) => {
            _elementRef.current = div;
          }}
        >


          {clientHeaderInfo()}
          <MyComponent
            key={queData?.id}
            componentType={
              questionsDetails?.enumId || JSON.stringify(queData?.enumId)
            }
            isPrint={true}
            questionId={questionsDetails?.id}
            questionListById={questionListById}
          />
        </div>
        : <Loader />}
    </div>
    // </div>
  );
};

export default PrintQuestionnaire;
