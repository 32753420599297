import { DropDownList } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import React, { ComponentState, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePickerKendoRct from "src/control-components/date-picker/date-picker";
import { filterBy } from "@progress/kendo-data-query";
import { showError } from "src/util/utility";
import apiHelper from "src/helper/api-helper";
import { Skeleton, TableCell } from "@mui/material";
import { customAxios } from "src/services/useExPressApi";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { TableRowsLoader } from "src/control-components/custom-skelton";
import {
    Grid,
    GridColumn,
} from "@progress/kendo-react-grid";
import DeleteDialogModal from "src/control-components/custom-delete-dialog-box/delete-dialog";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { userIsAdmin, userIsSuperAdmin } from "src/helper/permission-helper";
import AddTimeClock from "./add-clock-form";
import UpdateTimeClockModel from "./update-time-clock";
import { getClockStatus, saveUpdateStaffTimeClockByIdResponse, getTimeClockById, getTimeClockArchiveById, saveTimeClockFilters } from "src/redux/actions";
import CustomPager from "../../control-components/customPager/customPager";
import APP_ROUTES from "src/helper/app-routes";
import { useNavigate } from "react-router";
import { Checkbox, Switch } from "@progress/kendo-react-inputs";
import CustomSplitButton from "src/control-components/split-button";
import { Button } from "@progress/kendo-react-buttons";
import HistoryModel from "./history-time-clock-model";
import useScrollBlock from "src/cutomHooks/scroll";

export const TimeClock = () => {
    const state = useSelector((states: ComponentState) => { return states; });
    const isHumanResourcesManager = state.getRolePermission.isHumanResourcesManager
    const dispatch = useDispatch();
    const latestDataRef = useRef(null);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(25);
    const [skip, setSkip] = React.useState(0);
    const [totalRecord, setTotalRecord] = React.useState(0);
    const [openDateFilter, setOpenDateFilter] = useState(false);
    const [applyButtonClicked, setApplyButtonClicked] = useState(false);
    const staffId = useSelector((state: ComponentState) => state.loggedIn?.staffId);
    const date = new Date();
    const [staffTimeClock, setStaffTimeClock] = useState([]);
    const [staffCurrentlyClockedIn, setStaffCurrentlyClockedIn] = useState([]);
    const [staffTotalHoursWorked, setStaffTotalHoursWorked] = useState([]);
    const [clockMetaData, setClockMetaData] = useState<any>();
    const result = subtractDays(7, date);
    function subtractDays(numOfDays, date = new Date()) {
        const dateCopy = new Date(date.getTime());
        dateCopy.setDate(dateCopy.getDate() - numOfDays);
        return dateCopy;
    }
    const [staffError, setStaffError] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const staffLoginInfo = useSelector((state) => state.getStaffReducer);//useSelector((state: ComponentState) => state.getStaffReducer);
    const [selectedClickId, setSelectedClockId] = useState<any>();
    const clearObj = {
        "pageNumber": page,
        "pageSize": pageSize,
        "skip": skip,
        'staffId': (!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId) || !staffLoginInfo?.isHumanResourcesManager) ? staffId : null,
        'startDate': result,
        'endDate': date
    }
    const reasonData = [{
        id: "Sick Leave", name: "Sick Leave"
    },
    { id: "Vacation Leave", name: "Vacation Leave" },
    { id: "PTO (Paid Time Off)", name: "PTO (Paid Time Off)" },
    { id: "Maternity Leave", name: "Maternity Leave" },
    { id: "Not Near Computer", name: "Not Near Computer" },
    { id: "Forgot to Clock In", name: "Forgot to Clock In" },
    { id: "Forgot to Clock Out", name: "Forgot to Clock Out" },
    { id: "Other", name: "Other" },
    ]
    const [filter, setFilter] = React.useState<any>();
    const [fields, setFields] = useState(clearObj)
    const refStaff = useRef<HTMLInputElement>(null);
    const refStaffClose = useRef<HTMLInputElement>(null);
    const [openStaffFilter, setOpenStaffFilter] = useState(false);
    const [staffList, setStaffList] = useState([]);
    const [loadingStaff, setLoadingStaffList] = useState(false);
    const [loadingClockList, setLoadingClockList] = useState(false);
    const [isInitialDataFetched, setIsInitialDataFetched] = useState(false);
    const [isDeleteConfirm, setDeleteConfirm] = useState(false);
    const [showAddNewClock, setShowAddNewClock] = useState(false);
    const { timeclockStatus, updateClockTimeResponse, timeClockFilters } = state['DashboardReducer']
    const [isOpenTimeClockModel, setIsOpenTimeClockModel] = useState<boolean>(false)
    const [historyModel, setHistoryModel] = useState(false)
    const [staffIdForTimeHistory, setIdForTimeHistory] = useState()
    const [timeHistoryStaffName, setTimeHistoryStaffName] = useState()
    const [showTrashed, setShowTrashed] = useState(false);
    const { staffDDLByClinicId } = state['StaffReducer'];
    const [blockScroll,allowScroll]=useScrollBlock()

    useEffect(() => {
        document.addEventListener('mousedown', handleClickListener);
        return () => {
            document.removeEventListener('mousedown', handleClickListener);
        };
    }, []);
    const handleClickListener = async (event) => {
        if (refStaff.current && !refStaff.current?.contains(event.target)) {
            setTimeout(() => {
                setOpenStaffFilter(false)
            }, 200);
        }
    }

    const closeTimeHistoryDialog = () => {
        setHistoryModel(false);
        // setIdForTimeHistory(false);
    }

    const handleSwitch = async (e) => {
        var changeVal = e.target.value;
        setShowTrashed(changeVal);
    }

    useEffect(() => {
        fetchClockTimeList(fields?.staffId?.id, page, pageSize, fields.startDate, fields.endDate, showTrashed)
    }, [showTrashed])

    useEffect(() => {
        if (updateClockTimeResponse === true) {
            getClockStatus()
            fetchClockTimeList(
                fields?.staffId?.id ? fields?.staffId?.id :
                    (!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId) || !staffLoginInfo.isHumanResourcesManager)
                        ? staffId : (fields.staffId && fields.staffId.id) ? fields.staffId.id : null
                , page, pageSize, fields.startDate, fields.endDate, showTrashed)
            dispatch(saveUpdateStaffTimeClockByIdResponse(false))
        }
        fetchStaffList();
    }, [updateClockTimeResponse])

    useEffect(() => {
        if (!isInitialDataFetched) {
            fetchClockTimeList(
                fields?.staffId?.id ? fields?.staffId?.id :
                    staffLoginInfo.isHumanResourcesManager ? null :
                        (!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId))
                            ? staffId : (fields.staffId && fields.staffId.id) ? fields.staffId.id : null
                , page, pageSize, fields.startDate, fields.endDate, showTrashed)
            setIsInitialDataFetched(true)
        }
    }, [isInitialDataFetched]);

    function onFilterChange(event, listName) {
        setFilter({ ...filter, [listName]: event.filter });
    }
    const clearAllFilter = () => {
        const resetStaffId = userIsSuperAdmin(staffLoginInfo?.roleId) || userIsAdmin(staffLoginInfo?.roleId) || staffLoginInfo.isHumanResourcesManager;
        setFields({
            ...fields,
            staffId: resetStaffId ? null : staffId,
            startDate: result,
            endDate: date
        });
        setFilter({})
        fetchClockTimeList(resetStaffId ? null : staffId, page, pageSize, result, date, showTrashed);
    }

    const viewHistoryClick = (staffId, staffName) => {
        setIdForTimeHistory(staffId)
        setHistoryModel(true);
        setTimeHistoryStaffName(staffName)
    }

    const handleClearStaff = () => {
        const shouldClearStaffId = userIsSuperAdmin(staffLoginInfo?.roleId) || userIsAdmin(staffLoginInfo?.roleId) || staffLoginInfo.isHumanResourcesManager;
        setFields({
            ...fields, 'staffId': shouldClearStaffId ? null : staffId
        });
        setFilter({});
        fetchClockTimeList(
            shouldClearStaffId ? null : staffId,
            page, pageSize, fields.startDate, fields.endDate, showTrashed
        );
    }

    const handleApplyFilter = (event) => {
        setStaffError(true);
        setTimeout(() => {
            setOpenDateFilter(false)
        }, 200);

        fetchClockTimeList(
            fields?.staffId?.id ? fields?.staffId?.id :
                (!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId) || !staffLoginInfo.isHumanResourcesManager)
                    ? staffId : (fields.staffId && fields.staffId.id) ? fields.staffId.id : null
            , page, pageSize, fields.startDate, fields.endDate, showTrashed)
    };

    useEffect(() => {

        if (isInitialDataFetched) {
            fetchClockTimeList(
                fields?.staffId?.id ? fields?.staffId?.id :
                    staffLoginInfo.isHumanResourcesManager ? null :
                        (!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId))
                            ? staffId : (fields.staffId && fields.staffId.id) ? fields.staffId.id : null
                , page, pageSize, fields.startDate, fields.endDate, showTrashed)
        }
    }, [timeclockStatus])

    useEffect(() => {
        if (timeClockFilters) {
          setFields(prevData => ({
            ...prevData,
            startDate: timeClockFilters?.startDate || prevData?.startDate,
            endDate: timeClockFilters?.endDate || prevData?.endDate,
            staffId: timeClockFilters?.staffId || prevData?.staffId
          }));
        }
      }, [timeClockFilters]);

    function fetchStaffList() {
        setLoadingStaffList(true);
        apiHelper.getRequest(API_ENDPOINTS.GET_STAFF_DDL_BY_CLINIC_ID + "true")
            .then((result) => {
                const list = result.resultData.map((r: any) => {
                    return { id: r.id, name: r.name };
                });
                setStaffList(list);
            })
            .catch((err) => {
                showError(err, "Staff List");
            })
            .finally(() => {
                setLoadingStaffList(false);
            });
    }
    const authDelete = async () => {
        setDeleteConfirm(false);
        const clockData = await customAxios.delete<any, any>(`${API_ENDPOINTS.DELETE_STAFF_CLOCK}?id=${selectedClickId}`);
        setStaffTimeClock([])
        fetchClockTimeList((!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId)
            || !staffLoginInfo.isHumanResourcesManager) ? staffId : (fields.staffId && fields.staffId.id) ? fields.staffId.id : null, page, pageSize, fields.startDate, fields.endDate, showTrashed)
    };
    async function fetchClockTimeList(staffIds: number, pageNos: number, pageSizes: number, startDates: Date, endDates: Date, showTrashed: boolean) {
        setLoadingClockList(true)
        setCheckedClocks([])
        setAllSelected([])
        const clockData = await customAxios.post<any, any>(`${API_ENDPOINTS.GET_STAFF_TIME_CLOCK}`, {
            "staffId": staffIds,
            "startDate": moment(startDates).format("YYYY-MM-DD"),
            "endDate": moment(endDates).format("YYYY-MM-DD"),
            "pageNumber": pageNos > 0 ? pageNos : 1,
            "pageSize": pageSizes,
            "isActive": showTrashed ? 0 : 1,
        });
        if (clockData && clockData.status) {
            setStaffTimeClock(clockData.resultData.staffTimeClock)
            setStaffCurrentlyClockedIn(clockData.resultData.staffCurrentlyClockedIn)
            setStaffTotalHoursWorked(clockData.resultData.staffTotalHoursWorked)
            setClockMetaData(clockData.metaData)
            if (clockData.resultData.staffTimeClock.length > 0)
                setTotalRecord(clockData.resultData?.staffTimeClock[0][0]?.totalRecords)
            else
                setTotalRecord(0);
        }
        setLoadingClockList(false)
    }

    const handleDeleteAuth = () => {
        setDeleteConfirm(!isDeleteConfirm);
        if(!isDeleteConfirm){
            blockScroll()
        }else{
            allowScroll()
        }
    };

    const deleteClock = (id) => {
        setDeleteConfirm(true);
        setSelectedClockId(id);
            blockScroll()
    };


    const handleChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        latestDataRef.current = value;
        if (value == "" && (name === "startDate" || name === "endDate")) {
            value = null;
        }
        const newAdvSearchFileds = {
            ...fields,
            [name]: value,
        };
        // flushSync(() => {setFields(newAdvSearchFileds)})
        setFields(newAdvSearchFileds);

        if (name === 'staffId') {
            fetchClockTimeList(value.id, page, pageSize, fields.startDate, fields.endDate, showTrashed)
        }
        dispatch(saveTimeClockFilters({ ...timeClockFilters, [name]: value }));
    };

    const handleAddNewClock = () => {
        setShowAddNewClock(!showAddNewClock)
    };

    const renderHeaderFilters = () => {

        return (
            <div className="row align-items-center searchBoxShadow mx-0">
                <div className="col-lg-9 col-sm-12">
                    <div className="content-search-filter  px-0 filter-drop-down">
                        {/*start click major filter */}
                        <div className="major-filter">
                            <div
                                onClick={() => setOpenDateFilter(true)}

                                onBlur={() => {
                                    if (!applyButtonClicked) {
                                        setOpenDateFilter(false);
                                    }
                                }}
                                className="service-main  authCalender dropdown email-filter border-dashed-cus position-relative"
                            >
                                <button className="btn  btn-size-cus " type="button">
                                    Date{" "}
                                    <span className="border-spann">
                                        {moment(fields?.startDate).format("MM/DD/yyyy") +
                                            " - " +
                                            moment(fields?.endDate).format("MM/DD/yyyy")}
                                    </span>
                                </button>



                                {openDateFilter && (
                                    <div className="dropdown-service auth_drop_service "
                                        onFocus={() => setOpenDateFilter(true)}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <DatePickerKendoRct
                                                    validityStyles={staffError}
                                                    value={fields.startDate && new Date(fields.startDate)}
                                                    onChange={handleChange}
                                                    name="startDate"
                                                    label="Start Date"
                                                    placeholder="Start Date"
                                                    error={errors.startDate && errors.startDate}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <DatePickerKendoRct
                                                    validityStyles={staffError}
                                                    value={fields.endDate && new Date(fields.endDate)}
                                                    onChange={handleChange}
                                                    name="endDate"
                                                    label="End Date"
                                                    placeholder="End Date"
                                                    error={errors.endDate && errors.endDate}
                                                    required={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-2"

                                            onMouseDown={() => setApplyButtonClicked(true)}
                                            onMouseUp={() => setApplyButtonClicked(false)}
                                        >

                                            <div className="col-12"
                                            >
                                                <div className="text-end apply-search-btn">
                                                    <button onClick={handleApplyFilter} type="button">
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* create click2 start */}


                            {
                                isHumanResourcesManager ||
                                    userIsSuperAdmin(staffLoginInfo?.roleId)
                                    || userIsAdmin(staffLoginInfo?.roleId) ?
                                    <>
                                        {
                                            (loadingStaff) ?
                                                <TableCell component="th" scope="row" width={200}>
                                                    <Skeleton animation="wave" variant="text" />
                                                </TableCell> : <div
                                                    onClick={(event: any) => {

                                                        if (!refStaffClose.current?.contains(event.target)) {
                                                            setOpenStaffFilter(true)
                                                        }
                                                    }}
                                                    ref={refStaff}
                                                    className="service-main dropdown email-filter border-dashed-cus position-relative"
                                                >
                                                    <button className="btn  btn-size-cus pl-0 " type="button">
                                                        {fields?.staffId != null && fields?.staffId.name ?
                                                            <i ref={refStaffClose}
                                                                onClick={handleClearStaff}
                                                                className={"fa fa-times cross-icon mr-2 "}
                                                            ></i>
                                                            :
                                                            <i className={"fa fa-plus cross-icon "}></i>}

                                                        Staff {fields?.staffId != null && fields?.staffId.name && (

                                                            <span
                                                                className={fields?.staffId != null && fields?.staffId.name ? 'border-spann' : ''}
                                                            >
                                                                {fields?.staffId.name
                                                                }
                                                            </span>
                                                        )}
                                                    </button>


                                                    {openStaffFilter && (
                                                        <div className="dropdown-service dropDates ">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <DropDownList
                                                                        data={(filter && filter.staffList && filter.staffList != null) ? filterBy(staffList, filter.staffList) : staffList}
                                                                        onFilterChange={(event) => {
                                                                            onFilterChange(event, "staffList");
                                                                        }}
                                                                        textField="name"
                                                                        label="Staff Name"
                                                                        name="staffId"
                                                                        value={fields?.staffId}
                                                                        onChange={handleChange}

                                                                        dataItemKey={"id"}

                                                                        filterable={true}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                        }
                                    </> : <div></div>
                            }

                            <div className="px-1  switch-on my-1">
                                <Switch
                                    onChange={handleSwitch}
                                    checked={showTrashed}
                                    onLabel={""}
                                    offLabel={""}
                                />
                                <span className="switch-title-text ">Show Trashed</span>
                            </div>


                        </div>

                        {/* create click2  end*/}
                        {/* end click filter */}
                    </div>
                </div>



                <div className="col-lg-3 col-sm-12 d-flex justify-content-end clear-add-filter">

                    <div className="mr-3 ml-2">
                        <p className="mb-0" onClick={clearAllFilter}>
                            Clear Filter
                        </p>
                    </div>

                </div>


            </div>
        )

    };


    const staffCurrentlyClockedInWidget = () => {
        return (
            <>
                <div style={{ marginTop: 40, marginBottom: 6 }}>
                    <h4 className="address-title text-grey ">
                        <span className="f-24">Staff Currently Clocked In</span>
                    </h4>
                    <h4 className="address-title text-grey mb-12">
                        <span className="f-14">The following is a list of currently clocked in staff, including when they clocked in and how long they have been clocked in for.</span>
                    </h4>
                </div>
                <Grid
                    data={staffCurrentlyClockedIn}
                    style={{
                        height: "100%",
                    }}
                    className="pagination-row-cus"
                    filter={filter}
                >
                    <GridColumn
                        field="staffId"
                        title="Staff ID"
                        className="cursor-default"
                    />
                    <GridColumn
                        field="staff"
                        title="Staff"
                        className="cursor-default"
                    />
                    <GridColumn
                        title="Time In"
                        cell={(props) => {
                            let field = props.dataItem.isCustomClock ?
                                (props.dataItem.clockIn)
                                : moment.utc(props.dataItem.clockIn).local().format("M/D/YYYY hh:mm A");

                            const finalDateStr = field + '(' + props.dataItem.durationTime + ')'

                            return < td className="cursor-default">{(props.dataItem.clockIn && props.dataItem.clockIn != null) ? moment.utc(props.dataItem.clockIn).local().format("M/D/YYYY hh:mm A") : '-'}</td>;
                        }}
                    />

                </Grid>
            </>
        )
    }
    const handleApproveDisApprove = async (isApproved) => {
        setDeleteConfirm(false);
        allowScroll()
        var result = checkedClocks.map(a => a.id);
        await customAxios.post<any, any>(`${API_ENDPOINTS.APPROVE_TIME_CLOCK}`, { 'ids': result, 'isApproved': isApproved });
        setStaffTimeClock([])
        fetchClockTimeList(
            fields?.staffId?.id ? fields?.staffId?.id :
                (!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId) || !staffLoginInfo.isHumanResourcesManager)
                    ? staffId : (fields.staffId && fields.staffId.id) ? fields.staffId.id : null
            , page, pageSize, fields.startDate, fields.endDate, showTrashed)
    }


    const staffTotalHoursWorkedWidget = () => {
        return (
            <>
                <div style={{ marginTop: 40, marginBottom: 6 }}>
                    <h4 className="address-title text-grey ">
                        <span className="f-24">Staff Total Hrs Worked</span>
                    </h4>

                </div>
                <Grid
                    data={staffTotalHoursWorked}
                    style={{
                        height: "100%",
                    }}
                    className="pagination-row-cus "
                    filter={filter}
                >
                    <GridColumn
                        field="staffId"
                        className="cursor-default"
                        title="Staff ID"
                    />
                    <GridColumn
                        field="staff"
                        title="Staff"
                        className="cursor-default"
                    />
                    <GridColumn
                        field="durationTime"
                        title="Total Hours"
                        className="cursor-default"
                    />
                </Grid>
            </>
        )
    }

    const renderTimeByStaff = () => {
        return (<>
            {
                staffTimeClock.map((datStaffClock: any, index) => {
                    return (<>
                        {(datStaffClock && datStaffClock.length > 0) &&
                            <div className="row timeClockGridHeader">
                                <div className="col-lg-12">
                                    <span style={{ float: 'left', margin: 5 }} ><b>{datStaffClock[0]?.staff}</b></span>
                                    <span style={{ float: 'right', margin: 5 }}><b>Total Hrs: {datStaffClock[0]?.totalTimeStaff}</b></span>
                                </div>
                            </div>
                        }
                        <div className="row">
                            {
                                renderTimeLog(datStaffClock, index)
                            }
                        </div>

                    </>)
                })
            }

        </>)
    }

    function toHoursAndMinutes(totalMinutes) {
        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;
        var hoursStr = hours > 0 ? `${hours}h ` : "";
        var minutesStr = minutes > 0 ? `${minutes}m` : "";
        const formatedTime = `${hoursStr}${minutesStr}`;
        return formatedTime;
    }
    const SELECTED_FIELD = "selected";
    const [checkedClocks, setCheckedClocks] = React.useState<any[]>([]);
    const [parentSelectedList, setAllSelected] = useState<number[]>([]);

    function onCheckboxChange(e, docObj) {
        docObj.isSelected = false
        if (e.value) {
            setCheckedClocks([...checkedClocks, docObj]);
        }
        else {
            setCheckedClocks(checkedClocks?.filter((obj) => obj.id != docObj.id));
        }
    }

    const CustomCheckBox = (props) => {
        let docObj: any = props.dataItem;
        return (
            <td>
                <div className="k-chip-content">
                    <Checkbox
                        label={""}
                        value={!!checkedClocks?.find((obj) => docObj?.id == obj?.id)}
                        onChange={(e) => onCheckboxChange(e, docObj)}
                    />
                </div>
            </td>
        );
    };
    const onHeaderSelectionChanges = (event, mainIndex) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        let data = event.dataItems

        if (checked) {
            setCheckedClocks(current => [...current, ...data])
            setAllSelected(oldArray => [...oldArray, mainIndex]);
        }
        else {
            for (let checkedList in checkedClocks) {
                setCheckedClocks(checkedClocks?.filter((obj) => !data.includes(obj)))
            }
            setAllSelected(parentSelectedList?.filter((item, index) => item !== mainIndex))
        }
    }



    const renderTimeLog = (gridData, mainIndex) => {
        return (<>


            <Grid
                data={gridData}
                selectedField={SELECTED_FIELD}
                onHeaderSelectionChange={(event) => onHeaderSelectionChanges(event, mainIndex)}
                style={{ height: "100%", cursor: "default" }}
                filter={filter}>

                {(staffLoginInfo.isHumanResourcesManager || staffLoginInfo.userIsSuperAdmin) &&
                    <GridColumn
                        cell={CustomCheckBox}
                        className="cursor-default  "
                        width="50px"
                        field={SELECTED_FIELD}
                        headerSelectionValue={parentSelectedList.includes(mainIndex)}
                    />}
                <GridColumn
                    field="staff"
                    className="cursor-default"
                    title="Staff"
                />
                <GridColumn
                    title="Time In" className="cursor-default"
                    cell={(props) => {
                        let field = props.dataItem.isCustomClock ?
                            (props.dataItem.clockIn)
                            : moment.utc(props.dataItem.clockIn).local().format("M/D/YYYY hh:mm A");

                        return <td className="cursor-default">{(props.dataItem.clockIn && props.dataItem.clockIn != null) ? moment.utc(props.dataItem.clockIn).local().format("M/D/YYYY hh:mm A") : '-'}</td>;
                    }}
                />
                <GridColumn className="cursor-default"
                    title="Time Out"
                    cell={(props) => {
                        let field = props.dataItem.isCustomClockOut ?
                            (props.dataItem.clockOut)
                            : moment.utc(props.dataItem.clockOut).local().format("M/D/YYYY hh:mm A");
                        return <td className="cursor-default">{(props.dataItem.clockOut && props.dataItem.clockOut != null) ? moment.utc(props.dataItem.clockOut).local().format("M/D/YYYY hh:mm A") : '-'}</td>;
                    }}
                />
                <GridColumn
                    title="Hours Worked" className="cursor-default"
                    cell={(props) => {
                        let fieldStr = props.dataItem.durationTime
                        if (!fieldStr || fieldStr == null) {
                            var startDate = moment(new Date());
                            var endDate = moment.utc(props.dataItem.clockIn).local()
                            fieldStr = toHoursAndMinutes(startDate.diff(endDate, 'minutes'))
                        }
                        return <td className="cursor-default">{(fieldStr && fieldStr != null) ? fieldStr : '0 m'}</td>;
                    }}
                />

                <GridColumn className="cursor-default"
                    title="Approved"
                    cell={(props) => {
                        let fieldStr = props.dataItem.isApproved
                        if (fieldStr && fieldStr === true)
                            return (<td className="cursor-default"><i className="fa fa-check-circle active" style={{ color: "green", fontSize: "15px" }}></i></td>);
                        else if (fieldStr === false)
                            return (<td className="cursor-default"><i className="fa fa-times-circle active" style={{ color: "red", fontSize: "15px" }}></i></td>);
                        else return <td className="cursor-default"></td>
                    }}
                />
                <GridColumn
                    width="150px"
                    title="Action"
                    className="cursor-default"
                    filterable={false}
                    cell={(props) => {
                        let field = props.dataItem.id;
                        return (
                            <td>
                                <div className="row-3">
                                    {(userIsSuperAdmin(staffLoginInfo?.roleId) || staffLoginInfo?.isHumanResourcesManager) && !showTrashed &&
                                        <div
                                            className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base m-2 cursor-pointer"
                                            onClick={() => editTimeClock(field)}
                                        >
                                            <div className="k-chip-content">
                                                <Tooltip
                                                    anchorElement="target"
                                                    position="top"
                                                >
                                                    <i
                                                        className="fas fa-edit cursor-pointer"
                                                        title="Edit"
                                                        role="button"
                                                    ></i>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    }
                                    {!showTrashed && <div
                                        className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base mr-2 cursor-pointer"
                                        onClick={() => deleteClock(field)}
                                    >
                                        <div className="k-chip-content cursor-pointer">
                                            <Tooltip
                                                anchorElement="target"
                                                position="top"
                                            >
                                                <i
                                                    className="fa fa-trash cursor-pointer"
                                                    aria-hidden="true"
                                                    title="Delete"
                                                    role="button"
                                                ></i>
                                            </Tooltip>
                                        </div>
                                    </div>}
                                    {(userIsSuperAdmin(staffLoginInfo?.roleId) || staffLoginInfo?.isHumanResourcesManager) && <div
                                        className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base"
                                        onClick={() => viewHistoryClick(props.dataItem.id, props.dataItem.staff)}
                                    >
                                        <div className="k-chip-content cursor-pointer">
                                            <Tooltip
                                                anchorElement="target"
                                                position="top"
                                            >
                                                <i
                                                    className="fa fa-history cursor-pointer"
                                                    aria-hidden="true"
                                                    title="History"
                                                    role="button"
                                                ></i>
                                            </Tooltip>
                                        </div>
                                    </div>}
                                </div>
                            </td>
                        );
                    }}
                />

            </Grid></>)
    }

    const renderAddClockTimeForm = () => {
        return (<>
            <AddTimeClock
                refetchData={() => {
                    setShowAddNewClock(false)
                    fetchClockTimeList(fields?.staffId?.id, page, pageSize, fields.startDate, fields.endDate, showTrashed)
                }}
                onClose={handleAddNewClock} />
        </>)
    }

    const editTimeClock = (id: Number) => {
        setSelectedClockId(id);
        setIsOpenTimeClockModel(!isOpenTimeClockModel)
        if(!isOpenTimeClockModel){
            blockScroll()
        }else{
            allowScroll()
        }
    }

    const navigate = useNavigate();

    const handleGenerateReport = () => {
        navigate(APP_ROUTES.GENERATE_TIMECLOCK_REPORT)
    }
    return (
        <>
            <div className="grid-table  filter-grid">
                <div className="d-flex " style={{ justifyContent: "space-between" }}>
                    <div className="col-lg-3 col-sm-3">
                        <h6 className="f-24">Time Clock </h6>
                    </div>
                    <div className="row">
                        {(checkedClocks && checkedClocks.length > 0 && !showTrashed) &&
                            <div >
                                <CustomSplitButton
                                    items={[{ name: 'Approve selected' }, { name: 'Disapprove selected' }]}
                                    text="Clock Actions" onChange={(option) => {
                                        handleApproveDisApprove(option.itemIndex === 0)
                                    }} size={"small"} />
                            </div>}

                        {(userIsSuperAdmin(staffLoginInfo?.roleId) || isHumanResourcesManager) &&
                            <Button
                                onClick={handleGenerateReport}
                                className="btn blue-primary-outline mx-2 mr-3"
                            >
                                Generate Report
                            </Button>



                        }
                    </div></div>
                <div className="">
                    <div className="row">
                        <div className="col-lg-12"> {renderHeaderFilters()}</div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-lg-4">
                            <div >{renderAddClockTimeForm()}</div>
                        </div>
                        <div className="col-lg-8">
                            <div className="staff-profile-page" style={{ margin: 15 }}>
                                {
                                    loadingClockList ? <div><TableRowsLoader rowsNum={12} /></div> :
                                        <div>
                                            <div className="client-accept gridCursor">
                                                <div className="address-line-content mt-3  table-heading-auth">
                                                    {renderTimeByStaff()}
                                                    <br></br>
                                                    <div className="row">
                                                        {totalRecord > 0 && <CustomPager
                                                            resultData={staffTimeClock}
                                                            total={totalRecord}
                                                            fetchClockTimeList={fetchClockTimeList}
                                                            fields={fields}
                                                            skip={skip}
                                                            setSkip={setSkip}
                                                        />
                                                        }
                                                    </div>
                                                    {
                                                        (userIsSuperAdmin(staffLoginInfo?.roleId) || userIsAdmin(staffLoginInfo?.roleId) || staffLoginInfo.isHumanResourcesManager) && !showTrashed &&
                                                        <div>
                                                            {staffCurrentlyClockedInWidget()}
                                                            {staffTotalHoursWorkedWidget()}
                                                        </div>
                                                    }

                                                    {isDeleteConfirm && (
                                                        <DeleteDialogModal
                                                            onClose={handleDeleteAuth}
                                                            title="Time Clock"
                                                            message="time clock"
                                                            handleDelete={authDelete}
                                                        />
                                                    )}

                                                    {/* {showAddNewClock && (
                                                        <AddClock
                                                            refetchData={() => {
                                                                setShowAddNewClock(false)
                                                                fetchClockTimeList(fields?.staffId?.id, page, pageSize, fields.startDate, fields.endDate)
                                                            }}
                                                            onClose={handleAddNewClock} />
                                                    )} */}
                                                </div>
                                            </div>

                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isOpenTimeClockModel && <UpdateTimeClockModel onClose={editTimeClock} selectedClickId={selectedClickId} />}
            {historyModel && <HistoryModel onClose={closeTimeHistoryDialog} staffIdTimeClock={staffIdForTimeHistory} message="Grid" title={`Time clock history for ${timeHistoryStaffName}`} />}
        </>
    )

}
