import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePickerKendoRct from "../../control-components/date-picker/date-picker";
import TextAreaKendoRct from "../../control-components/kendo-text-area/kendo-text-area";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router";
import AppRoutes from "../../helper/app-routes";
import Loader from "../../control-components/loader/loader";
import { TaskService } from "../../services/taskService";
import ErrorHelper from "../../helper/error-helper";
import { renderErrors } from "src/helper/error-message-helper";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { Error } from "@progress/kendo-react-labels";
import DateTimeHelper from "src/helper/date-time-helper";
import moment from "moment";
import { customAxios } from "src/services/useExPressApi";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import DropDownKendoRct from "src/control-components/drop-down/drop-down";
import { getClockStatus, insertNewTImeClock } from "src/redux/actions";

const AddTimeClock = ({ onClose, refetchData }) => {
    const navigate = useNavigate();
    const state = useSelector(states => { return states; });
    const { timeclockStatus } = state['DashboardReducer']
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState("");
    const [settingError, setSettingError] = useState(false);
    const staffId = useSelector((state) => state.loggedIn?.staffId);
    const dispatch = useDispatch();
    const [isClockIn, setClockIn] = useState(false);
    const [fields, setFields] = useState({
        clockDate: new Date(),
        clockTime: new Date(),
        reason: "",
        description: ""
    });
    
    useEffect(() => {
        setLoading(true);
        if (!timeclockStatus || timeclockStatus.staffId != staffId) {
            dispatch(getClockStatus())
        } else {
            setClockIn(timeclockStatus.isClockIn)
        }
        // refetchData();
        setLoading(false);
    }, [timeclockStatus])

    const reasonData = [
        { id: "Sick Leave", name: "Sick Leave" },
        { id: "Vacation Leave", name: "Vacation Leave" },
        { id: "PTO (Paid Time Off)", name: "PTO (Paid Time Off)" },
        { id: "Maternity Leave", name: "Maternity Leave" },
        { id: "Not Near Computer", name: "Not Near Computer" },
        { id: "Forgot to Clock In", name: "Forgot to Clock In" },
        { id: "Forgot to Clock Out", name: "Forgot to Clock Out" },
        { id: "Other", name: "Other" },
    ]
    const updateTask = async () => {
        setLoading(true);
        await TaskService.updateTask(fields, 23)
            .then((result) => {
                setLoading(false);
                NotificationManager.success("Task updated successfully");
                onClose({ edited: true });
            })
            .catch((error) => {
                setLoading(false);
                renderErrors(error);
            });
    };
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFields({
            ...fields,
            [name]: value,
        });
    };
    const saveTask = async () => {
        if (handleValidation()) {
            const inClockTime = DateTimeHelper.concatDateTimeOfTwoDates(
                fields.clockDate,
                fields.clockTime
            );
            const requestObj = {
                'clockDateTime': moment.utc(inClockTime).format('YYYY-MM-DD HH:mm:ss'),
                "reason": fields.reason.name,
                "description": fields.description,
                "isCustomClock": true
            }
            setLoading(true)
            const clockData = await customAxios.post(`${API_ENDPOINTS.CREATE_NEW_TIMECLOCK}`, requestObj);
            refetchData()
            setLoading(false)
            clearFields();
            dispatch(getClockStatus())
        }
    };
    const handleSubmit = () => {
        setSettingError(true);
        if (handleValidation()) {
            if (false) {
                updateTask();
            } else {
                saveTask();
            }
        }
    };
    const handleCancle = () => {
        navigate(AppRoutes.TASK_MANAGER);
    };
    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (!fields.description || fields.description.length < 1) {
            formIsValid = false;
            errors["description"] = ErrorHelper.FIELD_BLANK;
        }
        if (!fields.reason || fields.reason.length < 1) {
            formIsValid = false;
            errors["reason"] = ErrorHelper.FIELD_BLANK;
        }
        if (!fields.clockDate || fields.clockDate.length < 1) {
            formIsValid = false;
            errors["clockDate"] = ErrorHelper.FIELD_BLANK;
        }
        if (!fields.clockTime || fields.clockTime.length < 1) {
            formIsValid = false;
            errors["clockTime"] = ErrorHelper.FIELD_BLANK;
        }
        setErrors(errors);
        return formIsValid;
    };
    const clearFields = () => {
        setFields({
            clockDate: new Date(),
            clockTime: new Date(),
            reason: {},
            description: ""
        });
    }
    return (
        <div style={{ backgroundColor: "rgb(242 242 242)", padding: 15 }}>

            <div className="row">
                <div className="col-lg-6">
                    <b>Custom
                        {
                            isClockIn ? " Clock Out" : " Clock In"
                        }

                    </b>
                </div>
                <div className="col-lg-6 col-md-6" >
                    <button
                        onClick={() => {
                            dispatch(insertNewTImeClock())
                        }}
                        className="btn btn-success"

                        style={{ float: "right" }}
                    >
                        <i class="fa-regular fa-hand-point-right"></i>
                        {
                            isClockIn ? " Click to CLOCK OUT" : " Click to CLOCK IN"
                        }

                    </button>
                </div>

            </div>
            <hr></hr>
            <div className="row">
                <br></br>
                <div className="mb-2 col-lg-6 col-md-6 align-clock">
                    <DatePickerKendoRct
                        onChange={handleChange}
                        format={"MM/dd/YYYY"}
                        placeholder=""
                        value={fields?.clockDate}
                        name={"clockDate"}
                        fillMode={"solid"}
                        size={"medium"}
                        title={"Clock Date"}
                        label={"Clock Date"}
                        weekNumber={false}
                        error={!fields?.clockDate && errors.clockDate}
                        required={true}
                        validityStyles={!!errors.clockDate}
                    />
                </div>

                <div className="mb-2 col-lg-6 col-md-6 align-clock">
                    <TimePicker
                        name={"clockTime"}
                        fillMode={"solid"}
                        size={"medium"}
                        title={"Clock Time"}
                        label={"Clock Time"}
                        value={fields?.clockTime}
                        onChange={handleChange}
                    />
                    {
                        (!fields?.clockTime && errors.clockTime) && <Error> {errors.clockTime}</Error>
                    }
                </div>

                <div className="mb-2 col-lg-12 col-md-12 col-12 clock">
                    <DropDownKendoRct
                        name="reason"
                        label="Select Reason"
                        onChange={handleChange}
                        data={reasonData}
                        value={fields.reason}
                        textField="name"
                        error={!fields?.reason && errors?.reason}
                        validityStyles={!!errors?.reason}
                        required={true}
                    />

                </div>


                <div className="col-md-12 col-lg-12 col-12 mb-4">
                    <TextAreaKendoRct
                        name="description"
                        label="Description"
                        txtValue={fields.description}
                        onChange={handleChange}
                        value={fields?.description}
                        placeholder={"Enter description..."}
                        error={!fields?.description && errors?.description}
                        required={settingError}
                    />
                </div>


            </div>
            {loading && <Loader />}
            <div className="row my-12 justify-content-end m-0" style={{ padding: 15 }}>
                <div className="d-flex">
                    <div>
                        <button
                            onClick={handleSubmit}
                            disabled={loading}
                            className="btn blue-primary text-white "
                        >
                            {
                                isClockIn ? "Clock Out" : "Clock In"
                            }

                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AddTimeClock;
