import React, { useEffect } from "react";
import HealthQuestionnaire from "./questionnaire-pages/health-questionnaire-module";
import {
  GAD,
  Connor,
  PHQ,
  PrimeScreenQuestionName,
  ACE,
  CATS,
  RecentValues,
  Interference,
  MoodDisorder,
  NICHQ,
  NICHQ_PARENT_PERFORMANCE,
  NICHQ_TEACHER_ASSESSMENT,
  NICHQ_TEACHER_PERFORMANCE,
  NICHQ_FOLLOWUP_PARENT,
  NICHQ_FOLLOWUP_PARENT_PERFORMANCE,
  NICHQ_FOLLOWUP_PARENT_SIDE_EFFECT,
  NICHQ_FOLLOWUP_TEACHER,
  NICHQ_FOLLOWUP_TEACHER_PERFORMANCE,
  NICHQ_FOLLOWUP_TEACHER_SIDE_EFFECT,
  NICHQ_SET1_HEADERS,
  NICHQ_SET3_HEADERS,
  NICHQ_SET4_HEADERS,
  NICHQ_SET2_HEADERS,
  NICHQ_S1_COMMENT,
  NICHQ_S2_COMMENT,
  NICHQ_S3_COMMENT,
  NICHQ_S4_COMMENT,
  NICHQ_TEACHER_BEH_PERFORMANCE,

  Gad7Ad,
  Gad7Ad_HEADERS,

  BDSS,
  BDSS_HEADERS,
  BDSS_ADDON,

  SPSRSBNS_PS,
  SPSRSBNS_PS_ADDON,
  SPSRSBNS_NS,
  SPSRSBNS_NS_ADDON,


  PHQ9MFT,
  PHQ9MFTAddON,
  PHQ9MFT_HEADERS,

  QIDS_A17,
  QIDS_A17_SCORING,
  QIDS_A17_HEADERS,


  QIDS_SR,
  QIDS_SR_SCORING,
  QIDS_HEADERS,

} from "./question-list/questionList";
import Header from "./header";
import GeneralAnxietyDisorder from "./questionnaire-pages/general-anxity-disorder";
import ConnorAbbreviatedRatingScale from "./questionnaire-pages/connor-abbreviated-rating-scale";
import MoodDisorderQuestionnaire from "./questionnaire-pages/mood-disorder";
import PrimeScreen from "./questionnaire-pages/prime-screen";
import Default from "./questionnaire-pages/default";
import AdverseChildhood from "./questionnaire-pages/Adverse-childhood-experience";
import ChildAdolesent from "./questionnaire-pages/child-adolescent-trauma";
import NICHQAssessment from "./questionnaire-pages/NICHQ-AssessmentScale";


import { Gad7 } from "./questionnaire-pages/general-anxity-disorder_Adolescent";
import { PHQ_9_TEEN } from "./questionnaire-pages/Phq-9-Teens";
import { QIDS_SelfReport } from "./questionnaire-pages/QIDS-SR";
import { QIDS_Adolescent } from "./questionnaire-pages/QIDS-A17";
import { SPSRSBNS_Scale } from "./questionnaire-pages/SPSRSBNS";
import { BDSS_Scale } from "./questionnaire-pages/BDSS";

const MyComponent = ({ componentType, questionId, questionListById, isPrint }) => {


  let componentToRender;
  // Use switch case to determine which component to render

  switch (componentType) {
    case "10":
      componentToRender = (
        <GeneralAnxietyDisorder
          list={GAD}
          questionId={questionId}
          response={questionListById}
          isPrint={isPrint}
        />
      );
      break;
    case "11":
      componentToRender = (
        <ConnorAbbreviatedRatingScale
          list={Connor}
          questionId={questionId}
          response={questionListById}
          isPrint={isPrint}
        />
      );
      break;
    case "12":
      componentToRender = (
        <ChildAdolesent
          list={CATS}
          questionId={questionId}
          subList={RecentValues}
          interfered={Interference}
          response={questionListById}
          isPrint={isPrint}
        />
      );
      break;
    case "13":
      componentToRender = (
        <MoodDisorderQuestionnaire
          questionId={questionId}
          list={MoodDisorder}
          response={questionListById}
          isPrint={isPrint}
        />
      );
      break;
    case "14":
      componentToRender = (
        <PrimeScreen
          list={PrimeScreenQuestionName}
          questionId={questionId}
          response={questionListById}
          isPrint={isPrint}
        />
      );
      break;
    case "15":
      componentToRender = (
        <HealthQuestionnaire
          list={PHQ}
          questionId={questionId}
          isPrint={isPrint}
          response={questionListById}
        />
      );
      break;
    case "16":
      componentToRender = (
        <AdverseChildhood
          list={ACE}
          isPrint={isPrint}
          questionId={questionId}
          response={questionListById}
        />
      );
      break;

    case "17":
      componentToRender = (
        <NICHQAssessment
          list_p_assessment={NICHQ}
          list_p_performance={NICHQ_PARENT_PERFORMANCE}
          list_t_assessment={NICHQ_TEACHER_ASSESSMENT}
          list_t_performance={NICHQ_TEACHER_PERFORMANCE}
          list_parent_followup={NICHQ_FOLLOWUP_PARENT}
          list_parent_followup_performance={NICHQ_FOLLOWUP_PARENT_PERFORMANCE}
          list_parent_side_effect={NICHQ_FOLLOWUP_PARENT_SIDE_EFFECT}
          list_teacher_followup={NICHQ_FOLLOWUP_TEACHER}
          list_teacher_followup_performance={NICHQ_FOLLOWUP_TEACHER_PERFORMANCE}
          list_teacher_side_effect={NICHQ_FOLLOWUP_TEACHER_SIDE_EFFECT}
          head1={NICHQ_SET1_HEADERS}
          head2={NICHQ_SET2_HEADERS}
          head3={NICHQ_SET3_HEADERS}
          head4={NICHQ_SET4_HEADERS}
          comment1={NICHQ_S1_COMMENT}
          comment2={NICHQ_S2_COMMENT}
          comment3={NICHQ_S3_COMMENT}
          comment4={NICHQ_S4_COMMENT}
          list_t_performance_beh={NICHQ_TEACHER_BEH_PERFORMANCE}
          isPrint={isPrint}
          questionId={questionId}
          response={questionListById}
        />
      );
      break;

    case "18":
      componentToRender = (

        <Gad7

          Gad7Ads={Gad7Ad}
          Gad7Ad_HEADERS={Gad7Ad_HEADERS}
          isPrint={isPrint}
          questionId={questionId}
          response={questionListById}
        />
      );
      break;

    case "19":
      componentToRender = (

        <BDSS_Scale


          BDSS={BDSS}
          BDSS_HEADERS={BDSS_HEADERS}
          BDSS_ADDON={BDSS_ADDON}

          questionId={questionId}
          isPrint={isPrint}
          response={questionListById}
        />
      );
      break;

    case "20":
      componentToRender = (
        <PHQ_9_TEEN

          PHQ9MFT={PHQ9MFT}
          PHQ9MFTAddON={PHQ9MFTAddON}
          PHQ9MFT_HEADERS={PHQ9MFT_HEADERS}

          questionId={questionId}
          isPrint={isPrint}
          response={questionListById}
        />
      );
      break;

    case "21":
      componentToRender = (
        <QIDS_Adolescent


          QIDS_A17={QIDS_A17}
          QIDS_A17_SCORING={QIDS_A17_SCORING}
          QIDS_A17_HEADERS={QIDS_A17_HEADERS}


          questionId={questionId}
          isPrint={isPrint}
          response={questionListById}

        />
      );
      break;

    case "22":
      componentToRender = (
        <QIDS_SelfReport


          QIDS_SR={QIDS_SR}
          QIDS_SR_SCORING={QIDS_SR_SCORING}
          QIDS_HEADERS={QIDS_HEADERS}


          questionId={questionId}
          isPrint={isPrint}
          response={questionListById}

        />
      );
      break;


    case "23":
      componentToRender = (

        <SPSRSBNS_Scale

          SPSRSBNS_PS={SPSRSBNS_PS}
          SPSRSBNS_PS_ADDON={SPSRSBNS_PS_ADDON}
          SPSRSBNS_NS={SPSRSBNS_NS}
          SPSRSBNS_NS_ADDON={SPSRSBNS_NS_ADDON}

          questionId={questionId}
          isPrint={isPrint}
          response={questionListById}

        />
      );
      break;



    default:
      // Render a default component or show an error message
      componentToRender = <Default />;
  }

  return (
    <div>
      {/* <Header /> */}
      {componentToRender}
    </div>
  );
};

export default MyComponent;
