
import { getClientList, getSiteList, getStaffList, getDoumentTemplate } from "src/redux/actions";

import { store } from "src/redux/store/store";

export  function getSiteListData(isHardRefresh: boolean,prevData:[]) {
    if(isHardRefresh || !prevData || prevData?.length < 1){
        store.dispatch(getSiteList({}));
    }
}

export function getStaffListData(isHardRefresh: boolean, prevData: [], isCheckActiveStaff: boolean, switchStaffValue: any) {
    if(isHardRefresh || !prevData || prevData.length < 1) {
        store.dispatch(getStaffList(switchStaffValue !== undefined ? switchStaffValue : isCheckActiveStaff))
    }
}

export function getClientListData(isHardRefresh: boolean,prevData:[], isCheckActiveStaff: boolean, switchStaffValue: any) {
    if(isHardRefresh || !prevData || prevData?.length < 1) {
        store.dispatch(getClientList(switchStaffValue !== undefined ? switchStaffValue : isCheckActiveStaff))
    }
}

export function getTemplateListData(isHardRefresh: boolean, prevData:[]) {
    if (isHardRefresh || !prevData || Object.keys(prevData).length === 0) {
        store.dispatch(getDoumentTemplate({}));
    }
}