import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import Loader from "../../control-components/loader/loader";
import ApiHelper from "../../helper/api-helper";
import TextAreaKendoRct from "../../control-components/kendo-text-area/kendo-text-area";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import ErrorHelper from "../../helper/error-helper";
import moment from "moment";
import { Encrption } from '../encrption';
import ValidationHelper from "../../helper/validation-helper";
import { renderErrors } from "src/helper/error-message-helper";
import { API_ENDPOINTS } from "src/services/api-endpoints";

const BillingComments = ({ onClose, CommentSelectedId,documentId, fetchBillingComments }) => {
  const [loading, setLoading] = useState(false);
  const [settingError, setSettingError] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [fields, setFields] = useState({
    comment:""
  });

  useEffect(() => {
    if (CommentSelectedId && CommentSelectedId!=null) {
        getBillingCommentById();
    }
  }, []);

  const handleTextChange = (e) => {
    const name = e.target.name;
    const value = e.value;
    setFields({
      ...fields,
      [name]: value,
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!fields.comment) {
      formIsValid = false;
      errors["comment"] = ErrorHelper.FIELD_BLANK;
    }
    setErrors(errors);
    return formIsValid;
  };
  const handleSubmit = () => {
    setSettingError(true);
    if (handleValidation()) {
      if (CommentSelectedId) {
        updateBillingComment()
      } else {
        saveBillingComment();
      }

    }
  };
  const saveBillingComment = async () => {
    setLoading(true);
    const data = {
        documentId: documentId,
      comment: fields?.comment,
    };
    await ApiHelper.postRequest(API_ENDPOINTS.INSERT_BILLING_COMMENT, data)
      .then((result) => {
        setLoading(false);
        NotificationManager.success("Comment added successfully");
        fetchBillingComments()

        onClose();
      })
      .catch((error) => {
        setLoading(false);
        renderErrors(error);
      });
  };

  const updateBillingComment = async () => {
    setLoading(true);
    const data = {
        documentId:documentId,
      id: CommentSelectedId,
      comment: fields.comment,
    };
    await ApiHelper.putRequest(API_ENDPOINTS.UPDATE_BILLING_COMMENT, data)
      .then((result) => {
        setLoading(false);
        NotificationManager.success("Comment updated successfully");
        fetchBillingComments()
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        renderErrors(error);
      });
  };

  const getBillingCommentById = () => {
    setLoading(true);
    try {
      ApiHelper.getRequest(
        API_ENDPOINTS.GET_BILLING_COMMENT_BY_ID + Encrption(CommentSelectedId)
      ).then((response) => {
        const data = response.resultData;
        setFields({
          comment:data?.comment
        });
        setLoading(false);
      });
    } catch (err) {
        setLoading(false);
     }
  };
  return (
    <Dialog
      onClose={onClose}
      title={CommentSelectedId ? "Edit Comment" : "Add Comment"} width={"45%"} height={"50"}
      className="small-dailog width_90 mediumHeight"
    >
      <div className="edit-client-popup row">
        <div className="col-md-12 col-lg-12 col-12 ">
          <TextAreaKendoRct
            name="comment"
            txtValue={fields.comment}
            onChange={handleTextChange}
            placeholder="Comment"
            label="Comment"
            error={!fields.comment && errors.comment}
            required={settingError}
          />
        </div>
      </div>

      {loading == true && <Loader />}
      <div className="preview-btn-bottom">
      <div className="border-bottom-line"></div>
      <div className="d-flex my-3 px-3">
        <div>
          <button
            onClick={handleSubmit}
            className="btn blue-primary text-white  mx-3"
          >
            {CommentSelectedId ? "Update" : "Add"}
          </button>
        </div>
        <div>
          <button
            className="btn grey-secondary text-white"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>

    </Dialog>
  );
};

export default BillingComments;
