import React, { ComponentState, useEffect, useState } from "react";
import Loader from "../../../control-components/loader/loader";
import InputKendoRctV1 from "src/control-components/input/inputV1";
import { useNavigate } from "react-router";
import { Error } from "@progress/kendo-react-labels";
import ErrorHelper from "../../../helper/error-helper";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useDispatch, useSelector } from "react-redux";
import { getStaffList, insertClinician, saveInsertClinicianResponse } from "src/redux/actions";
import { filterBy } from "@progress/kendo-data-query";
import { clinicServices } from "src/services/clinicService";
import { GenericResponse } from "src/dataModels/authSetModels";
import { DeaModel } from "src/dataModels/clinicResponseModels";
import { Dialog } from "@progress/kendo-react-dialogs";
import DropDownKendoRct from "src/control-components/drop-down/drop-down";
import { NotificationManager } from "react-notifications";
import apiHelper from "src/helper/api-helper";
import { renderErrors } from 'src/helper/error-message-helper';
import { Switch } from "@progress/kendo-react-inputs";
import { API_ENDPOINTS } from "src/services/api-endpoints";

const AddNewClinician = ({ onClose, selectedRefId }) => {
    const navigate = useNavigate();
    const [settingError, setSettingError] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [filter, setFilter] = useState<any>();
    const [isNPiNeeded, setIsNpiNeeded] = useState(false);
    const [isDeaRequired, setIsDeaRequired] = useState(false);
    const [isApiCalled, setInitialApiCalled] = useState(false);
    const [primaryRoleData, setRoleData] = useState([])
    const [secondaryRoleData, setSecondaryRoleData] = useState([])
    const [doseSpotStaffList, setDoseSpotStaffList] = useState([])
    const emptyDea: DeaModel = { deaNumber: "", state: "" }
    const [dea, setDea] = useState<DeaModel[]>([emptyDea])
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const state: ComponentState = useSelector(states => {
        return states;
    });
    const [isReportingClinician, setReportingClinician] = useState(false);
    const { staffsList } = state['DocumentReducer'];
    const { insertClinicianResponse } = state['ClinicsReducer'];
    const reportingClinicianId = 2;
    let [fields, setFields] = useState({
        staff: {} as any,
        primaryRole: 0,
        secondaryRole: 0,
        fax: "",
        npi: "",
        useClinicAddress: false
    });

    useEffect(() => {
        if (!isApiCalled) {
            getRole()
            setInitialApiCalled(true)
            getDosespotStaffList();
        }
    }, [isApiCalled]);

    useEffect(() => {
        if (insertClinicianResponse && insertClinicianResponse != null) {
            dispatch(saveInsertClinicianResponse())
            navigate(-1)
        }
    }, [insertClinicianResponse]);
    const getRole = async () => {
        const serviceRoleList: GenericResponse = await clinicServices.getPrescriptionRoleList()
        if (serviceRoleList && serviceRoleList.resultData) {
            var pRole = serviceRoleList.resultData.filter(function (e) { return e.isPrimary == true });
            var secRole = serviceRoleList.resultData.filter(function (e) { return e.isPrimary == false });
            setRoleData(pRole)
            setSecondaryRoleData(secRole);
        }
    }
    useEffect(() => {
        if (dea != null) {

        }
    }, [dea]);
    const handleChange = (e) => {
        errors.role = null;
        errors.staff = null;
        const name = e.target.name;
        let value = e.target.value;
        if (name === 'primaryRole') {
            value = e.target.value?.id;
            if (value === 1) {
                setIsNpiNeeded(true);
                setIsDeaRequired(true);
            }
            else {
                setIsDeaRequired(false);
                setIsNpiNeeded(false)
            }

        }
        setFields({
            ...fields,
            [name]: value,
        });
    };
    const handleSecondaryRoleChange = (e) => {
        const name = e.target.name;
        let value = e.target.value?.id;
        setFields({
            ...fields,
            [name]: value,
        });
    };
    function onFilterChange(event, listName) {
        setFilter({ ...filter, [listName]: event.filter });
    }
    const handleSubmit = (event) => {
        if (handleValidation()) {
            setLoading(true);
            if (selectedRefId) {
            } else {
                const roleArray: number[] = []
                roleArray.push(fields?.primaryRole);
                if (fields?.secondaryRole > 0)
                    roleArray.push(fields?.secondaryRole)
                if (isReportingClinician)
                    roleArray.push(reportingClinicianId)
                let requestObj: any = {
                    staffId: fields.staff.id,
                    primaryFax: fields.fax,
                    clinicianRoleType: roleArray,
                    npiNumber: fields.npi,
                    useClinicAddress: fields.useClinicAddress
                }
                if (isNPiNeeded) {
                    requestObj = { ...requestObj, npiNumber: fields.npi, }
                }
                requestObj = { ...requestObj, deaNumbers: dea }
                apiHelper.postRequest(`${API_ENDPOINTS.SAVE_CLINICIAN}`, requestObj).then((result) => {
                    if (result.statusCode == 200) {
                        setLoading(false);
                        onClose();
                        NotificationManager.success("eRx clinician added successfully!");
                    }
                }).catch((err) => {
                    setLoading(false);
                    renderErrors(err);
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
    };
    const getDosespotStaffList = () => {
        apiHelper.getRequest(`${API_ENDPOINTS.GET_DOSESPOT_STAFF_LIST}`).then((result) => {
            if (result.statusCode == 200) {
                setLoading(false);
                setDoseSpotStaffList(result?.resultData);
            }
        }).catch((err) => {
            setLoading(false);
            renderErrors(err);
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        var pattern = new RegExp(/^[0-9\b]+$/);

        if (!fields.staff || !fields.staff.id) {
            formIsValid = false;
            errors["staff"] = ErrorHelper.FIELD_BLANK;
        }
        if (fields.primaryRole === 0) {
            formIsValid = false;
            errors["role"] = ErrorHelper.FIELD_BLANK;
        }
        if (isDeaRequired && (!dea || dea.length === 0 || (dea.length == 1 && dea[0].deaNumber.length < 1))) {
            formIsValid = false;
            errors["dea"] = ErrorHelper.FIELD_BLANK;
        }
        dea.forEach(element => {
            if (element.deaNumber.length > 0 && element.state.length < 1) {
                formIsValid = false;
            }
        });
        for (var deaData in dea) { }
        if (!fields.fax || fields.fax.trim().length === 0) {
            formIsValid = false;
            errors["fax"] = ErrorHelper.FIELD_BLANK;
        }
        if (isNPiNeeded && (!fields.npi || fields.npi.trim().length === 0)) {
            formIsValid = false;
            errors["npi"] = ErrorHelper.FIELD_BLANK;
        }
        setErrors(errors);
        return formIsValid;
    };
    const handleSwitch = (e: any) => {
        var value = e.target.value;
        var name = e.target.name;
        setFields({
            ...fields,
            [name]: value,
        });
    };
    const handleReportingClinicianSwitch = (e: any) => {
        setReportingClinician(e.target.value);
    };

    return (
        <Dialog
            onClose={onClose}
            title={"Add eRx Staff"}
            className="dialog-modal xl-modal width_90" height={"65%"} width={"60%"}
        >
            <div className=" edit-Service-popup">
                    {loading === true && <Loader />}
                    <div className="mx-3">
                        <div className="row">
                            <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                                <DropDownList
                                    filterable={true}
                                    data={filterBy(doseSpotStaffList, filter ? filter.doseSpotStaffList : [])}
                                    onFilterChange={(event) => {
                                        onFilterChange(event, "doseSpotStaffList");
                                    }}
                                    textField="name"
                                    label="Staff"
                                    name="staff"
                                    value={fields.staff}
                                    onChange={handleChange}
                                />
                                {(errors && errors.staff && errors.staff != null) && <Error>{errors.staff}</Error>}
                            </div>
                            <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                                <DropDownList
                                    data={primaryRoleData}
                                    textField="name"
                                    label="Primary Role"
                                    name="primaryRole"
                                    disabled={false}
                                    onChange={handleChange}
                                />
                                {(errors && errors.role && errors.role != null) && <Error>{errors.role}</Error>}
                            </div>
                            <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                                <DropDownList
                                    data={secondaryRoleData}
                                    textField="name"
                                    label="Secondary Role"
                                    name="secondaryRole"
                                    disabled={false}
                                    // value={fields.role}
                                    onChange={handleSecondaryRoleChange}
                                />
                            </div>
                            <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop" style={{ display: "flex", alignItems: "center", paddingLeft: "10px", marginTop: "20px" }}>
                                <Switch
                                    onChange={handleReportingClinicianSwitch}
                                    onLabel={"on"}
                                    offLabel={"off"}
                                    name="reportingClinician"
                                    value={isReportingClinician}
                                    checked={isReportingClinician}
                                />
                                <span className="switch-title-text ml-2  fw-500 f-16 ">
                                    Reporting Clinician
                                </span>
                            </div>
                            <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                                <InputKendoRctV1
                                    value={fields.fax}
                                    onChange={handleChange}
                                    name="fax"
                                    label="Fax"
                                    error={fields.fax == "" && errors.fax}
                                    validityStyles={settingError}
                                    required={true}
                                    placeholder="Fax"
                                    maxLength={10}
                                />
                            </div>
                            {isNPiNeeded && <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                                <InputKendoRctV1
                                    validityStyles={settingError}
                                    value={fields.npi}
                                    onChange={handleChange}
                                    name="npi"
                                    label="NPI"
                                    error={fields.npi === "" && errors.npi}
                                    required={true}
                                    placeholder="NPI"
                                />
                            </div>
                            }
                            <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop" style={{ display: "flex", alignItems: "center", paddingLeft: "10px", marginTop: "20px" }}>
                                <Switch
                                    onChange={handleSwitch}
                                    onLabel={"on"}
                                    offLabel={"off"}
                                    name="useClinicAddress"
                                    value={fields.useClinicAddress}
                                    checked={fields.useClinicAddress}
                                />
                                <span className="switch-title-text ml-2  fw-500 f-16 ">
                                    Use Clinic Address
                                </span>
                            </div>
                        </div>
                        <div>
                            <div className="f-24">DEA details </div>
                            {
                                dea.map((currentDea, indexDea) => {
                                    return <div className="row">

                                        <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                                            <InputKendoRctV1
                                                value={currentDea.deaNumber}
                                                onChange={(handleTextChange) => {
                                                    errors.dea = null;
                                                    setDea(list => list.map((item: DeaModel, i) => i === indexDea ? {
                                                        ...item,
                                                        deaNumber: '' + handleTextChange.value
                                                    }
                                                        : item
                                                    ));
                                                }
                                                }
                                                label=""
                                                validityStyles={settingError}
                                                required={true}
                                                placeholder="DEA Number"
                                            /></div>

                                        <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                                            <InputKendoRctV1
                                                value={currentDea.state}
                                                onChange={(handleTextChange) => {
                                                    setDea(list => list.map((item, i) =>
                                                        i === indexDea
                                                            ? {
                                                                ...item,
                                                                state: '' + ((/^[a-zA-Z\s]*$/.test(handleTextChange.value)) ? handleTextChange.value : "")
                                                            }
                                                            : item
                                                    ));
                                                }}
                                                name="dea"
                                                // error={fields.dea == "" && errors.dea}
                                                validityStyles={settingError}
                                                required={true}
                                                placeholder="State"
                                                maxLength={2}
                                            />
                                            {(currentDea.deaNumber && currentDea.deaNumber.length > 0 && currentDea.state.length < 1) && <Error>{'* Required'}</Error>}
                                        </div>
                                        {(dea && dea.length > 1)
                                            && <div className="mb-2 col-lg-3 col-md-6 col-12 list-staff-drop">
                                                <i onClick={
                                                    () => {
                                                        const updatedDeaList = dea.filter((item, i) => i !== indexDea);
                                                        setDea(updatedDeaList)
                                                    }
                                                }
                                                    className="fa-solid fa-xmark cross-red"></i>


                                            </div>
                                        }
                                    </div>
                                })
                            }
                            {errors.dea && <Error>{'*Dea can not be empty'}</Error>}

                            <button
                                onClick={() => {
                                    setDea(oldArray => [...oldArray, emptyDea]);
                                }}
                                className="AddMore"><i className="fa-solid fa-plus"></i> Add another DEA</button>

                        </div>
                    </div>
                    {loading == true && <Loader />}
                </div>
                <div className="preview-btn-bottom">
                    
                    <div className="border-bottom-line"></div>
                    <div className="d-flex mt-4">
                        <div className="right-sde">
                            <button
                                className="btn blue-primary text-white "
                                onClick={handleSubmit}
                            >
                                {selectedRefId ? "Update" : "Submit"}
                            </button>
                        </div>
                        <div className="right-sde-grey">
                            <button
                                className="btn grey-secondary text-white  mx-2"
                                onClick={() => { onClose(); }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
        </Dialog>
    )
}
export default AddNewClinician;