import React, { useEffect, useState } from "react";
import "../../custom-css/custom-style.css";
import logoNotenetic from "../../assets/images/logo_notenetic.png";
import { filterBy } from "@progress/kendo-data-query";
import { Icon } from "@progress/kendo-react-common";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import moment from "moment";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import dummyImg from "../../../src/assets/images/dummy-img.png";
// import startClock from "../../../src/assets/images/start-clock.svg";
import clockPlay from "../../../src/assets/images/clock-play2.svg";
// import stopClock from "../../../src/assets/images/stop-clock.svg";
import clockStop from "../../../src/assets/images/clock-stop2.svg";
import { renderErrors } from "src/helper/error-message-helper";
import {
  SELECTED_STAFF_ID,
  STAFF_LOGIN_DETAIL,
  STAFF_ONLINE_STATUS,
} from "../../redux/actions/types";
import ApiHelper from "../../helper/api-helper";
import {
  default as APP_ROUTES,
  default as AppRoutes,
} from "../../helper/app-routes"; 
import DEVELOPMENT_CONFIG from "../../helper/config";
import { ClientService } from "../../services/clientService";
import { CommonService } from "../../services/commonService";
import GlobalSearch from "../global-search/global-search";
import ChangePassword from "./changePassword";
import Badge from '@mui/material/Badge';
import { getClockStatus, insertNewTImeClock, saveEncryptedRedirectDocId } from "src/redux/actions";
import { loaderAction } from "src/redux/actions";
import { customAxios } from "src/services/useExPressApi";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import HeadwayWidget from 'src/control-components/headway/headwayWidget'; // Replace 'path/to/HeadwayWidget' with the actual path to the HeadwayWidget component
import ClockInOutConfirmModal from "./clock-in-out-confirm";
import mailIcon from "../../assets/images/email.png";

const RefreshToken = async () => {
  var refreshtoken = localStorage.getItem("refeshtoken");
  var data = {
    refreshToken: refreshtoken,
  };
  ApiHelper.postRequest(API_ENDPOINTS.RERESH_TOKEN, data)
    .then((result) => {
      localStorage.setItem(DEVELOPMENT_CONFIG.TOKEN, result.resultData.token);
      localStorage.setItem(
        DEVELOPMENT_CONFIG.REFRESH_TOKEN,
        result.resultData.refreshToken
      );
      localStorage.setItem(DEVELOPMENT_CONFIG.LOGIN_TIME, moment().format());
      localStorage.setItem(
        DEVELOPMENT_CONFIG.TOKEN_EXPIRETIME,
        moment().add(result.resultData.tokenExpireIn, "seconds").format()
      );
      localStorage.setItem(
        DEVELOPMENT_CONFIG.REFRESHTOKEN_EXPIRETIME,
        moment().add(result.resultData.refreshTokenExpireIn, "seconds").format()
      );
    })
    .catch((error) => {
      renderErrors(error);
      localStorage.clear();
      window.location.href = "/login";
    });
};

function Header() {

  const state = useSelector(states => {
    return states;

  });
  const [readMessageCount, setReadMessageCount] = useState()
  const { unreadMessageCount } = state['ClientReducer'] 
  const { timeclockStatus } = state['DashboardReducer']
  const staffId = useSelector((state) => state.loggedIn?.staffId);
  useEffect(() => {
    setReadMessageCount(unreadMessageCount)
  }, [unreadMessageCount])

  useEffect(() => {
    if (!timeclockStatus || timeclockStatus.staffId != staffId) {
      dispatch(getClockStatus())
    } else {
      setClockIn(timeclockStatus.isClockIn)
    }
    dispatch(loaderAction(false))
  }, [timeclockStatus])

  const navigate = useNavigate();
  const location = useLocation();
  const anchor = React.useRef(null);
  const [confirmClockInOut, setConfirmClockInOut] = useState(false);
  const [timer, setTimer] = useState("30:00");
  const [counterpopup, setCounterPopup] = React.useState(false);
  const [isChangePassword, setChangePassword] = React.useState(false);
  const [loginData, setLoginData] = React.useState("");
  const dispatch = useDispatch();
  const [clientData, setClientData] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const selectedDrawer = useSelector((state) => state.selectedDrawer);
  const isGlobalSearchReducer = useSelector(
    (state) => state.isGlobalSearchReducer
  );
  const [isSelectedDDL, setIsSelectedDDL] = React.useState(false);
  const newPath = location.pathname.toLowerCase();
  const [loading, setLoading] = useState(false);
  const [isClockIn, setClockIn] = useState(false);
  const sessionId = useSelector((state) => state.loggedIn.sessionId);
  // const [isAccepted, setIsAccepted] = useState(false)
  const [inboxUnReadCount, setInboxUnReadCount] = useState()
  let d = newPath.split("/");
  let primaryPath = d[1];

  useEffect(() => {
    if (
      location.pathname == APP_ROUTES.GET_CLIENT ||
      primaryPath !== "client"
    ) {
      setSearchValue("");
    }
    const interval = setInterval(() => {
      GetDateDiff(new Date(localStorage.accessTokenExpireTime), new Date());
    }, 10000);
    return () => clearInterval(interval);
  }, [location.pathname]);

  useEffect(() => {
    getLoginDetail();
    getClientsList();
    getStaffOnlineStatus();

  }, []);

  useEffect(() => {
    setInterval(getStaffOnlineStatus, 70000);
  }, []);


  const { redirectedDocID } = state['LoggedInReducer'];


  useEffect(() => {

    if (redirectedDocID > 0) {
      navigate(APP_ROUTES.DOCUMENT_VIEW, {
        state: {
          id: redirectedDocID,
          backRoute: APP_ROUTES.DOCUMENT_LIST,
          isActiveCheck: false,
          // showClinicLogo:showClinicLogo
        },
      });
      dispatch(saveEncryptedRedirectDocId(0))
    }
  }, [redirectedDocID])


  // -------------------------TypeScript----------------------
  // useEffect(() => {
  //   if (!isAccepted) {
  //     dispatch(getUnreadCount())
  //     setIsAccepted(true)
  //   }
  // }, [isAccepted])

  async function getUnreadCount() {
    try {
      const result = await ApiHelper.getRequest(API_ENDPOINTS.GET_UNREAD_COUNT);
      setInboxUnReadCount(result.resultData.unreadCount);
    } catch (err) {
    }
  }
  useEffect(() => {
    getUnreadCount()
  }, [])

  const getClientsList = async () => {
    await ClientService.getClientsDDL(true)
      .then((result) => {
        let clientListing = result.resultData;
        setClientData(clientListing);
      })
      .catch((error) => {
        renderErrors(error.message);
      });
  };

  let isFetching = false;

  const getStaffOnlineStatus = async () => {
    if (isFetching) return;
    isFetching = true;
  
    try {
      const result = await CommonService.syncStaffOnlineStatus();
      const data = result.resultData;
      dispatch({
        type: STAFF_ONLINE_STATUS,
        payload: data,
      });
    } catch (error) {
      renderErrors(error.message);
    } finally {
      setTimeout(() => {
        isFetching = false;
        getStaffOnlineStatus();
      }, 2 * 60 * 1000);
    }
  };

  const getDeadTime = (seconds) => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + seconds);
    return deadline;
  };
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return { minutes, seconds };
  };
  const startTimer = (e) => {
    let { minutes, seconds } = getTimeRemaining(e);
    if (minutes >= 0 && seconds >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      Logout();
    }
  };
  const clearTimer = (e) => {
    setTimer("30:00");
    if (anchor.current) clearInterval(anchor.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    anchor.current = id;
  };
  const [anchorAlign, setAnchorAlign] = React.useState({
    horizontal: "center",
    vertical: "bottom",
  });
  const [popupAlign, setPopupAlign] = React.useState({
    horizontal: "center",
    vertical: "top",
  });
  const [show, setProfileDropdown] = React.useState(false);

  const logout = () => {
    // Clear user session data
    sessionStorage.clear();
    localStorage.setItem("logout", Date.now().toString());

    // Clear local storage data
    localStorage.removeItem("logout");
    localStorage.clear();
    window.location.href = "/login";
  };

  useEffect(() => {
    const onStorageChange = (event) => {
      if (event.key === "logout") {
        // Call the logout function if the 'logout' key is set in the local storage
        logout();
      }
    };

    window.addEventListener("storage", onStorageChange);
    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  }, []);




  const handleLogout = async () => {
    await CommonService.logOutUser(sessionId)
      .then((result) => {
        setLoading(false);
        logout();
      })
      .catch((error) => {
        setLoading(false);
        renderErrors("Something went wrong.");
      });
  };
  const Logout = () => {
    if (sessionId) {
      handleLogout();
    } else {
      logout();
    }
  };
  function profileDropdownEnter() {
    setProfileDropdown(true);
  }
  function profileDropdownOver() {
    setProfileDropdown(false);
  }
  function showCounterPopup() {
    setCounterPopup(true);
  }

  function hideCounterPopup(dontSendMessage) {
    setCounterPopup(false);
    clearTimer(getDeadTime(18000));

    // If the counter popup hide here then hide it on all another tabs as well
    if (!dontSendMessage) {
      idleTimer.message({ resetSession: true }, false);
    }
  }

  function resetSession() {
    hideCounterPopup(false);
  }

  function GetDateDiff(startDate, endDate) {
    var mins = parseInt(
      (Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60)) % 60
    );
    if (mins < 15) {
      RefreshToken();
    }
  }
  const getLoginDetail = async() => {
    const sessionResp = await customAxios.get(`${API_ENDPOINTS.GET_LOGIN_STAFF}`);
    if(sessionResp && sessionResp.resultData){
      let loginData = sessionResp.resultData;
        setLoginData(loginData);
        dispatch({
          type: STAFF_LOGIN_DETAIL,
          payload: loginData,
        });
  }
  }

  const handleOnIdle = (event) => {
    showCounterPopup(true);
    clearTimer(getDeadTime(1800));
  };

  const handleOnActive = (event) => { };

  const handleOnAction = (event) => {
    // Send remaining time to active the another tab when current tab is active
    idleTimer.message({ remainingTime: idleTimer.getRemainingTime() }, false);
  };

  const handleTimerMessages = (message) => {
    // Recieved when user click on "I'm here"
    if (message.resetSession) {
      hideCounterPopup(true);
    }
    // Recieved to reset the timer if user active on another tab
    else if (
      message.remainingTime &&
      idleTimer.getRemainingTime() < message.remainingTime
    ) {
      idleTimer.reset();
    }
  };

  const idleTimer = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    crossTab: true,
    onMessage: handleTimerMessages,
    debounce: 500,
  });

  const profileHandler = () => {
    dispatch({
      type: SELECTED_STAFF_ID,
      payload: loginData.id,
    });
    navigate(AppRoutes.STAFF_PROFILE);
  };

  const changePassword = () => {
    setChangePassword(true);
  };

  const handleClosePassword = () => {
    setChangePassword(false);
  };

  // const getClientDetail = async () => {
  //   await ClientService.getClientDetail(selectedClientId)
  //     .then((result) => {
  //       let clientDetail = result.resultData;

  //       dispatch({
  //         type: GET_CLIENT_DETAILS,
  //         payload: result.resultData,
  //       });

  //     })
  //     .catch((error) => {
  //       renderErrors(error.message);
  //     });
  // };

  const onSearchChange = (event) => {
    if (event.value) {
      setSearchValue(event.value);
      let eventType = event.syntheticEvent.type;
      if (eventType == "click" || eventType == "keydown") {
        setIsSelectedDDL(true);
        if (primaryPath == "client") {
          if (selectedDrawer.route) {
            // navigate(APP_ROUTES.CLIENT_DASHBOARD);
          } else {
            navigate(APP_ROUTES.CLIENT_DASHBOARD);
          }
        } else {
          navigate(APP_ROUTES.CLIENT_DASHBOARD);
        }
        if (newPath === APP_ROUTES.GET_CLIENT) {
          navigate(APP_ROUTES.CLIENT_DASHBOARD);
        }
      } else {
        setIsSelectedDDL(false);
      }
    } else {
      setSearchValue("");
    }
  };

  const handleClockInOut = () => {
    dispatch(insertNewTImeClock())
    dispatch(loaderAction(true))
    setConfirmClockInOut(false)
  }

  const handleClockInOutConfirm = () => {
    setConfirmClockInOut(!confirmClockInOut)
  }

  return (
    <div className="top-header">
      <div className="top-header-menu">
        <div className="topbar-items nav-left d-flex align-items-center">
          <a href="" className="logo-link text-blue text-decoration-none">
            <h3 className="logo-link notenetic-text mb-0">
              <img src={logoNotenetic} />
            </h3>
          </a>
          <div className="position-relative ml-3  searchicons">
            <form>
              <GlobalSearch
                data={filterBy(clientData, [
                  {
                    field: "clientName",
                    operator: "contains",
                    value: searchValue,
                  },
                ])}
                textField="clientName"
                value={searchValue}
                onChange={onSearchChange}
                placeholder="Find Client..."
                dataItemKey="clientId"
                isSelectedDDL={isSelectedDDL}
                isGlobalSearchReducer={isGlobalSearchReducer}
                setSearchValue={setSearchValue}
                setIsSelectedDDL={setIsSelectedDDL}
              />

              <Icon name="search" className="search-icon" />
            </form>
          </div>
        </div>
        <div className="topbar-items nav-right">
          <ul className="list-unstyled d-flex align-items-center mb-0">
            {/* <li className="nav-item dropdown more-btn-drop d-block d-lg-none">
              <a
                className="nav-link dropdown-toggle mb-0"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  className="k-icon k-i-more-vertical"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="More"
                ></i>
              </a>
              <div
                className="dropdown-menu drop-downlist"
                aria-labelledby="dropdownMenuButton"
              >
                <div className="d-flex flex-wrap">
                  <ul className="col-lg-6 list-unstyled col-12">
                    <li className="mb-3">
                      <a className="dropdown-item active" href="#">
                        <div className="d-flex align-items-start">
                          <p className="mb-0">Lorem Ipsum</p>
                        </div>
                      </a>
                    </li>
                    <li className="mb-3">
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-start">
                          <p className="mb-0">Lorem Ipsum</p>
                        </div>
                      </a>
                    </li>
                    <li className="mb-3">
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-start">
                          <p className="mb-0">Lorem Ipsum</p>
                        </div>
                      </a>
                    </li>
                    <li className="mb-3">
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-start">
                          <p className="mb-0">Lorem Ipsum</p>
                        </div>
                      </a>
                    </li>
                    <li className="mb-3">
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-start">
                          <p className="mb-0">Lorem Ipsum</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <ul className="col-lg-6 list-unstyled col-12">
                    <li className="mb-3">
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-start">
                          <p className="mb-0">Lorem Ipsum</p>
                        </div>
                      </a>
                    </li>
                    <li className="mb-3">
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-start">
                          <p className="mb-0">Lorem Ipsum</p>
                        </div>
                      </a>
                    </li>
                    <li className="mb-3">
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-start">
                          <p className="mb-0">Lorem Ipsum</p>
                        </div>
                      </a>
                    </li>
                    <li className="mb-3">
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-start">
                          <p className="mb-0">Lorem Ipsum</p>
                        </div>
                      </a>
                    </li>
                    <li className="mb-3">
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-start">
                          <p className="mb-0">Lorem Ipsum</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li> */}

            {/* <li className="icon-size mx-3">
              <a className="text-darkgrey">
                <i className="fa-clock text-theme fa fa-clock-o"></i>
              </a>
            </li> */}
            
            <li className="icon-size mr-1 mt-2">
              <HeadwayWidget></HeadwayWidget>
            </li>

            <li className="icon-size ml-3 mr-1">
              <div className="text-darkgrey " style={{ cursor: "pointer" }} onClick={handleClockInOutConfirm} >
                {/* <i className="fa-clock text-theme fa fa-clock-o"></i> */}
                {/* <svg width="30" height="30" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#ea4d36" />
                  <path d="M21.6147 15.1979H16.8022V10.3854C16.8022 10.1727 16.7177 9.96866 16.5673 9.81824C16.4169 9.66782 16.2129 9.58331 16.0002 9.58331C15.7874 9.58331 15.5834 9.66782 15.433 9.81824C15.2826 9.96866 15.1981 10.1727 15.1981 10.3854V15.1979H10.3856C10.1729 15.1979 9.96884 15.2824 9.81842 15.4328C9.668 15.5832 9.5835 15.7873 9.5835 16C9.5835 16.2127 9.668 16.4167 9.81842 16.5671C9.96884 16.7176 10.1729 16.8021 10.3856 16.8021H15.1981V21.6146C15.1981 21.8273 15.2826 22.0313 15.433 22.1817C15.5834 22.3321 15.7874 22.4166 16.0002 22.4166C16.2129 22.4166 16.4169 22.3321 16.5673 22.1817C16.7177 22.0313 16.8022 21.8273 16.8022 21.6146V16.8021H21.6147C21.8275 16.8021 22.0315 16.7176 22.1819 16.5671C22.3323 16.4167 22.4168 16.2127 22.4168 16C22.4168 15.7873 22.3323 15.5832 22.1819 15.4328C22.0315 15.2824 21.8275 15.1979 21.6147 15.1979Z" fill="white" />
                  <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#ea4d36.fa-chevron-right" />
                </svg> */}
                {isClockIn ? <img src={clockStop} className="start-timer-icon" alt="clock-stop2" title="Click to Clock Out" /> : <img src={clockPlay} alt="clock-play2" title="Click to Clock In" className="start-timer-icon" />}

              </div>
            </li>


            <li className="icon-size mx-3">
              <div className="text-darkgrey "  style={{ cursor: "pointer" }} onClick={() => navigate(AppRoutes.DOCUMENT_ADD)} >
                {/* <i
                  className="fa-solid text-theme fa-notes-medical"
                  onClick={() => navigate(AppRoutes.DOCUMENT_ADD)}
                ></i> */}
                <svg width="30" height="30" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#ea4d36" />
                  <path d="M21.6147 15.1979H16.8022V10.3854C16.8022 10.1727 16.7177 9.96866 16.5673 9.81824C16.4169 9.66782 16.2129 9.58331 16.0002 9.58331C15.7874 9.58331 15.5834 9.66782 15.433 9.81824C15.2826 9.96866 15.1981 10.1727 15.1981 10.3854V15.1979H10.3856C10.1729 15.1979 9.96884 15.2824 9.81842 15.4328C9.668 15.5832 9.5835 15.7873 9.5835 16C9.5835 16.2127 9.668 16.4167 9.81842 16.5671C9.96884 16.7176 10.1729 16.8021 10.3856 16.8021H15.1981V21.6146C15.1981 21.8273 15.2826 22.0313 15.433 22.1817C15.5834 22.3321 15.7874 22.4166 16.0002 22.4166C16.2129 22.4166 16.4169 22.3321 16.5673 22.1817C16.7177 22.0313 16.8022 21.8273 16.8022 21.6146V16.8021H21.6147C21.8275 16.8021 22.0315 16.7176 22.1819 16.5671C22.3323 16.4167 22.4168 16.2127 22.4168 16C22.4168 15.7873 22.3323 15.5832 22.1819 15.4328C22.0315 15.2824 21.8275 15.1979 21.6147 15.1979Z" fill="white" />
                  <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#ea4d36.fa-chevron-right" />
                </svg>

              </div>
            </li>
            <li className="icon-size mr-4">
              <div className="text-darkgrey " style={{ cursor: "pointer" }} onClick={() => navigate(AppRoutes.MESSAGE)} >
                <Badge badgeContent={readMessageCount >= 0 ? readMessageCount : inboxUnReadCount} color="primary"   >
                  {/* <MailIcon color="primary" /> */}
                  <img src={mailIcon} sizes="24" /> 
                </Badge>
              </div>
            </li>

            {/* <li className="mx-3 dropdown dropright icon-size">
              <a
                className="notification-drop dropdown-toggle toogle-hide"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa-solid fa-bell">
                  <span className="top-circle-show">1</span>
                </i>
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <a className="dropdown-item" href="#">
                  Action
                </a>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </div>
            </li> */}
            <li className="dropdown dropright drop-user-toogle">
              <a
                className="notification-drop dropdown-toggle"
                type="button"
                id="dropdownMenuButtonn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src={
                    loginData.profileImageId
                      ? loginData.profileImageId
                      : dummyImg
                  }
                  alt=""
                  className="user-top"
                />
              </a>
              <div
                className="dropdown-menu py-2"
                aria-labelledby="dropdownMenuButton"
              >
                <h6 className="user-name ">
                  <a className="f-15 ml-3">
                    {loginData.firstName} {loginData.lastName}
                  </a>
                </h6>
                <hr className="my-0" />
                <ul className="list-unstyled list-prodile px-3 mb-2 mt-2">
                  <li>
                    <a
                      className="text-decoration-none f-14 text-theme fw-500"
                      href="#"
                      onClick={profileHandler}
                    >
                      My Profile
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-decoration-none f-14 text-theme fw-500"
                      href="#"
                      onClick={changePassword}
                    >
                      Change Password
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-decoration-none f-14 my-2 text-theme fw-500"
                      href="#"
                      onClick={Logout}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>

      {counterpopup && (
        <Dialog className="xs-modal width-given" title={"Session Expire Time"} onClose={resetSession}>
          <b>Are you still there?</b> If not, your session will close in:{" "}
          <b>{timer}</b> due to inactivity.
          {/* Are you still there? out in <b>{timer}</b> minutes. You want to stay? */}
          <DialogActionsBar>
            <button
              className="btn btn-sm blue-primary text-white"
              onClick={resetSession}
            >
              {" "}
              I'm here
            </button>
            <button
              className="btn btn-sm grey-secondary text-white"
              onClick={Logout}
            >
              Log out
            </button>
          </DialogActionsBar>
        </Dialog>
      )}

      {isChangePassword && <ChangePassword onClose={handleClosePassword} />}

      {confirmClockInOut && <ClockInOutConfirmModal
        title={isClockIn ? "Clocking Out Confirmation" : "Clocking In Confirmation"}
        message={isClockIn ? "Time to wrap up? Let's clock out!" : "Ready to start your shift? Let's clock in!"}
        onClose={handleClockInOutConfirm}
        onConfirm={handleClockInOut}
      />}
    </div>
  );
}
export default Header;
