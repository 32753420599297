import { TimePicker } from "@progress/kendo-react-dateinputs";
import DatePickerKendoRct from "../../../control-components/date-picker/date-picker";

import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { Checkbox, Input, RadioGroup, TextArea } from "@progress/kendo-react-inputs";
import { Hint } from "@progress/kendo-react-labels";
import React, { forwardRef } from 'react';
import { templateControls } from './document-template-utility';

export interface PreviewDocumentProps {
    templateName?: string,
    controlList: any[],
    documentFieldsMappings?: any[],
    oldDocumentFieldsMappings?: any[],
    changedDocumentFieldKeyNames?: any[],
    disabled?: boolean,
    isViewMode?: boolean,
    isPrintMode?: boolean,
    ref?: any
}

export const PreviewDocumentTemplate = (props: PreviewDocumentProps) => {
    const { templateName, controlList, documentFieldsMappings, oldDocumentFieldsMappings,
        changedDocumentFieldKeyNames, disabled, isViewMode, isPrintMode, ref
    } = props
    const editor = React.createRef();

    const RawHTML = ({ children, className = "" }) =>
        <div className={className}
            dangerouslySetInnerHTML={{ __html: children.replace(/<p>/, '<p style="margin: 0;">').replace(/\n/g, '<br />') }} />

    let columnCSS
    let renderControlList: any[] = []
    let lastControlList: any[] = [];
    let lastColumnCSS: any;
    let lastColumnNumber: any;
    for (let index = 0; index < controlList.length; index++) {
        const control = controlList[index]
        columnCSS = "1";
        // 3 column layout
        if (
            index + 2 < controlList.length && control.columnNumber == 1
            && controlList[index + 1].columnNumber == 2
            && controlList[index + 2].columnNumber == 3
            ||
            index + 1 < controlList.length && control.columnNumber == 2
            && controlList[index + 1].columnNumber == 3
            ||
            control.columnNumber == 3
        ) {
            columnCSS = "3"
        }

        // 2 column layout
        else if (
            index + 1 < controlList.length && control.columnNumber == 1
            && controlList[index + 1].columnNumber == 2
            ||
            control.columnNumber == 2
        ) {
            columnCSS = "2"
        }

        // Push column controls together with css
        if (lastColumnCSS && (columnCSS != lastColumnCSS || lastColumnCSS == "1" || lastColumnNumber == 3)) {
            renderControlList.push({
                columnCSS: "form-group mb-2",
                controlList: lastControlList
            })
            lastControlList = []
        }

        // Collect same column controls
        lastControlList.push(control)
        lastColumnCSS = columnCSS
        lastColumnNumber = control.columnNumber
    }

    // Push last column controls together with css
    renderControlList.push({
        columnCSS: "form-group mb-2",
        controlList: lastControlList
    })

    // To prevent truncation of text from right
    const rightMarginClass = isPrintMode ? " pdf-right-margin p-0 " : ""

    function getHtmlValueOf(id) {
        const value = getValueOf(id)
        if (isPrintMode) {
            return value ? <p className="printableContent">{value}</p> : <></>
        }
        return value ? <div className="content-text-view">{value}</div> : <></>
    }

    function getValueOf(id) {
        if (documentFieldsMappings) {
            const pair = documentFieldsMappings.find(x => x.keyName == id)
            if (pair) {
                return pair.keyValue
            }
        }
        return ""
    }

    function getOldValueOf(id) {
        if (oldDocumentFieldsMappings) {
            const pair = oldDocumentFieldsMappings.find(x => x.keyName == id)
            if (pair) {
                return pair.keyValue
            }
        }
        return ""
    }

    /* ============================= Render View ============================= */

    function renderHeaderableTitle(control?: any, isText?: boolean) {
        let text = isText ? control.text : control.title
        // text = control.isRequired ? `* ${text}` : text
        return <p className="printableHeader">{text}</p>
    }

    function renderHint(control) {
        if (control.hint && control.hint.length > 0) {
            const text = `(${control.hint})`
            return <p className='small'>{text}</p>
        }
        return <></>
    }

    function renderParagraph(control) {
        control.isHeader = true
        return <div className={columnCSS + rightMarginClass}>
            {renderHint(control)}
            <RawHTML>{control.textHtml}</RawHTML>
        </div>
    }

    function renderHeading3(control) {
        return <div className={rightMarginClass}>
            {renderHeaderableTitle(control, true)}
            {renderHint(control)}
        </div>
    }

    function renderMinMax(control) {
        let minMax = ""
        if (control.minInputChar > 0) {
            minMax = `Min: ${control.minInputChar} `
        }
        if (control.maxInputChar > 0) {
            minMax += `Max: ${control.maxInputChar}`
        }
        return minMax ? "" : <Hint direction={"end"}>{minMax}</Hint>
    }

    function renderOldValue(control: any, hasItemList?: boolean) {
        if (isPrintMode) return <></>
        if (!oldDocumentFieldsMappings) return <></>
        if (hasItemList) {
            const index = getOldValueOf(control.id)
            if (control.itemList && index > -1 && index < control.itemList.length) {
                return <p style={{ color: "grey" }}>{control.itemList[index]}</p>
            }
        }
        return <p style={{ color: "grey" }}>{getOldValueOf(control.id)}</p>
    }

    function renderTextBox(control) {
        if (isViewMode || isPrintMode) {
            return <div className={columnCSS + rightMarginClass}>
                {renderHeaderableTitle(control)}
                {renderHint(control)}
                {getHtmlValueOf(control.id)}
                {renderOldValue(control)}
                <br />
            </div>
        }
        return <span className={columnCSS}>
            <div className='mb-2' ><h6 className="fw-500">{renderHeaderableTitle(control)}</h6></div>
            {renderHint(control)}
            <Input
                disabled={disabled}
                value={getValueOf(control.id)}
            // placeholder={control.title}
            />
            {renderMinMax(control)}
        </span >
    }

    function renderTextKendoEditor(control) {
        if (isViewMode || isPrintMode) {
            return <div className={columnCSS + rightMarginClass}>
                <label className='mb-2' >{renderHeaderableTitle(control)}</label>
                {renderHint(control)}
                <RawHTML>{getValueOf(control.id)}</RawHTML>
                {oldDocumentFieldsMappings && <div style={{ color: "grey" }}>
                    <RawHTML>{getOldValueOf(control.id)}</RawHTML>
                </div>}
                <br />
            </div>
        }
        const { Bold, Italic, Underline, AlignLeft, AlignRight, AlignCenter, Indent, Outdent, OrderedList, UnorderedList, Undo, Redo, Link, Unlink, } = EditorTools;
        return (<span className={columnCSS}>
            <label className='mb-2' >{renderHeaderableTitle(control)}</label>
            {renderHint(control)}
            <Editor
                // disabled={disabled}
                // ref={editor}
                value={control?.textHtml}
                tools={[
                    [Bold, Italic, Underline],
                    [Undo, Redo],
                    [Link, Unlink],
                    [AlignLeft, AlignCenter, AlignRight],
                    [OrderedList, UnorderedList, Indent, Outdent],
                ]}
                contentStyle={{
                    height: 100,
                }}
            // placeholder={"Enter Text here"}
            />
        </span>
        );
    }

    function renderTextArea(control) {
        if (isViewMode || isPrintMode) {
            return <div className={columnCSS + rightMarginClass}>
                {renderHeaderableTitle(control)}
                {renderHint(control)}
                {getHtmlValueOf(control.id)}
                {renderOldValue(control)}
                <br />
            </div>
        }
        return <span className={columnCSS}>
            <div>
                <label className='mb-2' >{renderHeaderableTitle(control)}</label>
                {renderHint(control)}
            </div>
            <p>{control.text}</p>
            <TextArea
                disabled={disabled}
                value={getValueOf(control.id)}
                // placeholder={control.text}
                rows={5} />
            {renderMinMax(control)}
        </span >
    }

    function renderRadio(control) {
        const data = control.itemList.map((item, index) => {
            return {
                label: item,
                value: index,
            }
        })
        const radioItemIndex = getValueOf(control.id)
        const defaultValue = radioItemIndex ? data[radioItemIndex].value : undefined

        if (isViewMode || isPrintMode) {
            return <div className={columnCSS + rightMarginClass}>
                {renderHeaderableTitle(control)}
                {renderHint(control)}
                <div className="st-radio-container">
                    {data.map((item, index) => (
                        <div className="">
                            <label className="st-radio-label" key={index}>
                                <input
                                    type="radio"
                                    value={item.value}
                                    checked={item.value === defaultValue}
                                    id={`radio-${index}`}
                                    className="st-radio-input"
                                />
                                <span className="st-radio-content ml-2 mb-2">{item.label}</span>
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        }

        return <p className={columnCSS}>
            {renderHeaderableTitle(control)}
            {renderHint(control)}
            <div className=" p-0 radio-align-cus col-sm">
                <RadioGroup
                    disabled={disabled}
                    layout={"vertical"}
                    name={`radio${control.id}`}
                    defaultValue={defaultValue}
                    data={data}
                />
            </div>
            {renderOldValue(control, true)}
        </p >
    }

    function renderCheckbox(control) {
        if (isViewMode || isPrintMode) {
            const data = control.itemList.map((item, index) => {
                return {
                    label: item,
                    value: index,
                    checked: getValueOf(`${control.id}Checkbox${index}`) != ""
                }
            })

            return <div className={columnCSS + rightMarginClass}>
                {renderHeaderableTitle(control)}
                {renderHint(control)}
                <div className="custom-row">
                    {data.map((item, index) => (
                        <div key={index} className="mb-1 custom-col">
                            <label className="st-checkbox-label" key={index}>
                                <input
                                    type="checkbox"
                                    value={item.value}
                                    checked={item.checked}
                                    id={`checkbox-${index}`}
                                    className="st-checkbox-input"
                                    disabled={true}
                                />
                                <span className="st-checkbox-content">{item.label}</span>
                            </label>
                        </div>
                    ))}
                </div>

            </div >
        }
        return <p className={columnCSS}>
            <label className='mb-2' >{renderHeaderableTitle(control)}</label>
            {renderHint(control)}
            <div className="row ">
                {control.itemList.map((item, index) => {
                    return <div className="col-sm-6 heightCheck">
                        <Checkbox
                            disabled={disabled}
                            defaultChecked={getValueOf(`${control.id}Checkbox${index}`) != ""}
                            name={`checkbox${index}`}
                            // value={index}
                            // checked={index === 0}
                            className="text-overlap-cus"
                            label={item}
                        />
                        <br />
                    </div>
                })}
            </div>
            {oldDocumentFieldsMappings && <div className="">
                {control.itemList.map((item, index) => {
                    const keyName = `checkbox1Checkbox${index}`
                    if (oldDocumentFieldsMappings.find(x => x.keyName == keyName)) {
                        if (getValueOf(`${control.id}Checkbox${index}`)) {
                            return <div style={{ color: "grey" }}>{item}</div>
                        }
                    }
                    return ""
                })}
            </div>}
        </p >
    }

    function renderDropDown(control) {
        if (isViewMode || isPrintMode) {
            return <div className={columnCSS + rightMarginClass}>
                {renderHeaderableTitle(control)}
                {renderHint(control)}
                {getHtmlValueOf(control.id)}
                {renderOldValue(control)}
                <br />
            </div>
        }
        return <span className={columnCSS}>
            <label className='mb-2' >{renderHeaderableTitle(control)}</label>
            {renderHint(control)}
            {control.itemList.length > 0 &&
                <DropDownList
                    disabled={disabled}
                    defaultValue={getValueOf(control.id)}
                    data={control.itemList}
                />
            }
        </span >
    }

    function renderSignLine(_) {
        return <div className="border-bottom-line my-3"></div>
    }

    function renderDatePicker(control) {
        if (isViewMode || isPrintMode) {
            return <div className={columnCSS + rightMarginClass}>
                {renderHeaderableTitle(control)}
                {renderHint(control)}
                {getHtmlValueOf(control.id)}
                {renderOldValue(control)}
                <br />
            </div>
        }
        return <span className={columnCSS}>
            <label className='mb-2' >{renderHeaderableTitle(control)}</label>
            {renderHint(control)}
            <DatePickerKendoRct
                disabled={disabled}
                value={getValueOf(control.id)}
            />
        </span>
    }

    function renderTimePicker(control) {
        if (isViewMode || isPrintMode) {
            return <div className={columnCSS + rightMarginClass}>
                {renderHeaderableTitle(control)}
                {renderHint(control)}
                {getHtmlValueOf(control.id)}
                {renderOldValue(control)}
                <br />
            </div>
        }
        return <span className={columnCSS}>
            <label className='mb-2'>{renderHeaderableTitle(control)}</label>
            {renderHint(control)}
            <TimePicker
                disabled={disabled}
                value={getValueOf(control.id)}
                format="hh:mm a" />
        </span>
    }

    function render() {
        return <div className={rightMarginClass}>
            {templateName && <h4 className='fw-500 text-center'>{templateName}</h4>}
            {renderControlList.map((obj) => {
                columnCSS = obj.columnCSS
                return <>
                    {obj.controlList.map((control: any) => {

                        // Show only controls who are changed.
                        if (changedDocumentFieldKeyNames) {
                            const changedControl = changedDocumentFieldKeyNames.find(
                                keyName => keyName.includes(control.id))
                            if (!changedControl) {
                                return <></>
                            }
                        }

                        // Render controls
                        switch (control.type) {
                            case templateControls.paragraph:
                                return renderParagraph(control)
                            case templateControls.textBox:
                                return renderTextBox(control)
                            case templateControls.textArea:
                                return renderTextArea(control)
                            case templateControls.radio:
                                return renderRadio(control)
                            case templateControls.checkbox:
                                return renderCheckbox(control)
                            case templateControls.dropDown:
                                return renderDropDown(control)
                            case templateControls.signLine:
                                return renderSignLine(control)
                            case templateControls.datePicker:
                                return renderDatePicker(control)
                            case templateControls.timePicker:
                                return renderTimePicker(control)
                            // case templateControls.table:
                            //     return renderTable(control)
                            case templateControls.heading3:
                                return renderHeading3(control)
                            case templateControls.textEditor:
                                return renderTextKendoEditor(control)

                        }
                        return <></>
                    })}
                </>
            })}
        </div>
    }

    if (isPrintMode) {
        return <div ref={ref} className={"col-12 file-template p-0"}>
            {render()}
        </div>
    } else {
        return <div ref={ref} className={"col-12 file-template p-0"}>
            <div className={"preview-section mt-2 p-0"}>
                {render()}
            </div>
        </div>
    }
}
