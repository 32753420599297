export const styles = `
   body, h1, h2, h3, h4, h5, h6 {
        font-family:  'Manrope', sans-serif !important;
      }
      label {
        font-size: 12px !important;
      }

   .customCell
   {
    color:Black;
    font-size: 10px !important;
   }
   .customCell-light
   {
    color: #4a4a4b;
    margin-left: 2px;
    font-size: 10px !important;
   }
  .grid-template-pdf-head {
    margin-top: 1rem; /* equivalent to mt-4 */
  }
  
  .client-name {
    color: #000;
    font-size: 11px;
    margin-left: 2px;
  }
  
  .list-unstyled {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }
  
  .additional-info {
    padding-top: 5px;
  }
  
  .client-detail-item {
    color: #000;
  }
  
  .client-detail-label {
    font-size: 10px;
    font-weight: 600;
  }

  .printableHeader {
    color: var(--lebel_2) !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
  }

  .printableBigHeader {
    color: var(--black) !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
  }

  .printableContent {
    color: var(--black) !important;
    font-weight: 500;
    font-size: 13px;
    margin-top: 4px !important;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
  }

  .hr-line {
    border: none;
    border-top: 1px solid lightgray;
    margin: 10px 0;
  }

  .client-detail-value {
    color: #4a4a4b;
    margin-left: 2px;
  }
`;