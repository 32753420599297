import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { SELECTED_HEADER_MENU, SITE_ID, SELECTED_APP_SITE, SITE_VALUE, STAFF_LOGIN_DETAIL, } from "../../redux/actions/types";
import APP_ROUTES from "../../helper/app-routes";
import ApiHelper from "../../helper/api-helper";
import { NotificationManager } from "react-notifications";
import { permissionEnum, userIsAdmin, userIsSuperAdmin } from "../..//helper/permission-helper";
import DEVELOPMENT_CONFIG from "../../helper/config";
import moment from "moment";
import { renderErrors } from "src/helper/error-message-helper";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { customAxios } from "src/services/useExPressApi";

function MenuHeader() {
  const defaultSite = useSelector((state) => state.getStaffReducer);
  const [showMenuBar, setShowMenuBar] = useState(true);

  const [siteData, setSiteData] = useState([]);
  const [field, setField] = useState({});
  const [isShown, setIsShown] = useState(false);
  const [isBillingShow, setBillingShow] = useState(false);
  const [loginData, setLoginData] = React.useState("");
  const getRolePermission = useSelector(
    (state) => state.getRolePermission.canModifyClinicRoles
  );
  const isBillingManager = useSelector(
    (state) => state.getRolePermission.isBillingManager
  );
  const staffLoginInfo = useSelector((state) => state.getStaffReducer);
  const isImpersonating = useSelector(state => state.LoggedInReducer.appIsImpersonate);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const newPath = location.pathname.toLowerCase();
  let d = newPath.split("/");
  let primaryPath = d[1];
  const userAccessPermission = useSelector(
    (state) => state.userAccessPermission
  );
  useEffect(() => {
    getSiteByClinicId();
  }, [defaultSite]);

  useEffect(() => {
    if (isImpersonating) {
      getLoginDetail();
    }
  }, []);

  const handleChange = (e) => {
    setField(e.target.value);
    dispatch({
      type: SITE_ID,
      payload: e.target.value,
    });
    dispatch({
      type: SELECTED_APP_SITE,
      payload: e.target.value,
    });
  };

  const getSiteByClinicId = () => {
    ApiHelper.getRequest(API_ENDPOINTS.GET_STAFF_GLOBAL_VIEW_SITES)
      .then((result) => {
        let siteObj = {
          siteId: defaultSite?.defaultSite == 0 ? result.resultData[0].siteId : defaultSite.defaultSite,
          siteName: defaultSite.defaultSiteName == null ? result.resultData[0].siteName : defaultSite.defaultSiteName,
        }
        setField(siteObj);
        dispatch({ type: SITE_ID, payload: field, });
        dispatch({ type: SELECTED_APP_SITE, payload: siteObj });
        dispatch({ type: SITE_VALUE, payload: result.resultData, });
        setSiteData(result.resultData);
      })
      .catch((error) => { });
  };

  const handleDefaultValue = () => {
    const data = {
      siteId: field.siteId,
    };
    ApiHelper.postRequest(API_ENDPOINTS.SET_STAFF_DEFAULT_SITE, data)
      .then(() => {
        NotificationManager.success("Default Value Set Successfully");
      })
      .catch((error) => {
        NotificationManager.success(error.message);
      });
  };

  const getLoginDetail = async () => {
    const sessionResp = await customAxios.get(`${API_ENDPOINTS.GET_LOGIN_STAFF}`);
    if(sessionResp && sessionResp.resultData){
      let loginData = sessionResp.resultData;
        setLoginData(loginData);
        dispatch({
          type: STAFF_LOGIN_DETAIL,
          payload: loginData,
        });
  }
  };

  const menus = [
    {
      id: "1",
      name: "Dashboard",
      route: APP_ROUTES.DASHBOARD,
      className: primaryPath == "dashboard" ? "nav-link active" : "nav-link",
    },
    {
      id: "2",
      name: "Staff",
      route: APP_ROUTES.GET_STAFF,
      className: primaryPath == "staff" ? "nav-link active" : "nav-link",
    },
    {
      id: "3",
      name: "Clients",
      route: APP_ROUTES.GET_CLIENT,
      className: primaryPath == "client" ? "nav-link active" : "nav-link",
    },
    {
      id: "4",
      name: "Messages",
      route: APP_ROUTES.MESSAGE,
      className: primaryPath == "messages" ? "nav-link active" : "nav-link",
    },
    {
      id: "5",
      name: "Calendar",
      route: APP_ROUTES.SCHEDULER,
      className: primaryPath == "scheduler" ? "nav-link active" : "nav-link",
    },
    {
      id: "6",
      name: "Documents",
      route: APP_ROUTES.DOCUMENT_LIST,
      className: primaryPath == "document" ? "nav-link active" : "nav-link",
    },
  ];

  const clinicMenuItems = [];
  if (userIsAdmin(staffLoginInfo?.roleId) || userIsSuperAdmin(staffLoginInfo?.roleId)) {
    clinicMenuItems.push(
      {
        id: "1",
        name: "Document Templates",
        route: APP_ROUTES.DOCUMENT_TEMPLATE_LIST,
        className:
          newPath == APP_ROUTES.DOCUMENT_TEMPLATE_LIST
            ? "dropdown-item active"
            : "dropdown-item",
        value: true,
        isPermission: userIsAdmin(staffLoginInfo?.roleId),
      })
  }
  if (userIsAdmin(staffLoginInfo?.roleId) || userIsSuperAdmin(staffLoginInfo?.roleId)) {
    clinicMenuItems.push(
      {
        id: "2",
        name: "Services",
        route: APP_ROUTES.GET_SERVICE_BY_CLINICID,
        className:
          primaryPath == "services" ? "dropdown-item active" : "dropdown-item",
        value: true,
        isPermission: userIsAdmin(staffLoginInfo?.roleId),
      })
  }
  if (userIsAdmin(staffLoginInfo?.roleId) || userIsSuperAdmin(staffLoginInfo?.roleId)) {
    clinicMenuItems.push(
      {
        id: "3",
        name: "Sites",
        route: APP_ROUTES.GET_CLINIC_SITE,
        className:
          primaryPath == "provider" ? "dropdown-item active" : "dropdown-item",
        value: true,
        isPermission: userIsAdmin(staffLoginInfo?.roleId),
      })
  }
  if (userIsAdmin(staffLoginInfo?.roleId) || userIsSuperAdmin(staffLoginInfo?.roleId)) {
    clinicMenuItems.push(
      {
        id: "4",
        name: "Settings",
        route: APP_ROUTES.CLINIC_INFO,
        className:
          primaryPath == "settings" ? "dropdown-item active" : "dropdown-item",
        value: true,
        isPermission: userIsAdmin(staffLoginInfo?.roleId),
      })
  }
  if (userIsSuperAdmin(staffLoginInfo?.roleId) || userAccessPermission[permissionEnum.MANAGE_CLIENT_COMPLIANCE]) {
    clinicMenuItems.push(
      {
        id: "5",
        name: "Client Compliance",
        route: APP_ROUTES.CLIENT_COMPLIANCE,
        className:
          primaryPath == "client-compliance" ? "dropdown-item active" : "dropdown-item",
        value: true,
        isPermission: (userIsSuperAdmin(staffLoginInfo?.roleId) || userAccessPermission[permissionEnum.MANAGE_CLIENT_COMPLIANCE]),
      })
  }
  // {
  //   id: "8",
  //   name: "Compliance Report",
  //   route: APP_ROUTES.COMPLIANCE_REPORT,
  //   className:
  //     primaryPath == "compliance-report" ? "dropdown-item active" : "dropdown-item",
  //   value: true,
  //   isPermission: userIsAdmin(staffLoginInfo?.roleId),
  // },

  // {
  //   id: "11",
  //   name: "Authorization Set",
  //   route: APP_ROUTES.AUTHORIZATION_SETS,
  //   className:
  //     primaryPath == "authorization-sets" ? "dropdown-item active" : "dropdown-item",
  //   value: true,
  //   isPermission: userIsAdmin(staffLoginInfo?.roleId),
  // },
  // {
  //   id: "6",
  //   name: "Roles",
  //   route: APP_ROUTES.ROLES,
  //   className:
  //     primaryPath == "roles" ? "dropdown-item active" : "dropdown-item",
  //   // value: settingSwitch.canModifyClinicRoles,
  //   value: getRolePermission,
  //   isPermission: true,
  // },
  if (userIsSuperAdmin(staffLoginInfo?.roleId) || userAccessPermission[permissionEnum.MANAGE_AUTHORIZATIONS]) {
    clinicMenuItems.push(
      {
        id: "7",
        name: "Authorizations",
        route: APP_ROUTES.MULTIPLE_CLIENT_SERVICE_AUTH,
        className:
          primaryPath == "authorizations"
            ? "dropdown-item active"
            : "dropdown-item",
        // value: settingSwitch.canModifyClinicRoles,
        value: true,
        isPermission: userIsSuperAdmin(staffLoginInfo?.roleId) || userAccessPermission[permissionEnum.MANAGE_AUTHORIZATIONS],

      })
  }
  if (userIsAdmin(staffLoginInfo?.roleId) || userIsSuperAdmin(staffLoginInfo?.roleId)) {
    clinicMenuItems.push(
      {
        id: "10",
        name: "Default Goals",
        route: APP_ROUTES.DEFAULT_GOLAS,
        className:
          primaryPath == "defaultgoals"
            ? "dropdown-item active"
            : "dropdown-item",
        value: true,
        isPermission: userIsAdmin(staffLoginInfo?.roleId),
      })
  }
  // {
  //   id: "9",
  //   name: "Service Sets",
  //   route: APP_ROUTES.SERVICE_SETS,
  //   className:
  //     primaryPath == "servicesets"
  //       ? "dropdown-item active"
  //       : "dropdown-item",
  //   value: true,
  //   isPermission: userIsAdmin(staffLoginInfo?.roleId),
  // },
  if (userIsSuperAdmin(staffLoginInfo?.roleId) || userAccessPermission[permissionEnum.SHOW_CLINIC_FILES]) {
    clinicMenuItems.push(
      {
        id: "9",
        name: "Clinic Files",
        route: APP_ROUTES.CLINIC_FILE,
        className:
          primaryPath == "clinic-file"
            ? "dropdown-item active"
            : "dropdown-item",
        value: true,
        isPermission: (userIsSuperAdmin(staffLoginInfo?.roleId) || userAccessPermission[permissionEnum.SHOW_CLINIC_FILES]),
      })
  }

  // ];
  if (getRolePermission) {
    clinicMenuItems.push(
      {
        id: "6",
        name: "Roles",
        route: APP_ROUTES.ROLES,
        className:
          primaryPath == "roles" ? "dropdown-item active" : "dropdown-item",
        // value: settingSwitch.canModifyClinicRoles,
        value: getRolePermission,
        isPermission: true,
      })
  }
  
  clinicMenuItems.push(
    {
      id: "7",
      name: "Staff Documents",
      route: APP_ROUTES.STAFF_DOCUMENTS,
      className:
        primaryPath == "staff-documents" ? "dropdown-item active" : "dropdown-item",
      value: true,
      isPermission: true,
    })

  const accessMenuItems = [
    {
      id: "1",
      name: "Task Manager",
      route: APP_ROUTES.TASK_MANAGER,
      className:
        primaryPath == "task" ? "dropdown-item active" : "dropdown-item",
    },
    (userIsSuperAdmin(staffLoginInfo?.roleId) || userIsAdmin(staffLoginInfo?.roleId)) && {
      id: "2",
      name: "Administrative Board",
      route: APP_ROUTES.DASHBOARD_CHART,
      className:
        primaryPath == "dashboardchart" ? "dropdown-item active" : "dropdown-item",
      isPermission: userIsSuperAdmin(staffLoginInfo?.roleId)
    },
    {
      id: "3",
      name: "Time Clock",
      route: APP_ROUTES.TIMECLOCK,
      className:
        primaryPath == "clinic-file"
          ? "dropdown-item active"
          : "dropdown-item",
      value: true,
      isPermission: true,
    },
  ];

  // const dashbaordGrapgh = [
  //   {
  //     id: "1",
  //     name: "Dashboard Graph",
  //     route: APP_ROUTES.DASHBOARD_CHART,
  //     className:
  //       primaryPath == "" ? "dropdown-item active" : "dropdown-item",
  //   },
  // ];

  const AuditMenuItems = [
    {
      id: "1",
      name: "Staff Audit",
      route: APP_ROUTES.STAFF_AUDIT,
      className: primaryPath == "staffaudit" ? "dropdown-item active" : "dropdown-item",
    },
    {
      id: "2",
      name: "Client Audit",
      route: APP_ROUTES.CLIENT_AUDIT,
      className: primaryPath == "clientaudit" ? "dropdown-item active" : "dropdown-item",
    },
    // {
    //   id: "3",
    //   name: "Clinic Audit",
    //   route: APP_ROUTES.CLINIC_AUDIT,
    //   className:
    //     primaryPath == "Clinic" ? "dropdown-item active" : "dropdown-item",
    // },
  ];

  const billingItems = [
    {
      id: "1",
      name: "New Billing",
      route: APP_ROUTES.NEW_BILLING,
      className:
        newPath == APP_ROUTES.NEW_BILLING
          ? "dropdown-item active"
          : "dropdown-item",
      value: true,
    },
    {
      id: "2",
      name: "Billing Submissions",
      route: APP_ROUTES.BILLING_TRANSACTION,
      className:
        newPath == APP_ROUTES.BILLING_TRANSACTION
          ? "dropdown-item active"
          : "dropdown-item",
      value: true,
    },
    {
      id: "3",
      name: "Billing Remittance",
      route: APP_ROUTES.BILLING_REMITTANCE,
      className:
        newPath == APP_ROUTES.BILLING_REMITTANCE
          ? "dropdown-item active"
          : "dropdown-item",
      value: true,
    },
    {
      id: "4",
      name: "Billing Profiles",
      route: APP_ROUTES.BILLING_PROFILE,
      className:
        newPath == APP_ROUTES.BILLING_PROFILE ? "dropdown-item active" : "dropdown-item",
      value: true,
    },
  ];


  const onMenuSelect = (e, obj) => {
    e.preventDefault();
    navigate(obj.route);
    setShowMenuBar(!showMenuBar);
    dispatch({
      type: SELECTED_HEADER_MENU,
      payload: obj,
    });
  };

  const onMoreMenuSelect = (e, obj) => {
    e.preventDefault();
    setIsShown(false);
    setShowMenuBar(!showMenuBar);
    setBillingShow(false);
    navigate(obj.route);
  };



  return (
    <>
      <div className="menu-header-items">
        <header className="inner-header position-relative ">
          <div className="container-fluid d-flex justify-content-between align-items-baseline bgWHite nav-shadow">
            <nav className="navbar navbar-expand-lg navbar-light p-0">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                {/* {/ { <span className="navbar-toggler-icon"></span > } /} */}
              </button>
              <div
                className={`collapse navbar-collapse custome-nav cursor-pointer${showMenuBar ? "show" : " "
                  }`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0">
                  {menus.map((obj, index) => (
                    <li key={index} className="nav-item">
                      <a
                        href={obj.route}
                        className={obj.className}
                        onClick={(e) => {
                          onMenuSelect(e, obj);
                        }}
                      >
                        {obj.name}
                      </a>
                    </li>
                  ))}

                  {
                    (isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) &&

                    <li className="nav-item dropdown more-btn-drop  d-lg-block ">
                      <a
                        onMouseOver={() => setBillingShow(true)}
                        onMouseLeave={() => setBillingShow(true)}
                        className="nav-link dropdown-toggle"
                        type="button"
                      >
                        Billing <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </a>
                      {isBillingShow && (
                        <div className="dropdown-menu-t">
                          <div className="drop-downlist-billing">
                            <div className="d-flex flex-wrap">
                              <div className="col-md-12 list-unstyled col-12 mb-0 p-0">
                                <div className="mb-2">

                                  <ul className="list-unstyled pt-0 pb-0 ">
                                    {billingItems
                                      .filter((item) => {
                                        return item.value != false;
                                      })
                                      .map((obj, index) => (
                                        <li key={index} className="mb-1">
                                          <a
                                            className={obj.className}
                                            href={obj.route}
                                            onClick={(e) => {
                                              onMoreMenuSelect(e, obj);
                                            }}
                                          >
                                            {obj.name}
                                          </a>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom-line"></div>
                          </div>
                        </div>
                      )}
                    </li>
                  }

                  <li className="nav-item dropdown more-btn-drop  d-lg-block ">
                    <a
                      onMouseOver={() => setIsShown(true)}
                      className="nav-link dropdown-toggle"
                      type="button"
                    >
                      More <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                    {isShown && (
                      <div className="dropdown-menu-t">
                        <div className="drop-downlist">
                          <div className="d-flex flex-wrap">
                            <div className="col-md-6 list-unstyled col-12 mb-0 p-0 drop-down" >
                              <div className="mb-2">
                                <a className="dropdown-item heading">
                                  <h6 className="mb-1">Clinic</h6>
                                </a>
                                <ul className="list-unstyled pt-0 pb-0">
                                  {clinicMenuItems.sort((a, b) => {
                                    if (a.name < b.name) {
                                      return -1;
                                    }
                                    if (a.name > b.name) {
                                      return 1;
                                    }
                                    return 0;
                                  })
                                    .filter((item) => {
                                      return item.value != false;
                                    })
                                    .map((obj, index) => (
                                      <li key={index} className="mb-1">
                                        <a
                                          className={obj.className}
                                          href={obj.route}
                                          onClick={(e) => {
                                            onMoreMenuSelect(e, obj);
                                          }}
                                        >
                                          {obj.isPermission == true
                                            ? obj.name
                                            : ""}
                                        </a>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-6 list-unstyled col-12 mb-0">
                              {userIsAdmin(staffLoginInfo?.roleId) && (
                                <div className="mb-2">
                                  <a className="dropdown-item heading">
                                    <h6 className="mb-1">Audit</h6>
                                  </a>
                                  <ul className="list-unstyled pt-0 pb-0">
                                    {AuditMenuItems.map((obj, index) => (
                                      <li key={index} className="mb-1">
                                        <a
                                          className={obj.className}
                                          href={obj.route}
                                          onClick={(e) => {
                                            onMoreMenuSelect(e, obj);
                                          }}
                                        >
                                          {obj.name}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                              <div className="mb-2">
                                <a className="dropdown-item heading">
                                  <h6 className="mb-1">Misc.</h6>
                                </a>
                                <ul className="list-unstyled pt-0 pb-0">
                                  {accessMenuItems.map((obj, index) => (
                                    <li key={index} className="mb-1">
                                      <a
                                        className={obj.className}
                                        href={obj.route}
                                        onClick={(e) => {
                                          onMoreMenuSelect(e, obj);
                                        }}
                                      >
                                        {obj.name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>

                              {/* <div className="mb-2">
                              <a className="dropdown-item heading">
                                <h6 className="mb-1">Dashboard Graph</h6>
                              </a>
                              <ul className="list-unstyled pt-0 pb-0">
                                {dashbaordGrapgh.map((obj, index) => (
                                  <li key={index} className="mb-1">
                                    <a
                                      className={obj.className}
                                      href={obj.route}
                                      onClick={(e) => {
                                        onMoreMenuSelect(e, obj);
                                      }}
                                    >
                                      {obj.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div> */}
                            </div>
                          </div>
                          <div className="border-bottom-line"></div>
                        </div>
                      </div>
                    )}
                  </li>
                </ul>
              </div>

            </nav>
            <div className='d-flex align-items-center'>
              <div className='d-flex align-items-center'>
                <p
                  className="mr-1 ml-0 mb-0 mt-0  default-head-btn"
                  style={{ textAlign: "center" }}
                >
                  Site
                </p>
                <div className="header-site-cus">
                  <DropDownList
                    style={{
                      width: "220px",
                      height: "30px",
                      padding: "0",
                      marginTop: "0px",
                    }}
                    onChange={handleChange}
                    data={siteData}
                    value={field}
                    textField="siteName"
                    dataItemKey="siteId"
                    suggest={true}
                    name="field"
                    autoClose={true}
                    className="fw-600"
                  />
                </div>
              </div>

              <button
                onClick={handleDefaultValue}
                className="btn blue-primary-outline btn-sm  default-head-btn"
              >
                Set as Default
              </button>
            </div>
          </div>
        </header>

      </div>
      {isImpersonating &&
        <div className="headerBottomText">
          <div className="container-fluid">
            <p className="m-0" > {moment(new Date()).format("M/D/yyyy hh:mm A")}: You are currently impersonating as {loginData.firstName} {loginData.lastName}({loginData.roleName})</p>
          </div>
        </div>
      }

    </>

  );
}
export default MenuHeader;
