import React from 'react'
import { ComponentState, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "src/control-components/loader/loader";
import { DocAttachements, DocClientSignature, DocCommonControllArray2, DocQuestionaries, DocSignature, DocumentByIdResponse, DocumentFieldsMapping, FormattedDocument, LinkedDocs } from "src/dataModels/docListModel";
import apiHelper from "src/helper/api-helper";
import APP_ROUTES from "src/helper/app-routes";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { customAxios } from "src/services/useExPressApi";
import { convertServerDocumentToLocal } from '../documents/document-utility';
import { showError } from 'src/util/utility';
import axios from 'axios';
import { delay } from 'redux-saga/effects';
import { Encrption } from '../encrption';
import { saveClinicLogoBytes } from 'src/redux/actions';
import { mapDocumentTemplate } from '../documents/template/document-template-utility';
import DocumentTemplatePdf from '../documents/pdf-generator/pdf-doc-temp';
import { docs } from 'src/services/documentService';

export const CallByClient = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isProcessStarted, setIsProcessStarted] = useState(false)
  let documentId = location.state.docId;
  let token = location.state.token;
  const [documentFormattedVal, setDocumentDetail] = useState<FormattedDocument>();
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [template, setTemplate] = useState<any>();
  const [isHtmlFileTypeTemplate, setHtmlFileTypeTemplate] = useState(false);
  const [htmlFileName, setHtmlFileName] = useState("");
  const [fieldsMapping, setFieldsMapping] = useState<DocumentFieldsMapping[]>([]);
  const [docSignature, setDocSignature] = useState<DocSignature[]>([]);
  const [docClientSignature, setDocClientSignature] = useState<DocClientSignature[]>([]);
  const [historyList, setHistoryList] = useState<[]>([]);
  const [staffInfo, setStaffInfo] = useState({ 'clinicName': '' });
  const userAccessPermission = useSelector(
    (state: ComponentState) => state.userAccessPermission
  );
  const [isLockDocument, setLockDocument] = useState(false);
  const [documentCommonControls, setDocumentControls] = useState<DocCommonControllArray2>();
  const [docHasControlls, setDocHasControlls] = useState(false);
  const [docDataIsFetched, setDocHasFetched] = useState(false);
  const [documentDetailItems, setDocDetailItems] = useState()

  const navaiGateToScreen = () => {
    navigate(APP_ROUTES.DOCUMENT_VIEW);
  }

  useEffect(() => {
    if (!isProcessStarted && documentId) {
      getDocumentInfo()
    }
  }, [isProcessStarted])


  useEffect(() => {
    if (docDataIsFetched) {
      setLockDocument(true)
    }
  }, [docDataIsFetched])

  const getDocumentInfo = async () => {
    if (documentId > 0) {
      const riskAreaList:any = await customAxios.get(`${API_ENDPOINTS.GET_RISK_DDL}${'riskArea'}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      })

      const riskFactorsList:any = await customAxios.get(`${API_ENDPOINTS.GET_RISK_DDL}${'riskFactors'}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },

      })

      const protectiveFactorsList:any = await customAxios.get(`${API_ENDPOINTS.GET_RISK_DDL}${'protectiveFactors'}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      })

      localStorage.setItem('riskList', JSON.stringify(riskAreaList?.resultData));
      localStorage.setItem('riskFactorList', JSON.stringify(riskFactorsList?.resultData));
      localStorage.setItem('riskProtectiveList', JSON.stringify(protectiveFactorsList?.resultData));
      await fetchByDocumentId(documentId);
    }


  }

  const handlePrintOnClick = async () => {
    await delay(2000);
    setDocHasFetched(true)
  };


  async function fetchByDocumentId(documentIds) {
    customAxios.get(API_ENDPOINTS.GET_DOCUMENT_BY_ID + documentIds, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },

    })
      .then(async (response) => {
        const result: any = response
        if (result?.resultData) {

          const clinicLogoResp: any = await customAxios.get<any, any>(`${API_ENDPOINTS.GET_CLINIC_LOGO}${Encrption(result.resultData.clinicId)}&inBase64=${true}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            },
          })
          if (clinicLogoResp.status) {
            dispatch(saveClinicLogoBytes(clinicLogoResp.resultData.clinicLogo));
          }



          const metaResponse: any = await customAxios.post<any, any>(`${API_ENDPOINTS.GET_DOC_PRINT_META_DATA}`, [documentId], {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            },
          })
          if (metaResponse && metaResponse.status) {
            setDocDetailItems(metaResponse.resultData)
          }


          const docSignatureResp: any = await customAxios.get<any, any>(`${API_ENDPOINTS.GET_STAFF_DOCUMENT_SIGNATURE}${documentId}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            },
          })
          if (docSignatureResp.status) {
            setDocSignature(docSignatureResp.resultData)
          }

          const docClientSignatureResp: any = await customAxios.get<any, any>(`${API_ENDPOINTS.GET_CLIENT_DOCUMENT_SIGNATURE}${documentId}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            },
          })
          if (docClientSignatureResp.status) {
            setDocClientSignature(docClientSignatureResp.resultData)
          }

          const templateResp: any = await customAxios.get<any, any>(`${API_ENDPOINTS.GET_DOCUMENT_TEMPLATE_BY_ID}${result.resultData.documentTemplateId}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            },
          })
          if (templateResp.status) {
            setTemplate(mapDocumentTemplate(templateResp.resultData));
          }

          // const historyResp:any=  await customAxios.get<any, any>(`${API_ENDPOINTS.DOCUMENT_HISTORY}${documentId}`,{
          //   headers: {
          //     'Content-Type': 'application/json',
          //     'Authorization': 'Bearer ' + token
          //   },
          // })
          // if(historyResp.status){
          //   setHistoryList(historyResp.resultData)
          // }



          setDocumentDetail(convertServerDocumentToLocal(result.resultData));
          setHtmlFileTypeTemplate(result.resultData.isHtmlFileTypeTemplate);
          setHtmlFileName(result.resultData.htmlFileName);
          setStaffInfo({ ...staffInfo, clinicName: result.resultData.clinicName })
          setFieldsMapping(result.resultData.documentFieldsMappings);

          setLockDocument(result.resultData.isLocked);

          setDocumentControls(result.resultData.documentCommonControls)
          const hadDx = (result.resultData.documentCommonControls.documentGoals?.length ?? 0 > 0) ? true : false
          const hasGoals = (result.resultData.documentCommonControls.documentDiagnosis?.length ?? 0 > 0) ? true : false
          setDocHasControlls(hadDx || hasGoals)
          handlePrintOnClick()
        }

      })
      .catch((err) => {
        showError(err, "Fetch Document");
      })
      .finally(() => {

      });
  }






  return (<div>

    {isLockDocument ?
      <DocumentTemplatePdf
        documentPrintData={[{
          staffInfo: staffInfo,
          documentDetail: documentFormattedVal,
          diagnosisList: diagnosisList,
          docSignature: docSignature,
          docClientSignature: docClientSignature,
          isHtmlFileTypeTemplate: isHtmlFileTypeTemplate,
          htmlFileName: htmlFileName,
          template: template,
          fieldsMapping: fieldsMapping,
          documentId: documentId,
          docTreatmentPlans: documentFormattedVal?.documentTreatmentPlans,
          historyList: historyList,
          docHasControlls: docHasControlls,
          documentCommonControls: documentCommonControls
        }]}
        onlyView={true}
        metaDataDocs={documentDetailItems}
      />
      : <Loading></Loading>}
  </div>)

}

