import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import Loader from "../../../../control-components/loader/loader";
import ApiHelper from "../../../../helper/api-helper";
import { Switch } from "@progress/kendo-react-inputs";
import { NotificationManager } from "react-notifications";
import { renderErrors } from "src/helper/error-message-helper";
import { useDispatch } from "react-redux";
import { StaffService } from "src/services/staffService";
import { TableRowsLoader } from "src/control-components/custom-skelton";
import { API_ENDPOINTS } from "src/services/api-endpoints";

const StaffSettingTemplate = ({
  onClose,
  isSelectedId,
  getAllDocumentTemplates,
  templateAssignedIdProp
}) => {
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState({
    canApplySignature: false,
    showSessionTime: false,
    showClinicalSkills: false,
    showGoals: false,
    showReviewServices: false,
    showFileAttachment: false,
    showContactType: false
  });
  const [templateAssignedId, setTemplateAssignedId] = useState()

  useEffect(() => {
    setLoading(true)
    getDocumentTemplatePrefById();
  }, []);

  const getDocumentTemplatePrefById = () => {
    try {
      StaffService.getStaffPlanDocTemplateSettings(isSelectedId).then((response) => {
        const data = response?.resultData
        setFields({
          canApplySignature: data?.canApplySignature,
          showSessionTime: data?.showSessionTime,
          showClinicalSkills: data?.showClinicalSkills,
          showGoals: data?.showGoals,
          showReviewServices: data?.showReviewServices,
          showFileAttachment: data?.showFileAttachment,
          showContactType: data?.showContactType
        })
        setTemplateAssignedId(data?.templateAssignedId || templateAssignedIdProp)
        setLoading(false)
      })
    }
    catch(error) {
      renderErrors(error)
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
      setFields({
        ...fields,
        [name]: value,
      });
  };

  const saveTask = async () => {
    setLoading(true);
    const data = {
      templateAssignedId: templateAssignedId,
      canApplySignature: fields?.canApplySignature,
      showGoals: fields?.showGoals,
      showClinicalSkills: fields?.showClinicalSkills,
      showContactType: fields?.showContactType,
      showSessionTime: fields?.showSessionTime,
      showReviewServices: fields?.showReviewServices,
      showFileAttachment: fields?.showFileAttachment
    };

    await ApiHelper.postRequest(API_ENDPOINTS.INSERT_UPDATE_STAFF_PLAN_TEMPLATE_SETTINGS, data)
      .then((result) => {
        setLoading(false);
        NotificationManager.success("Document template updated successfully");
        onClose({ added: true });
        getAllDocumentTemplates();
      })
      .catch((error) => {

        setLoading(false);
        renderErrors(error);
      });
  };

  const handleSubmit = () => {
    saveTask();
  };

  return (
    <Dialog
      onClose={onClose}
      title="Staff Document Template Settings"
      className="small-dailog medium-modal width_90" width={"70%"} height={"45%"}
    >
      {loading ? <TableRowsLoader rowsNum = {4}/> :
      <div className="column mt-3 ">
        <div className="row m-0">
          <div className="col-md-6 col-lg-6 col-6 mb-3">
            <Switch
              value={fields.canApplySignature}
              name={"canApplySignature"}
              onChange={handleChange}
              checked={fields.canApplySignature}
            />

            <span className="switch-title-text ml-2  fw-400 f-16 ">
              Can Apply Signature
            </span>
          </div>
          <div className="col-md-6 col-lg-6 col-6 mb-3">
            <Switch
              value={fields.showFileAttachment}
              name={"showFileAttachment"}
              onChange={handleChange}
              checked={fields.showFileAttachment}
            />

            <span className="switch-title-text ml-2  fw-400 f-16 ">
              Show File Attachment
            </span>
          </div>

          <div className="col-md-6 col-lg-6 col-6 mb-3">
            <Switch
              value={fields.showSessionTime}
              name={"showSessionTime"}
              onChange={handleChange}
              checked={fields.showSessionTime}
            />

            <span className="switch-title-text ml-2  fw-400 f-16 ">
              Show Session Time
            </span>
          </div>

          
          <div className="col-md-6 col-lg-6 col-6 mb-3">
            <Switch
              value={fields.showReviewServices}
              name={"showReviewServices"}
              onChange={handleChange}
              checked={fields.showReviewServices}
            />

            <span className="switch-title-text ml-2  fw-400 f-16 ">
              Show Review Services
            </span>
          </div>

          
          <div className="col-md-6 col-lg-6 col-6 mb-3">
            <Switch
              value={fields.showContactType}
              name={"showContactType"}
              onChange={handleChange}
              checked={fields.showContactType}
            />

            <span className="switch-title-text ml-2  fw-400 f-16 ">
              Show Contact Type
            </span>
          </div>

          <div className="col-md-6 col-lg-6 col-6 mb-3">
            <Switch
              value={fields.showClinicalSkills}
              name={"showClinicalSkills"}
              onChange={handleChange}
              checked={fields.showClinicalSkills}
            />

            <span className="switch-title-text ml-2  fw-400 f-16 ">
              Show Clinical Skills
            </span>
          </div>

          <div className="col-md-6 col-lg-6 col-6 mb-3">
            <Switch
              value={fields.showGoals}
              name={"showGoals"}
              onChange={handleChange}
              checked={fields.showGoals}
            />

            <span className="switch-title-text ml-2  fw-400 f-16 ">
              Show Goals
            </span>
          </div>

        </div>
        

        <div className="preview-btn-bottom">
          <div className="border-bottom-line"></div>

          <div className="d-flex my-3">
            <div>
              <button
                onClick={handleSubmit}
                className="btn blue-primary text-white "
              >
                Update
              </button>
            </div>
            <div>
              <button className="btn grey-secondary text-white  mx-3" onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>}
    </Dialog>
  );
};
export default StaffSettingTemplate;