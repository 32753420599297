import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { SaveAssigntoStaffResponse, SaveAvailableServiceList, SaveServiceDDLByClinicId, SaveServiceSetDDL, getAuthSetList, getClinicDefaultGoalsTemplates, getClinicStoredDocumentByClinicId, getClinicTpServiceTypes, getClinicTpStaffTypes, getClinicianList, getServiceSets, getStoreComplianceReport, getclinicDefaultGoals, loaderAction, saveAssignGoalResponse, saveAssignedRolesForNotificationSetting, saveAuthSetList, saveClientCompliance, saveClientComplianceById, saveClientComplianceForClinicDDL, saveClinicDefaultGoals, saveClinicDefaultGoalsTemplates, saveClinicDocumentById, saveClinicDocumentS3SignedUrl, saveClinicNotificationSetting, saveClinicServiceList, saveClinicStoredDocumentByClinicId, saveClinicTags, saveClinicTpServiceTypes, saveClinicTpStaffTypes, saveClinicianList, saveDoumentTemplate, saveGoalById, saveInsertClinicianResponse, saveRole, saveServiceSets,saveCalenderCategory,getCalenderCategory,saveDefaultCalendarCategories} from '../actions';

import { AssignDefaultGoalsResponse, ClientComplianceResponse, ClinicDefaultGoalResponse, ClinicServiceListResponse, ClinicStoredDocumentByclinicIdModel, ClinicTpServiceOrStaffTypeResponse, CommonDeleteResponseModel, ComplianceResponse, DefaultGoalsTemplateListResponse, DeleteClinicDefaultGoalResponse, DeleteServiceSetResponse, DocumentTemplateResponse, SaveDefaultGoalsTemplatResponseModel, ServiceDDLByClinicIdResponse, ServiceSetsResponse, UpdateDefaultGoalsTemplatResponseModel,AssignCertificateToStaffsResponse,calendarSettingResponse,DefaultCalendarCategoriesResponse} from 'src/dataModels/clinicResponseModels';
import { clinicServices } from 'src/services/clinicService';
import { NotificationManager } from "react-notifications";
import NOTIFICATION_MESSAGE from 'src/helper/notification-messages';
import { clientServices } from 'src/services/clientServices';
import { ServiceSetDDLResponse } from 'src/dataModels/staffResponsesModels';
import { GenericResponse } from 'src/dataModels/authSetModels';

export function* getClientComplianceSaga() {
    yield takeLatest(TYPES.GET_CLIENT_COMPLIANCE_FOR_CLINIC, clientComplianceWorker)
}

function* clientComplianceWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ComplianceResponse = yield clinicServices.complianceList();
        yield put(loaderAction(false));
        yield put(saveClientCompliance(response))
    }
    catch (err) {
    }

}

export function* getClinicServiceListSaga() {
    yield takeLatest(TYPES.GET_CLINIC_SERVICES, getClinicServiceListWorker)
}
function* getClinicServiceListWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ClinicServiceListResponse = yield clinicServices.clinicServiceList(param['payload']);
        yield put(loaderAction(false));
        yield put(saveClinicServiceList(response))
    }
    catch (err) {
    }
}


export function* getDocumentTemplateSaga() {
    yield takeLatest(TYPES.GET_DOCUMENT_TEMPLATE, getDocumentTemplateWorker)
}
function* getDocumentTemplateWorker(param: any) {
    try {
        // yield put(loaderAction(true));
        const response: DocumentTemplateResponse = yield clinicServices.documentTemplate();
        yield put(loaderAction(false));
        yield put(saveDoumentTemplate(response))
    }
    catch (err) {
    }
}

export function* getClientComplianceForClinicDDLSaga() {
    yield takeLatest(TYPES.GET_CLIENT_COMPLIANCE, getClientComplianceForClinicDDLWorker)
}
function* getClientComplianceForClinicDDLWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ClientComplianceResponse = yield clinicServices.clientCompliance(param['payload']);
        yield put(loaderAction(false));
        yield put(saveClientComplianceForClinicDDL(response))
    }
    catch (err) {
    }
}

export function* getClientComplianceByIdSaga() {
    yield takeLatest(TYPES.GET_CLIENT_COMPLIANCE_BY_ID, getClientComplianceByIdWorker)
}
function* getClientComplianceByIdWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ClientComplianceResponse = yield clinicServices.clientComplianceById(param['payload']);
        yield put(loaderAction(false));
        yield put(saveClientComplianceById(response))
    }
    catch (err) {
    }
}

export function* getClinicDefaultGoalsSaga() {
    yield takeLatest(TYPES.GET_CLINIC_DEFAULT_GOALS, getClinicDefaultGoalWorker)
}
function* getClinicDefaultGoalWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ClinicDefaultGoalResponse = yield clinicServices.clinicDefaultGoalList();
        yield put(loaderAction(false));
        yield put(saveClinicDefaultGoals(response))
    }
    catch (err) {
    }
}

export function* deleteClinicDefaultGoalsSaga() {
    yield takeLatest(TYPES.DELETE_CLINIC_DEFAULT_GOALS, deleteClinicDefaultGoalWorker)
}
function* deleteClinicDefaultGoalWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: DeleteClinicDefaultGoalResponse = yield clinicServices.deleteDefaultGoal(param['payload'])
        if (response.statusCode === 200) {

            NotificationManager.success(NOTIFICATION_MESSAGE.DELETE_DEFAULT_GOALS);
        }
        yield put(getclinicDefaultGoals());
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* assignDefaultGoalsToClientSaga() {
    yield takeLatest(TYPES.ASSIGN_DEFAULT_GOALS_TO_CLIENT, assignDefaultGoalsToClientWorker)
}
function* assignDefaultGoalsToClientWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: AssignDefaultGoalsResponse = yield clinicServices.assignDefaultGoalsToClients(param['payload'])
        if (response.statusCode === 200) {
            yield put(saveAssignGoalResponse(200));
            NotificationManager.success(NOTIFICATION_MESSAGE.ASSIGN_DEFAULT_GOAL);
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}


export function* getGoalByIdSaga() {
    yield takeLatest(TYPES.GET_GOAL_BY_ID, getGoalByIdSagaWorker)
}
function* getGoalByIdSagaWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clientServices.getDefaultGoalDataById(param['payload'])
        yield put(saveGoalById(response.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
    }
}

export function* getServiceSetsSaga() {
    yield takeLatest(TYPES.GET_SERVICE_SETS, getServiceSetsWorker)
}
function* getServiceSetsWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ServiceSetsResponse = yield clientServices.getServiceSets()

        yield put(saveServiceSets(response.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* deleteServiceSetSaga() {
    yield takeLatest(TYPES.DELETE_SERVICE_SETS, deleteServiceSetWorker)
}
function* deleteServiceSetWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: DeleteServiceSetResponse = yield clinicServices.deleteServiceSet(param['payload'])
        if (response.statusCode === 200) {

            NotificationManager.success(NOTIFICATION_MESSAGE.DELETE_SERVICE_SET);
        }
        yield put(getServiceSets());
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* InsertServiceSetSaga() {
    yield takeLatest(TYPES.INSERT_SERVICE_SETS, InsertServiceSetWorker)
}
function* InsertServiceSetWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.insertServiceSet(param['payload'])
        if (response.statusCode === 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.INSERT_SERVICE_SET);
        }
        yield put(getServiceSets());
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* UpdateServiceSetSaga() {
    yield takeLatest(TYPES.UPDATE_SERVICE_SETS, UpdateServiceSetWorker)
}
function* UpdateServiceSetWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.updateServiceSet(param['payload'])
        if (response.statusCode === 200) {

            NotificationManager.success(NOTIFICATION_MESSAGE.UPDATE_SERVICE_SET);
        }
        yield put(getServiceSets());
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* AssignSetToStaffSaga() {
    yield takeLatest(TYPES.ASSIGN_SET_TO_STAFF, AssignSetToStaffWorker)
}
function* AssignSetToStaffWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.assignSetToStaff(param['payload'])
        if (response.statusCode === 200) {
            yield put(SaveAssigntoStaffResponse(response));
            NotificationManager.success(NOTIFICATION_MESSAGE.ASSIGN_SET_TO_STAFF);
        }
        yield put(getServiceSets());
        yield put(loaderAction(false));

    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* AssignSetToClientSaga() {
    yield takeLatest(TYPES.ASSIGN_SET_TO_CLIENT, AssignSetToClientWorker)
}
function* AssignSetToClientWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.assignSetToClient(param['payload'])
        if (response.statusCode === 200) {
            yield put(SaveAssigntoStaffResponse(response));
            NotificationManager.success(NOTIFICATION_MESSAGE.ASSIGN_SET_TO_CLIENT);
        }
        yield put(getServiceSets());
        yield put(loaderAction(false));

    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* AvailableServiceListSaga() {
    yield takeLatest(TYPES.GET_AVAILABLE_SERVICE_LIST, AvailableServiceListWorker)
}
function* AvailableServiceListWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.availableServiceList(param['payload'])
        yield put(SaveAvailableServiceList(response.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* GetServiceDDLByClinicIdSaga() {
    yield takeLatest(TYPES.GET_SERVICE_DDL_BY_CLINIC_ID, ServiceDDLByClinicIdWorker)
}
function* ServiceDDLByClinicIdWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ServiceDDLByClinicIdResponse = yield clinicServices.getServiceDDLByClinicId()
        yield put(SaveServiceDDLByClinicId(response));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}


export function* getAuthSetListSaga() {
    yield takeLatest(TYPES.GET_AUTH_SET, getAuthSetWorker)
}
function* getAuthSetWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: GenericResponse = yield clinicServices.getAuthSetList()
        yield put(saveAuthSetList(response.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}


export function* deleteAuthSetSaga() {
    yield takeLatest(TYPES.DELETE_AUTH_SET, deleteAuthSetWorker)
}

function* deleteAuthSetWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: GenericResponse = yield clinicServices.deleteAuthSet(param['payload'])
        yield put(loaderAction(false));
        yield put(getAuthSetList());
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* createNewAuthSaga() {
    yield takeLatest(TYPES.CREATE_NEW_AUTH_SET, createNewAuthWorker)
}

function* createNewAuthWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: GenericResponse = yield clinicServices.createNewAuthSet(param['payload'])
        yield put(loaderAction(false));
        yield put(getAuthSetList());
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}


export function* updateAuthSetSaga() {
    yield takeLatest(TYPES.UPDATE_AUTH_SET, updateAuthWorker)
}

function* updateAuthWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: GenericResponse = yield clinicServices.updateAuthSet(param['payload'])
        yield put(loaderAction(false));
        yield put(getAuthSetList());
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* SaveDefaultGoalTemplatetSaga() {
    yield takeLatest(TYPES.SAVE_DEFAULT_GOALS_TEMPLATES, SaveDefaultGoalTemplateWorker)
}
function* SaveDefaultGoalTemplateWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: SaveDefaultGoalsTemplatResponseModel = yield clinicServices.insertDefaultGoalTemplate(param['payload'])
        if (response.statusCode === 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.INSERT_DEFAUT_GOALS_TEMPLATES);
        }
        yield put(getClinicDefaultGoalsTemplates());
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* getClinicDefaultGoalsTemplatesSaga() {
    yield takeLatest(TYPES.GET_CLINIC_DEFAULT_GOALS_TEMPLATES, getClinicDefaultGoalsTemplatesWorker)
}
function* getClinicDefaultGoalsTemplatesWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: DefaultGoalsTemplateListResponse = yield clinicServices.getClinicDefaultGoalsTemplates()
        yield put(saveClinicDefaultGoalsTemplates(response.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* deleteDefaultGoalTemplateSetSaga() {
    yield takeLatest(TYPES.DELETE_DEFAULT_GOALS_TEMPLATES, deleteDefaultGoalTemplateWorker)
}
function* deleteDefaultGoalTemplateWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: CommonDeleteResponseModel = yield clinicServices.deleteDefaultGoaltemplate(param['payload'])
        if (response.statusCode === 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.DELETE_DEFAUT_GOALS_TEMPLATES);
        }
        yield put(loaderAction(false));
        yield put(getClinicDefaultGoalsTemplates());
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* updateClinicDefaultGoalsTemplateSaga() {
    yield takeLatest(TYPES.UPDATE_DEFAULT_GOALS_TEMPLATES, updateClinicDefaultGoalsTemplateWorker)
}

function* updateClinicDefaultGoalsTemplateWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: UpdateDefaultGoalsTemplatResponseModel = yield clinicServices.updateDefaultGoalTemplate(param['payload'])
        yield put(loaderAction(false));
        if (response.statusCode === 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.UPDATE_DEFAUT_GOALS_TEMPLATES);
        }
        yield put(getClinicDefaultGoalsTemplates());

    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

// clinic file

export function* getClinicDocumentS3SignedUrlSaga() {
    yield takeLatest(TYPES.GET_CLINIC_DOCUMENT_S3_SIGNED_URL, getClinicDocumentS3SignedUrlWorker)
}
function* getClinicDocumentS3SignedUrlWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.getClinicDocumentS3SignedUrl(param['payload'])
        yield put(saveClinicDocumentS3SignedUrl(response.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* insertClinicianSaga() {
    yield takeLatest(TYPES.INSERT_CLINICIAN, insertClinicianWorker)
}

function* insertClinicianWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: GenericResponse = yield clinicServices.insertClinician(param['payload'])
        yield put(loaderAction(false));
        yield put(saveInsertClinicianResponse());
        yield put(getClinicianList());
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}


export function* getClinicStoredDocumentByClinicSaga() {
    yield takeLatest(TYPES.GET_CLINIC_STORED_DOCUMENT_BY_CLINIC_ID, getClinicStoredDocumentByClinicWorker)
}
function* getClinicStoredDocumentByClinicWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ClinicStoredDocumentByclinicIdModel = yield clinicServices.getClinicStoreddocumentByClinicId()
        yield put(saveClinicStoredDocumentByClinicId(response.resultData))
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* getClinicianListSaga() {
    yield takeLatest(TYPES.GET_CLINICIAN_LIST, getClinicianWorker)
}

function* getClinicianWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: GenericResponse = yield clinicServices.getClinicianList()
        yield put(loaderAction(false));
        yield put(saveClinicianList(response.resultData));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* getClinicS3DocumentUrlByIdSaga() {
    yield takeLatest(TYPES.GET_CLINIC_S3_DOCUMENT_URL_BYID, getClinicS3DocumentUrlByIdWorker)
}
function* getClinicS3DocumentUrlByIdWorker(param: any) {
    try {
        yield put(loaderAction(true));
        yield clinicServices.getClinicDefaultGoalsTemplates()
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* getClinicDocumentByIdSaga() {
    yield takeLatest(TYPES.GET_CLINIC_DOCUMENT_BYID, getClinicDocumentByIdWorker)
}
function* getClinicDocumentByIdWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.getClinicDocumentById(param['payload'])
        yield put(saveClinicDocumentById(response.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* deleteClinicDocumentTagSaga() {
    yield takeLatest(TYPES.DELETE_CLINIC_DOCUMENT_TAG, deleteClinicDocumentTagWorker)
}
function* deleteClinicDocumentTagWorker(param: any) {
    try {
        yield put(loaderAction(true));
        yield clinicServices.deleteClinicDocumentTag(param['payload'])
        yield put(getClinicStoredDocumentByClinicId())
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* deleteClinicDocumentSaga() {
    yield takeLatest(TYPES.DELETE_CLINIC_DOCUMENT, deleteClinicDocumentWorker)
}
function* deleteClinicDocumentWorker(param: any) {
    try {
        yield put(loaderAction(true));
        yield clinicServices.deleteClinicDocument(param['payload'])
        yield put(getClinicStoredDocumentByClinicId())
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* getCliniTagsSaga() {
    yield takeLatest(TYPES.GET_CLINIC_TAGS, getClinicTagsWorker)
}
function* getClinicTagsWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.getClinicTags(param['payload'])
        yield put(saveClinicTags(response.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}


export function* assignCliniTagsSaga() {
    yield takeLatest(TYPES.ASSIGN_CLINIC_TAGS, assignClinicTagsWorker)
}
function* assignClinicTagsWorker(param: any) {
    try {
        yield put(loaderAction(true));
        yield clinicServices.assignClinicTags(param['payload'])
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* getRoleListSaga() {
    yield takeLatest(TYPES.GET_CLINIC_NOTIFICATION_SETTING, getRoleListWorker)
}
function* getRoleListWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.getRole()

        yield put(saveRole(response.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}


export function* getClinicNotificationSettingSaga() {
    yield takeLatest(TYPES.GET_CLINIC_NOTIFICATION_SETTING, GetClinicNotificationSettingWorker)
}
function* GetClinicNotificationSettingWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.getClinicNotificationSetting(param['payload'])
        yield put(saveClinicNotificationSetting(response));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* saveClinicNotificationSettingPayloadSaga() {
    yield takeLatest(TYPES.SAVE_CLINIC_NOTIFICATION_PAYLOAD, saveClinicNotificationSettingPayloadWorker)
}
function* saveClinicNotificationSettingPayloadWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.saveClinicNotificationSettingPayload(param['payload'])
        if (response?.statusCode == 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS);
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* assignNotificationSettingPayloadSaga() {
    yield takeLatest(TYPES.ASSIGN_NOTIFICATION_SETTING_TO_STAFF_ROLE, assignNotificationSettingPayloadWorker)
}
function* assignNotificationSettingPayloadWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.assignNotificationSettingPayload(param['payload'])
        if (response?.statusCode == 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS);
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}


export function* getAssignedRolesForNotificationSettingsSaga() {
    yield takeLatest(TYPES.GET_ASSIGNED_NOTIFICATION_SETTINGS, getAssignedRolesForNotificationSettingsWorker)
}
function* getAssignedRolesForNotificationSettingsWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.getAssignedRolesForNotificationSettings(param['payload'])
        yield put(saveAssignedRolesForNotificationSetting(response?.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}





export function* storeComplianceReportDataSaga() {
    yield takeLatest(TYPES.STORE_COMPLIANCE_REPORT, storeComplianceReportDataWorker)
}
function* storeComplianceReportDataWorker(param: any) {
    yield put(getStoreComplianceReport(param))
}

export function* getClinicTpServiceTypesSaga() {
    yield takeLatest(TYPES.GET_CLINIC_TP_SERVICE_TYPES, getClinicTpServiceTypesWorker)
}
function* getClinicTpServiceTypesWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ClinicTpServiceOrStaffTypeResponse = yield clinicServices.getClinicTpServiceTypes()
        yield put(saveClinicTpServiceTypes(response?.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}
export function* getClinicTpStaffTypesSaga() {
    yield takeLatest(TYPES.GET_CLINIC_TP_STAFF_TYPES, getClinicTpStaffTypesWorker)
}
function* getClinicTpStaffTypesWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ClinicTpServiceOrStaffTypeResponse = yield clinicServices.getClinicTpStaffTypes()
        yield put(saveClinicTpStaffTypes(response?.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* insertOrUpdateClinicTpServiceTypesSaga() {
    yield takeLatest(TYPES.INSERT_OR_UPDATE_CLINIC_TP_SERVICE_TYPES, insertOrUpdateClinicTpServiceTypesWorkder)
}
function* insertOrUpdateClinicTpServiceTypesWorkder(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.insertOrUpdateClinicTpServiceTypesSaga(param['payload'])
        if (response?.statusCode === 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS);
            yield put(getClinicTpServiceTypes())

        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* deleteClinicTpServiceTypesSaga() {
    yield takeLatest(TYPES.DELETE_CLINIC_TP_SERVICE_TYPES, deleteClinicTpServiceTypesWorkder)
}
function* deleteClinicTpServiceTypesWorkder(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.deleteClinicTpServiceTypesSaga(param['payload'])
        if (response?.statusCode === 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS);
            yield put(getClinicTpServiceTypes())
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* insertOrUpdateClinicTpStaffTypesSaga() {
    yield takeLatest(TYPES.INSERT_OR_UPDATE_CLINIC_TP_STAFF_TYPES, insertOrUpdateClinicTpStaffTypesWorkder)
}
function* insertOrUpdateClinicTpStaffTypesWorkder(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.insertOrUpdateClinicTpStaffTypesSaga(param['payload'])
        if (response?.statusCode === 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS);
            yield put(getClinicTpStaffTypes())

        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* deleteClinicTpStaffTypesSaga() {
    yield takeLatest(TYPES.DELETE_CLINIC_TP_STAFF_TYPES, deleteClinicTpStaffTypesWorkder)
}
function* deleteClinicTpStaffTypesWorkder(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.deleteClinicTpStaffTypesSaga(param['payload'])
        if (response?.statusCode === 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS);
            yield put(getClinicTpStaffTypes())
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* AssignCertificateToStaffSaga() {
    yield takeLatest(TYPES.ASSIGN_CERTIFICATE_TO_STAFFS, AssignCertificateToStaffs)
}
function* AssignCertificateToStaffs(param: any) {
    try {
        yield put(loaderAction(true));
        const response: AssignCertificateToStaffsResponse = yield clinicServices.AssignCertificateToStaffs(param['payload'])
        if (response.statusCode === 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.ASSIGN_CERTIFICATE_TO_STAFF);
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* getCalenderCategorySaga() {
    yield takeLatest(TYPES.GET_CALENDAR_CATEGORY, getCalenderCategoryWorker)
}
function* getCalenderCategoryWorker() {
    try {
        yield put(loaderAction(true));
        const response: calendarSettingResponse = yield clinicServices.getCalenderCategory()
        yield put(saveCalenderCategory(response?.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* deleteCalendarCategorySaga() {
    yield takeLatest(TYPES.DELETE_CALENDAR_CATEGORY, deleteCalendarCategoryWorker)
}
function* deleteCalendarCategoryWorker(param:any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.deleteCalendarCategory(param['payload'])
        if (response?.statusCode === 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.DELETE_CALENDAR_CATEGORY);
            yield put(getCalenderCategory())
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* insertCalendarCategorySaga() {
    yield takeLatest(TYPES.INSERT_CALENDAR_CATEGORY, insertCalendarCategoryWorker)
}
function* insertCalendarCategoryWorker(param:any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield clinicServices.insertCalendarCategory(param['payload'])
        if (response?.statusCode === 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.ADD_CALENDAR_CATEGORY);
            yield put(getCalenderCategory())

        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}
export function* updateCalendarCategorySaga() {
    yield takeLatest(TYPES.UPDATE_CALENDAR_CATEGORY, updateCalendarCategoryWorker)
}

function* updateCalendarCategoryWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response:any = yield clinicServices.updateCalendarCategory(param['payload'])
        yield put(loaderAction(false));
        if (response.statusCode === 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.UPDATE_CALENDAR_CATEGORY);
        }
        yield put(getCalenderCategory());

    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* getDefaultCalendarCategoriesSaga() {
    yield takeLatest(TYPES.GET_DEFAULT_CALENDAR_CATEGORYS, getDefaultCalendarCategoriesWorker)
}
function* getDefaultCalendarCategoriesWorker() {
    try {
        yield put(loaderAction(true));
        const response:DefaultCalendarCategoriesResponse = yield clinicServices.getDefaultCalendarCategories()
        yield put(saveDefaultCalendarCategories(response?.resultData));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* updateDefaultCalendarCategoriesSaga() {
    yield takeLatest(TYPES.UPDATE_DEFAULT_CALENDAR_CATEGORYS, updateDefaultCalendarCategoriesWorker)
}

function* updateDefaultCalendarCategoriesWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response:any = yield clinicServices.updateDefaultCalendarCategories(param['payload'])
        yield put(loaderAction(false));
        if (response.statusCode === 200) {
            NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS);
             yield put(getCalenderCategory());
        }

    }
    catch (err) {
        yield put(loaderAction(false));

    }
}


