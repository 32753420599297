import React, { ComponentState, useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { NumericTextBox, Switch, TextArea } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { renderErrors } from "src/helper/error-message-helper";
import { DropDownObjectWithString } from "src/dataModels/docListModel";
import { AuthSets, AuthSetsPostModel, AuthorizationSetInfo, CreateAuthSetModelObj, CreateAuthSetServiceModel } from "src/dataModels/authSetModels";
import MultiSelectDropDown from "src/control-components/multi-select-drop-down/multi-select-drop-down";
import { clientServices } from "src/services/clientServices";
import { DropDownObjectFullName, ServiceResponse, Staff } from "src/dataModels/clientResponse";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ErrorHelper from "../../../helper/error-helper";
import InputKendoRctV1 from "src/control-components/input/inputV1";
import { useDispatch, useSelector } from "react-redux";
import { createNewAuthSet, updateAuthSet } from "src/redux/actions";
import TextAreaKendoRct from "src/control-components/text-area/text-area";

interface MyComponentProps {
    onClose: any,
    callback?: any
    preselectedData?: AuthSets
}


const AddEditAuthSet: React.FC<MyComponentProps> = ({ onClose, callback, preselectedData }) => {

    const dispatch = useDispatch()
    const frequencyData = [
        { key: "Day", value: "Day" },
        { key: "Week", value: "Week" },
        { key: "Month", value: "Month" },
        { key: "Year", value: "Year" },
    ];
    const emtyObj: CreateAuthSetModelObj = { isRestrictive: false, numUnits: 0, freqNumUnits: 0, frequency: frequencyData[0].key }
    const [authServices, setAuthServices] = useState<CreateAuthSetModelObj[]>([emtyObj])
    const [services, setServices] = useState<DropDownObjectWithString[]>([]);
    const [settingError, setSettingError] = useState(false);
    const [setName, setSetName] = useState('');
    const [errors, setErrors] = useState<any>({});
    const [comment, setComment] = useState('')

    const state: ComponentState = useSelector(states => {
        return states;

    });
    const clinicId = useSelector((state: ComponentState) => state.loggedIn.clinicId);

    const handleValidation = async () => {
        let formIsValid = true;

        if (setName.length < 1) {
            formIsValid = false;
            errors["setName"] = ErrorHelper.FIELD_BLANK;
        }

        authServices.map((serviceItem) => {
            if (serviceItem.isRestrictive) {
                if (!serviceItem.freqNumUnits || serviceItem.freqNumUnits < 1) {
                    formIsValid = false;
                    errors["serviceErrorUnits"] = ErrorHelper.GREATER_THEN_ZERO;
                }
            }
            if (!serviceItem.selectedServices || !(serviceItem.numUnits)
                || !serviceItem.frequency || serviceItem.selectedServices.length < 1) {
                formIsValid = false;
                errors["serviceError"] = ErrorHelper.FIELD_BLANK;
            }
        })


        return formIsValid;
    };


    useEffect(() => {
        fetchServiceList();
    }, []);

    useEffect(() => {
        if (preselectedData) {
            setSetName(preselectedData.setName)
            setAuthServices([])
            preselectedData.authorizationSetInfos?.map((dataItem: AuthorizationSetInfo) => {
                let dropDownOption: DropDownObjectFullName[] = []
                const dropOption = dataItem.authorizationSetServices.map((Item) => {
                    dropDownOption.push({ id: Item.serviceId, fullName: Item.service })
                })

                setAuthServices(oldData => [{
                    serviceDropOptions: dropDownOption,
                    selectedServices: dropDownOption,
                    isRestrictive: dataItem.isRestrictive,
                    freqNumUnits: dataItem.freqNumUnits, frequency: dataItem.freq, numUnits: dataItem.numUnits,
                    selectedCodes:dataItem.serviceCodesList
                },

                ...oldData])
            })
            setComment(preselectedData.comment)

        }
    }, [preselectedData]);



    async function fetchServiceList() {
        const modifier = await clientServices.getServicesWoModifiers();
        setServices(modifier?.resultData)
    }

    function renderToItem(li, itemProps) {
        const itemChildren = (
            <span>
                <input
                    type="checkbox"
                    name={itemProps.dataItem}
                    checked={itemProps.selected}
                    onChange={(e) => itemProps.onClick(itemProps.index, e)}
                />
                &nbsp;{li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }
    const getServicesList = async (selectedService: string[], indexOfSelectedService: number) => {
        if (selectedService.length < 1) {
            setAuthServices(list => list.map((item, i) =>
                i === indexOfSelectedService
                    ? {
                        ...item,
                        serviceDropOptions: [],
                        selectedServices: [],
                        serviceId: []
                    }
                    : item
            ));
        } else {
            await clientServices.getClientServicebyServiceCodes(selectedService)
                .then((result: any) => {
                    const serviceResponse: ServiceResponse = result;
                    const serviceIds = serviceResponse.resultData.map(option => option.id);
                    setAuthServices(list => list.map((item, i) =>
                        i === indexOfSelectedService
                            ? {
                                ...item,
                                serviceDropOptions: serviceResponse.resultData,
                                selectedServices: serviceResponse.resultData,
                                serviceId: serviceIds
                            }
                            : item
                    ));
                })
                .catch((error) => {
             
                    renderErrors(error.message);
                });
        }
    };


    const handleSubmit = async () => {
        setSettingError(true);
        if (await handleValidation()) {
            if (!preselectedData) {
                saveAuthorization()
            } else {
                updateAuthorization()
            }

        }
    };

    const updateAuthorization = async () => {
        let setPostObj: AuthSetsPostModel = {
            authorizationSetId: preselectedData?.id,
            setName: setName, clinicId: clinicId,
            authorizationSetInfos: [],
            comment: comment
        };
        authServices.map((item) => {
            const serviceIds = item?.selectedServices?.map(option => option.id);
            const serviceCodes= item?.selectedCodes?.map(option => option)
            const serviceObj: CreateAuthSetServiceModel = {
                freqNumUnits: item.freqNumUnits ?? 0,
                freq: item.frequency!,
                isRestrictive: item.isRestrictive ? item.isRestrictive : false,
                serviceId: serviceIds ? serviceIds : [],
                numUnits:item.numUnits?item.numUnits:0,
                serviceCodesList:serviceCodes??[]
            }
            setPostObj.authorizationSetInfos?.push(serviceObj)
        })
        dispatch(updateAuthSet(setPostObj))
        onClose()
    }

    const saveAuthorization = async () => {
        let setPostObj: AuthSetsPostModel = { setName: setName, clinicId: clinicId, authorizationSetInfos: [], comment: comment };
        authServices.map((item) => {
            const serviceIds = item?.selectedServices?.map(option => option.id);
            const serviceCodes= item?.selectedCodes?.map(option => option)
            const serviceObj: CreateAuthSetServiceModel = {
                freqNumUnits: item.freqNumUnits ?? 0,
                freq: item.frequency!,
                isRestrictive: item.isRestrictive ? item.isRestrictive : false,
                serviceId: serviceIds ? serviceIds : [],
                numUnits:item.numUnits?item.numUnits:0,
                serviceCodesList:serviceCodes??[],

            }
            setPostObj.authorizationSetInfos?.push(serviceObj)
        })
        dispatch(createNewAuthSet(setPostObj))
        onClose()
    };

    return (
        <Dialog
            onClose={onClose}
            title={preselectedData ? "Edit Authorizations" : "Add Authorizations"}
            className="dialog-modal xl-modal width_90" height={"90%"} width={"60%"}
        >
            <div className="overlapHead mx-n2 px-2 edit-Service-popup">

                <div className="preview-btn-bottom">
                    <InputKendoRctV1
                        value={setName}
                        onChange={(handleTextChange) => {
                            setSetName(handleTextChange.value)
                        }
                        }
                        error={setName == "" && errors?.setName}
                        name="custAuthId"
                        label="Template Name"
                    />

                    {/* <div className="border-bottom-line"></div> */}
                    {
                        authServices.map((selectedService, index) =>

                            <div className="row mt-3 align-items-center borderTop" >
                                <div className="mb-2 col-lg-4 col-md-6 col-12">
                                    <MultiSelectDropDown
                                        data={services}
                                        textField="name"
                                        label="*Select Service Codes"
                                        autoClose={false}
                                        onChange={(handleChange) => {
                                            const serviceIds = handleChange.value.map(option => option.id);
                                            let idsArray:Staff[]=[]
                                            if (serviceIds.length == selectedService.selectedCodes?.length) {

                                                setAuthServices(list => list.map((item, i) =>
                                                    i === index
                                                        ? {
                                                            ...item,
                                                            service: handleChange.value.name,
                                                            selectedCodes: [],
                                                            serviceDropOptions: [],
                                                            selectedServices: []
                                                        }
                                                        : item
                                                ));
                                                return;
                                            }
                                            setAuthServices(list => list.map((item, i) =>
                                                i === index
                                                    ? {
                                                        ...item,
                                                        service: handleChange.value.name,
                                                        selectedCodes: serviceIds,
                                                        serviceDropOptions: []
                                                    }
                                                    : item
                                            ));
                                            getServicesList(serviceIds, index);
                                        }}
                                        value={
                                            selectedService.selectedCodes?.map((option) =>({id:option,name:option}))
                                        }
                                        name="service"
                                        required={true}
                                        dataItemKey={"id"}
                                        itemRender={renderToItem}
                                        tags={
                                            selectedService.selectedCodes && selectedService.selectedCodes?.length > 0
                                                ? [{
                                                    text: `${selectedService.selectedCodes.length} items selected`,
                                                    data: [...selectedService.selectedCodes]
                                                }]
                                                : []
                                        }
                                    />
                                </div>


                                <div className="mb-2 col-lg-4 col-md-6 col-12">
                                    <MultiSelectDropDown
                                        label="*Select Services"
                                        placeholder="*Select Service Codes First*"
                                        onChange={(handleChange) => {
                                            const serviceIds = handleChange.value.map(option => option.id);
                                            setAuthServices(list => list.map((item, i) =>
                                                i === index
                                                    ? {
                                                        ...item,
                                                        serviceId: serviceIds,
                                                        selectedServices: handleChange.value
                                                    }
                                                    : item
                                            ));
                                        }}
                                        data={selectedService.serviceDropOptions ?? []}
                                        textField="fullName"
                                        name="authServices"
                                        value={selectedService?.selectedServices}
                                        autoClose={false}
                                        dataItemKey="id"
                                        validityStyles={settingError}
                                        required={true}
                                        itemRender={renderToItem}
                                        error={((!selectedService.selectedServices || selectedService.selectedServices.length < 1) && errors && errors.serviceError) && errors.serviceError}
                                        tags={
                                            selectedService.selectedServices && selectedService.selectedServices?.length > 0
                                                ? [{
                                                    text: `${selectedService.selectedServices.length} items selected`,
                                                    data: [...selectedService.selectedServices]
                                                }]
                                                : []
                                        }
                                    />
                                </div>

                                <div className="mb-2 col-lg-3 col-md-6 col-12">
                                    <span style={{ fontSize: "14px", color: (true) ? "#d51923" : "black" }}></span>
                                    <NumericTextBox
                                        onChange={(handleChange) => {
                                            setAuthServices(list => list.map((item, i) =>
                                                i === index
                                                    ? {
                                                        ...item,
                                                        numUnits: handleChange.value!
                                                    }
                                                    : item
                                            ));
                                        }}
                                        name="numUnits"
                                        label="Total Units"
                                        validityStyles={settingError}
                                        required={true}
                                        value={selectedService.numUnits}
                                        spinners={false}

                                    />
                                    {(!selectedService.numUnits && errors && errors.serviceError) && <Error>{errors.serviceError}</Error>}
                                </div>
                                <div className=" col-lg-1 col-md-1 col-12">
                                    {authServices.length > 1 && <i
                                        onClick={() => {
                                            setAuthServices(authServices.filter(item => item !== selectedService));
                                        }}
                                        className="fa-solid fa-xmark cross-red"></i>}
                                </div>

                                <div className=" align-items-center row ml-2" >
                                    <div className="">

                                        <Switch
                                            onChange={(handleChange) => {
                                                setAuthServices(list => list.map((item, i) =>
                                                    i === index
                                                        ? {
                                                            ...item,
                                                            isRestrictive: handleChange.value!
                                                        }
                                                        : item
                                                ));
                                            }}
                                            checked={selectedService.isRestrictive}
                                            onLabel={""}
                                            offLabel={""}
                                            name="isEnforceValidation"
                                        />
                                        <span className="ml-2">Restrictive?</span>
                                    </div>
                                    <div className="frequency">
                                        <div className="col-md-12 d-flex align-items-center dropIcon drop_icns">
                                            <p className="f-12">If yes, Client is limited to</p>

                                            <NumericTextBox
                                                style={{ width: "70px", minHeight: "30px" }}
                                                onChange={(handleChange) => {
                                                    setAuthServices(list => list.map((item, i) =>
                                                        i === index
                                                            ? {
                                                                ...item,
                                                                freqNumUnits: handleChange.value!
                                                            }
                                                            : item
                                                    ));
                                                }}
                                                name="freqNumUnits"
                                                validityStyles={settingError}
                                                required={true}
                                                value={selectedService.freqNumUnits}
                                                spinners={false}
                                            />
                                            {
                                                (selectedService.isRestrictive &&
                                                    (!selectedService?.freqNumUnits || selectedService?.freqNumUnits < 1)) ?
                                                    (errors && errors.serviceErrorUnits)
                                                        ? <Error>{errors.serviceErrorUnits}</Error> : null : null

                                            }

                                            <p className="ml-2 ">units per</p>
                                            <div className="auth-drop-error ">
                                                <DropDownList
                                                    defaultValue={frequencyData[0].value}
                                                    data={frequencyData}
                                                    style={{ width: "70px", height: "30px" }}
                                                    textField="value"
                                                    dataItemKey="key"
                                                    onChange={(handleChange) => {
                                                        setAuthServices(list => list.map((item, i) =>
                                                            i === index
                                                                ? {
                                                                    ...item,
                                                                    frequency: handleChange.value.key,
                                                                }
                                                                : item
                                                        ));
                                                    }}
                                                    value={selectedService.frequency && {
                                                        key: selectedService.frequency,
                                                        value: selectedService.frequency
                                                    }}
                                                    name="frequency"
                                                    className="frequency-drop"
                                                    required={true}
                                                />
                                                {(!selectedService.frequency && errors && errors.serviceError) && <Error>{errors.serviceError}</Error>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                    <button
                        onClick={() => {
                            setAuthServices(oldArray => [...oldArray, { isRestrictive: false, numUnits: 0, freqNumUnits: 0, frequency: frequencyData[0].key  }]);
                        }}
                        className="AddMore "><i className="fa-solid fa-plus"></i> Add Another Service</button>

                    <div className = "mt-3 pr-0 pl-0 col-md-12">
                        <TextAreaKendoRct
                            rows = {1}
                            label = "Comments"
                            name = "comment"
                            txtValue={comment}
                            onChange={(e: any) => setComment(e.target.value)}
                        />
                    </div>

                    <div className="d-flex mt-4">
                        <div className="right-sde">
                            <button
                                className="btn blue-primary text-white "
                                onClick={handleSubmit}
                            >
                                {preselectedData ? "Update" : "Submit"}
                            </button>
                        </div>
                        <div className="right-sde-grey">
                            <button
                                className="btn grey-secondary text-white  mx-2"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div></div>
        </Dialog>
    );
};
export default AddEditAuthSet;
