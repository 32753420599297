import {Error} from "@progress/kendo-react-labels";
import {useEffect, useRef, useState} from "react";
import {onShowFileTempFields} from "./file-template-utility";
import {useDispatch} from "react-redux";
import {loaderAction} from "src/redux/actions";

export function AddDocumentFileTemplate({
                                            name,
                                            onComponentLoaded,
                                            controlErrors,
                                            showValidationError,
                                            focusEnable,
                                            selectedPatientList,
                                            isEdit,
                                            onGoalsDataStateChange,
                                            preselctedGoalEdit,
                                            isViewDoc,
                                            dxPreselectedData,
                                            onDxStateChange,
                                            tpId,
                                            isDraft,
                                            draftCommonControlls,
                                            isFromEditDocs,
                                            docFieldMappings
                                            // setHtmlFileValidationError,
                                        }) {

    const itemsRef = useRef(null);
    const [errorList, setErrorList] = useState([]);
    const [ComponentToRender, setComponentToRender] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (ComponentToRender && onComponentLoaded) {
            onComponentLoaded();
        }
    }, [ComponentToRender])

    useEffect(() => {
        // console.log("Loading component", name);
        // Dynamically import the component
        import(`./file-template/${name}`)
            .then((module) => setComponentToRender(() => {
                // console.log("Got component", name);
                if (module.default) return module.default;
                // console.log("module name ", name);
                return module[name];
            }))
            .catch((error) => {
                console.error(`Error loading component: ${name}`, error);
                setComponentToRender(null);
            });
    }, [name]);

    useEffect(() => {
        if (ComponentToRender && isFromEditDocs && docFieldMappings) {
            dispatch(loaderAction(true))
            setTimeout(() => {
                onShowFileTempFields(docFieldMappings, "", false);
                dispatch(loaderAction(false))
            }, 2000)

        }
    }, [ComponentToRender])

    useEffect(() => {
        const handleChange = (_) => {
            // setHtmlFileValidationError()
        };

        const formEl = document.getElementById(name);
        formEl?.addEventListener("change", handleChange);

        return () => {
            formEl?.removeEventListener("change", handleChange);
        };
    }, []);

    useEffect(() => {
        let errorList =
            !showValidationError || !controlErrors || controlErrors.length < 1
                ? []
                : controlErrors;
        setErrorList(errorList);
    }, [showValidationError, controlErrors]);

    useEffect(() => {
        if (focusEnable && errorList && errorList.length > 0) {
            const uniqueIndex = errorList[0].id;
            if (
                itemsRef.current &&
                itemsRef.current[uniqueIndex] &&
                itemsRef.current[uniqueIndex].focus
            ) {
                itemsRef.current[uniqueIndex].focus();
            }
        }
    }, [errorList, focusEnable]);

    function showErrorFor(id) {
        if (!errorList) return <></>;
        const error = errorList.find((error) => error.id === id);
        if (!error) return <></>;
        return <Error>{error.msg}</Error>;
    }

    return ComponentToRender ? (
        <ComponentToRender
            itemsRef={itemsRef}
            showErrorFor={showErrorFor}
            selectedPatientList={selectedPatientList}
            isEdit={isEdit}
            onGoalsDataStateChange={onGoalsDataStateChange}
            preselctedGoalEdit={preselctedGoalEdit}
            isViewDoc={isViewDoc}
            dxPreselectedData={dxPreselectedData}
            onDxStateChange={onDxStateChange}
            tpId={tpId}
            isDraft={isDraft}
            draftCommonControlls={draftCommonControlls}
        />
    ) : (
        <></>
    );
}