import React  from "react";
import { Skeleton, SkeletonShape } from "@progress/kendo-react-indicators";




export interface SkeletonProps{
  shape:SkeletonShape,
  width?:number,
  height?:number,
  marginRight?:number
}

const CustomSkeleton=(props:SkeletonProps)=>{
   const {shape,
    width,
    height,
    marginRight,
    ...reset
  }=props;

  const shapeParam:SkeletonShape=!shape ? "text" : shape;
    return(
        <Skeleton
                  shape={shapeParam}
                  // style={{
                  //   width: {width},
                  //   height: {height},
                  //   marginRight: {marginRight},
                  // }}
                />
    )
}

export default CustomSkeleton;